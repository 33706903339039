import { sample } from "lodash";
import { obfuscateString } from "./obfuscator";
import { replaceAll } from "../text";
import { EConditionTerm } from "../../_interfaces/nodeTreeEditor/INodeTreeEditor";
import { declinate } from "../declinate";

const SPECIAL_TOKENS = {
  END: "{END}",
  CITY: "{CITY}",
  COUNTRY: "{COUNTRY}",
  INLINE_BOT_LINK: "{INLINE_BOT_LINK}",
  NL: "{NL}",
};

export const compileTextSegment = (segment: any, res = "") => {
  if (typeof segment === "string") return res + segment;
  if (!segment) return res;
  const term = segment.term;

  switch (term) {
    case EConditionTerm.OR: {
      return res + compileTextSegment(sample(segment.content), res);
    }
    case EConditionTerm.SEQ:
    case EConditionTerm.AND: {
      return res + segment.content.map((s) => compileTextSegment(s, res)).join(" ");
    }
    default:
      return res;
  }
};

export const generateBotText = (
  options: { city: string; country: string; userSettingsLinkId: string },
  segment,
) => {
  if (!segment) return "";
  let res = compileTextSegment(segment);

  // process {END}
  res = res.split(SPECIAL_TOKENS.END)[0].trim();
  //With city
  res = replaceAll(res, SPECIAL_TOKENS.CITY, declinate(options.city));
  res = replaceAll(res, SPECIAL_TOKENS.COUNTRY, declinate(options.country));
  res = replaceAll(res, SPECIAL_TOKENS.INLINE_BOT_LINK, "IN_LINKED" + options.userSettingsLinkId);
  res = replaceAll(res, SPECIAL_TOKENS.NL, "\n");

  res = obfuscateString(res, 0.1);
  res = replaceAll(res, "  ", " ");
  res = replaceAll(res, " , ", ", ");
  res = replaceAll(res, " \\?", "?");
  res = replaceAll(res, "\n ", "\n");
  return res;
};
