import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { CommonTableController } from "../_common/CommonTableController";
import { apiController } from "../ApiController";
import { EChatMode } from "../../../_shared/_enums/EChatMode";

export class UnreadsController {
  root: RootController;
  tableController: CommonTableController;
  unreadCounter: number = 0;
  unreadCheckTimeout = null;
  readTimeout = null;

  constructor(root: RootController) {
    this.root = root;
    this.tableController = new CommonTableController(EEntityType.CLIENT);
    makeAutoObservable(this);
  }
  getUnreadClients = async () => {
    const resp = await apiController.chats.getUnread();
    return resp.items;
  };

  unreadsCheck = async () => {
    if (this.unreadCheckTimeout) {
      clearTimeout(this.unreadCheckTimeout);
    }
    this.unreadCheckTimeout = setTimeout(() => {
      this.unreadsCheck();
    }, 60000);
    if (!this.root.isRoot()) return;

    this.tableController.items = (await this.getUnreadClients()) || [];
    this.tableController.count = this.tableController.items.length;
    this.unreadCounter = this.tableController.count;
  };

  startReadMessages = async () => {
    if (this.readTimeout) {
      clearTimeout(this.readTimeout);
    }
    this.readTimeout = setTimeout(() => {
      this.startReadMessages();
    }, 5000);
    if (!this.root.isRoot()) return;
    const pathname = document.location.pathname;
    const page = pathname.split("/");

    if (page.length !== 3) return;
    let mode = null;
    if (page[1] === "dialog") {
      mode = EChatMode.DIALOG;
    }
    if (page[1] === "chat") {
      mode = EChatMode.BOTCHAT;
    }
    if (!mode) return;
    const chatId = page[2];

    await apiController.chats.readMessages(mode, chatId);
  };

  init = async () => {
    if (!this.root.isRoot()) {
      return;
    }
    await this.startReadMessages();
    this.unreadsCheck().then();
  };
}
