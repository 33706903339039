import styles from "./ChatMessenger.module.scss";
import cx from "classnames";

export const UnreadMessages = ({ msgCount, onClick = () => {} }) => {
  if (!msgCount) return null;
  return (
    <span onClick={onClick} className={styles.UnreadMessages}>
      📧 {msgCount}
    </span>
  );
};

export const CustomBadge = ({ text, isRed = false }) => {
  if (!text) return null;
  return <span className={cx(styles.CustomBadge, isRed && styles.alerted)}>{text}</span>;
};
