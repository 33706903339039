export interface ICoords {
  x: number;
  y: number;
}

export interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum EDragMode {
  NODRAG = "NODRAG",
  ELEMENT_DRAG = "ELEMENT_DRAG",
  CONNECTION_DRAG = "CONNECTION_DRAG",
  SELECTION_DRAG = "SELECTION_DRAG",
}

export interface IConnection {
  from_slot_id: number;
  to_unique_id?: string;
  to_slot_id?: number;
  pivot?: ICoords;
}
