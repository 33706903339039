import { Modal } from "../Modal/Modal";
import React from "react";
import { GridRow } from "../Grid/GridRow";
import { Form } from "../Form/Form";
import { observer } from "mobx-react-lite";
import { PaymentController } from "../../controllers/Client/PaymentController";
import { EGridItemSize, GridItem } from "../Grid/GridItem";
import { ControlledTable, ITableOptions } from "../Table/Table";
import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import { getDateStraight } from "../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  filters: {},
  columns: [
    {
      title: "Created At",
      field: "created_at",
      columnStyle: { width: "300px" },
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
    {
      title: "value",
      field: "value",
    },
    {
      title: "currency",
      field: "currency",
    },
    {
      title: "comment",
      field: "comment",
    },
  ],
};

export const PaymentHistoryModal: React.FunctionComponent<{
  controller: PaymentController;
}> = observer(({ controller }) => {
  const isModalOpen = !!controller.isHistoryOpened;
  if (!isModalOpen) return null;

  return (
    <Modal
      header={"История транзакций"}
      innerStyle={{ minWidth: 800 }}
      onClose={controller.closeHistoryModal}
    >
      <Form>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <ControlledTable
              controller={controller.transactionsTableController}
              options={tableOptions}
            />
          </GridItem>
        </GridRow>
      </Form>
    </Modal>
  );
});
