import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { CommonTableController } from "../_common/CommonTableController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { apiController } from "../ApiController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { DateTime } from "luxon";
import { ETgNodeType } from "../../../_shared/_enums/ETgNodeType";
import { get } from "lodash";

export class NodeTableController {
  root: RootController;
  tableController: CommonTableController;
  unusedLimits: number = 0;
  maxLimits: number = 0;
  dialogsCount: number = 0;

  constructor(root: RootController) {
    this.tableController = new CommonTableController(EEntityType.NODE);
    this.root = root;
    makeAutoObservable(this);
  }

  init = async () => {
    await this.updateLimits();
    await this.tableController.loadTable();
  };

  updateLimits = async () => {
    const lastDay = DateTime.now().minus({ days: 1 }).toMillis();
    const dialogsCountResp = await apiController[EEntityType.MESSAGING_PRIVATE_CHAT][
      ECrudMethods.COUNT
    ]({
      created_at: {
        $lt: Date.now(),
        $gt: lastDay,
      },
    });

    const aggr = [
      {
        $match: {
          error: null,
          node_type: ETgNodeType.MESSAGING,
        },
      },
      {
        $group: {
          _id: null,
          sum_val: {
            $sum: "$max_new_dialogs",
          },
        },
      },
    ];

    const totalMaxRes = await apiController[EEntityType.NODE][ECrudMethods.AGGREGATE](aggr);
    const max = get(totalMaxRes, ["res", "0", "sum_val"], 0);

    this.maxLimits = max;
    this.dialogsCount = dialogsCountResp.count;
    this.unusedLimits = max - dialogsCountResp.count;
  };
}
