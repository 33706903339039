import style from "./Button.module.scss";
import cx from "classnames";

export enum EButtonType {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  DEFAULT = "DEFAULT",
}

interface IButtonProps extends React.ButtonHTMLAttributes<any> {
  styling?: EButtonType;
  text: string;
}

export const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const styling = props?.styling || EButtonType.ACCEPT;
  return (
    <button
      {...props}
      className={cx({
        [props.className as string]: !!props.className,
        [style.Button]: true,
        [style[styling]]: true,
      })}
    >
      {props.text}
    </button>
  );
};
