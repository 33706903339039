import { each } from "lodash";

//warning, russian symbols obfuscated
const obfuscateDictionary = {
  е: "e",
  р: "p",
  о: "o",
  а: "a",
  с: "c",
  х: "x",
};

export const obfuscateString = (str, intensity = 1) => {
  return str
    .split("")
    .map((char) => {
      if (!obfuscateDictionary[char]) return char;
      const isObfuscate = Math.random() < intensity;
      if (!isObfuscate) return char;
      return obfuscateDictionary[char];
    })
    .join("");
};

const ruAlph = "абвгдеёжзийклмнопрстуфхцчшщъыьэюя";
const RU_ALPH = new Set();

const enAlph = "abcdefghijklmnopqrstuvwxyz&";
const EN_ALPH = new Set();

each(ruAlph, (char, index) => {
  RU_ALPH.add(ruAlph.charCodeAt(index));
});

each(enAlph, (char, index) => {
  EN_ALPH.add(enAlph.charCodeAt(index));
});

const deobfuscateDictionaryRu = {
  // english - rusian
  e: "е",
  p: "р",
  o: "о",
  a: "а",
  c: "с",
  x: "х",
  u: "и",
  n: "п",
  k: "к",
  y: "у",
  // greek - rusian (Capital variants is the same)
  η: "н",
  ε: "е",
  β: "в",
  γ: "г",
  α: "а",
  κ: "к",
  μ: "м",
  ο: "о",
  π: "п",
  ρ: "р",
  τ: "т",
  φ: "ф",
  χ: "х",
};

const deobfuscateDictionaryEn = {
  е: "e",
  р: "p",
  о: "o",
  а: "a",
  с: "c",
  х: "x",
  и: "u",
  п: "n",
  к: "k",
  у: "y",
};

const isRussian = (msg): boolean => {
  let fullLen = msg.length;
  let ruLen = 0;
  each(msg, (c, index) => {
    if (c === " " || c === "?" || c === "!") {
      fullLen--;
    }
    if (RU_ALPH.has(msg.charCodeAt(index))) {
      ruLen++;
    }
  });
  const perc = ruLen / fullLen;
  return perc > 0.2;
};

const latinFamily =
  "abcdefghijklmnopqrstuvwxyzàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿāăąćĉċčďđēĕėęěĝğġģĥħĩīĭįıĵķĺļľłńņňŋōŏőœŕŗřśŝşšţťŧũūŭůűųŵŷźżžǎǐǒǔǖǘǚǜǟǡǣǭǯȁȃȅȇȉȋȍȏȑȓȕȗșțȝȟȡȣȥɐɓɔɕɖɗɘəɚɛɜɞɟɠɡɣɤɥɦɨɪɫɬɭɯɰɲɵɹɻɽɾʂʃʄʈʉʊʋʌʍʎʐʑʒʝʠʣʥʦʧʨʪʫʬʭʮʯ";
const LATIN_FAMILY = new Set();
each(latinFamily, (_, index) => {
  LATIN_FAMILY.add(latinFamily.charCodeAt(index));
});

const greekFamily = "αβγδεζηθικλμνξοπρστυφχψως";
const GREEK_FAMILY = new Set();
each(greekFamily, (_, index) => {
  GREEK_FAMILY.add(greekFamily.charCodeAt(index));
});

const cyrillicFamily = `абвгдеёжзийклмнопрстуфхцчшщъыьэюяґєіїјљњћќўџѣѥѧѩѫѭѯѱѳѵѷѹѻѽѿҁ҃҅҇ҋҍҏґғҕҗҙқҝҟҡңҥҧҩҫҭүұҳҵҷҹһҽҿӂӄӆӈӊӌӎӏӑӓӕӗәӛӝӟӡӥӧөӫӭӱӳӷӹӽӿԁԃԅԇԉԋԍԏԑԓԕԗԙԛԝԟաբգդեզէըթժիխճմյնշոչպջռսվտրցւփքօֆևֈ։֊־׀׃׆אבגדהוזחטיךכלםמןנסעפףצקרשת׫׬׭׮װױײ׳״אבגדהוזחטיכלמנסעפצקרשתאבגדהוזחטיכלמנסעפצקרשתאבגדהוזחטיכלמנסעפצקרשתאבגדהוזחטיכלמנסעפצקרשת`;
const CYRILLIC_FAMILY = new Set();
each(cyrillicFamily, (_, index) => {
  CYRILLIC_FAMILY.add(cyrillicFamily.charCodeAt(index));
});

function isDigit(char) {
  return char.length === 1 && char >= "0" && char <= "9";
}

export const isObfuscated = (msg: string): boolean => {
  let words = msg.trim().split(" ");

  let commonWordsLen = words.length;
  if (commonWordsLen === 0) return false;

  let suspWords = 0;
  for (let word of words) {
    if (word.length <= 1) {
      commonWordsLen--;
      continue;
    }
    let counts = { cyr: 0, lat: 0, other: 0 };
    for (let i = 0; i < word.length; i++) {
      if (isDigit(word[i])) {
        continue;
      }
      if (CYRILLIC_FAMILY.has(word.charCodeAt(i))) {
        counts.cyr = counts.cyr + 1;
      } else if (LATIN_FAMILY.has(word.charCodeAt(i))) {
        counts.lat = counts.lat + 1;
      } else {
        counts.other = counts.other + 1;
      }
    }
    let alphabetsUsed = 0;
    for (let alphCount of Object.values(counts)) {
      if (alphCount !== 0) {
        alphabetsUsed++;
      }
    }
    if (alphabetsUsed > 1) {
      suspWords++;
    }
  }
  if (suspWords === 0) return false;

  let suspPercentage = suspWords / commonWordsLen;

  // if suspicious words count is less then 10% - we count all phrase as suspicious
  return suspPercentage >= 0.22;
};

export const deobfuscateSpam = (msg): string => {
  return msg
    .split(" ")
    .map((word) => {
      const isRu = isRussian(word);
      const dict = isRu ? deobfuscateDictionaryRu : deobfuscateDictionaryEn;
      const alph = isRu ? RU_ALPH : EN_ALPH;
      return word
        .split("")
        .map((c, index) => {
          if (alph.has(word.charCodeAt(index))) {
            return c;
          }
          if (!dict[c]) return c;
          return dict[c];
        })
        .join("");
    })
    .join(" ");
};
