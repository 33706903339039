import React, { useEffect } from "react";
import { root } from "../../controllers/RootController";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ChatMessenger } from "../../components/ChatMessenger/ChatMessenger";
import { EChatMode } from "../../../_shared/_enums/EChatMode";

export const FeedChat: React.FC<{ mode: EChatMode }> = observer(({ mode }) => {
  let params = useParams();
  const { chatId } = params;
  const feedChatController = root.feedChatController;
  useEffect(() => {
    feedChatController.init(mode, chatId).then();
  }, []);

  return <ChatMessenger />;
});
