import axios from "axios";
import { EEntityType } from "../../_shared/_enums/EEntityType";
import { EChatMode } from "../../_shared/_enums/EChatMode";
import { ECrudMethods, IMethodOverride } from "../../_shared/_enums/ECrudMethods";
import { ITransactionSchema } from "../../_shared/Database/transaction";
import { makeAutoObservable } from "mobx";

const axiosInstance = axios.create({
  baseURL: "https://" + process.env.REACT_APP_API_HOSTNAME + "/v1",
  timeout: 100000,
  withCredentials: true,
});

const getCommonCrud = (entityType: EEntityType): IMethodOverride => {
  return {
    [ECrudMethods.COUNT]: async (filter) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.COUNT}`, {
        filter,
      });
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.AGGREGATE]: async (aggregate) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.AGGREGATE}`, {
        aggregate,
      });
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.LIST]: async (filter, page = 0, limit = 50, sort = {}) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.LIST}`, {
        filter,
        page,
        limit,
        sort,
      });
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.GETBY_ID]: async (itemId: string) => {
      const resp = await axiosInstance.get(
        `/entity/${entityType}/${ECrudMethods.GETBY_ID}/${itemId}`,
      );
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.GET_BY_QUERY]: async (query: any) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.GET_BY_QUERY}`, {
        query,
      });
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.DELETE_MANY_BY_QUERY]: async (query: any) => {
      const resp = await axiosInstance.post(
        `/entity/${entityType}/${ECrudMethods.DELETE_MANY_BY_QUERY}`,
        { query },
      );
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.UPDATE]: async (update: any) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.UPDATE}`, update);
      if (!resp || !resp.data.result) return null;
      return resp.data;
    },
    [ECrudMethods.DELETE]: async (itemId: string) => {
      const resp = await axiosInstance.post(`/entity/${entityType}/${ECrudMethods.DELETE}`, {
        [`${entityType}Id`]: itemId,
      });
      if (!resp || !resp.data.result) {
        throw resp.data.message;
      }
      return resp.data.entity;
    },
  };
};

class ApiController {
  reqCount = 0;

  constructor() {
    makeAutoObservable(this);
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      (config) => {
        this.reqCount = this.reqCount + 1;
        return config;
      },
      (error) => {
        this.reqCount = this.reqCount - 1;
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    axiosInstance.interceptors.response.use(
      (response) => {
        this.reqCount = this.reqCount - 1;
        return response;
      },
      (error) => {
        this.reqCount = this.reqCount - 1;
        return Promise.reject(error);
      },
    );
  }

  getMe = async () => {
    const resp = await axiosInstance.get("/me");
    if (!resp || !resp.data.result) return null;
    return resp.data.entity;
  };

  getCurrencies = async () => {
    const resp = await axiosInstance.get("/currency/rates");
    if (!resp || !resp.data.result) return null;
    return resp.data.data;
  };

  scanner = {
    scanSources: async (sourcesString: string) => {
      const resp = await axiosInstance.post("/enqueueBatchChatQueries", {
        sourcesString,
      });
      if (!resp || !resp.data.result) {
        throw resp.data.error;
      }
      return resp.data;
    },
    getChatScanState: async () => {
      const resp = await axiosInstance.post("/getChatScanState");
      if (!resp || !!resp.data.error) {
        throw resp.data.error;
      }
      return resp.data;
    },
    dequeueQuery: async (query: string) => {
      const resp = await axiosInstance.post("/dequeueQuery", { query });
      if (!resp || !resp.data.result) {
        throw resp.data.error;
      }
      return resp.data;
    },
    resetScan: async () => {
      const resp = await axiosInstance.post("/resetScan");
      if (!resp || !resp.data.result) {
        throw resp.data.error;
      }
      return resp.data;
    },
    openChat: async (chatSourceId: string) => {
      const resp = await axiosInstance.post("/openChat", {
        chatId: chatSourceId,
      });
      if (!resp || !resp.data.result) {
        throw resp.data.error;
      }
      return resp.data;
    },
    closeChat: async (chatSourceId: string) => {
      const resp = await axiosInstance.post("/closeChat", {
        chatId: chatSourceId,
      });
      if (!resp || !resp.data.result) {
        throw resp.data.error;
      }
      return resp.data;
    },
  };

  chats = {
    getUnread: async () => {
      const resp = await axiosInstance.get(`/chats/getUnread`);
      return resp.data;
    },
    sendMessage: async (mode: EChatMode, chatId: string, message: any) => {
      const resp = await axiosInstance.post(`/chats/sendMessage`, {
        mode,
        chatId,
        message,
      });
      return resp.data;
    },
    deleteMessage: async (mode: EChatMode, chatId: string, messageId: any) => {
      const resp = await axiosInstance.post(`/chats/deleteMessage`, {
        mode,
        chatId,
        messageId,
      });
      return resp.data;
    },
    readMessages: async (chatMode: EChatMode, chatId: string) => {
      const resp = await axiosInstance.post(`/chats/readMessages`, {
        chatMode,
        chatId,
      });
      return resp.data;
    },
    initFeedChat: async (feedId: string) => {
      const resp = await axiosInstance.get(`/chats/initChat/${feedId}`);
      return resp.data.data;
    },
    loadChatMessages: async (
      chatMode: EChatMode,
      chatId: string,
      fromMessageId: string | null,
      fullLog = false,
    ) => {
      const resp = await axiosInstance.get(
        `/chats/messages/${chatMode}/${chatId}/${fullLog}/${fromMessageId || "last"}`,
      );
      return resp.data;
    },
    startNodeBotChat: async (clientId: string) => {
      const resp = await axiosInstance.post(`/chats/startNodeBotChat`, {
        clientId,
      });
      return resp.data;
    },
  };

  targeting = {
    processChatDumps: async (files: File[], searchCoreId: string, targetListId: string) => {
      const resp = await axiosInstance.post(
        "/target/parseChats",
        { files, searchCoreId, targetListId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return {
        succeed: !!!resp.data.error,
        message: resp.data?.error || "",
      };
    },
  };

  nodes = {
    getNodeReport: async () => {
      const resp = await axiosInstance.post(`/entity/${EEntityType.NODE}/getNodeReport`);
      return resp.data;
    },
    uploadTDatas: async (files: File[]) => {
      const resp = await axiosInstance.post(
        `/entity/${EEntityType.NODE}/uploadTDatas`,
        { files },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return resp.data;
    },
    restartNode: async (nodePhone: string) => {
      const resp = await axiosInstance.post(`/entity/${EEntityType.NODE}/restartNode`, {
        phone: nodePhone,
      });
      return resp.data;
    },
  };

  coreLab = {
    aiAnswerTest: async (prompt, phrase) => {
      const resp = await axiosInstance.post("/aiAnswer", { prompt, phrase });
      return {
        response: resp.data?.response,
      };
    },
    scan: async (cores: string[], size = 200000) => {
      const resp = await axiosInstance.post("/coreLab/scan", { cores, size });
      return {
        succeed: !!!resp.data.error,
        message: resp.data?.error || "",
      };
    },
  };

  semantics = {
    analyze: async ({ searchCoreId, searchText, scanningSize }) => {
      const resp = await axiosInstance.post("/semantics/analyze", {
        searchCoreId,
        scanningSize,
        searchText,
      });
      return resp.data;
    },
    getSamples: async ({ text, sampleCount }) => {
      const resp = await axiosInstance.post("/semantics/getSamples", {
        text,
        sampleCount,
      });
      return resp.data;
    },
    ...getCommonCrud("semantics" as EEntityType),
  };

  clientUtils = {
    getLocation: async (clientId: string) => {
      const resp = await axiosInstance.post(`entity/${EEntityType.CLIENT}/getLocation`, {
        clientId,
      });
      return resp.data;
    },
    getBestSettingsLink: async (clientId: string) => {
      const resp = await axiosInstance.post(`entity/${EEntityType.CLIENT}/getBestSettingsLink`, {
        clientId,
      });
      return resp.data;
    },
    registerTransaction: async (isMessageNeeded: boolean = true, payment: ITransactionSchema) => {
      const resp = await axiosInstance.post(`entity/${EEntityType.CLIENT}/processPayment`, {
        payment,
        isMessageNeeded,
      });
      return resp.data;
    },
  };

  nodeManager = {
    uploadAvatar: async (nodePhone: string, avatar: File) => {
      const resp = await axiosInstance.post(
        `nodeManager/uploadAvatar`,
        { files: [avatar], nodePhone },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return resp.data;
    },
    manage: async (nodePhone: string, command: string, payload: any) => {
      const resp = await axiosInstance.post(`nodeManager/${command}`, {
        nodePhone,
        payload,
      });
      return resp.data;
    },
  };

  [EEntityType.SEARCH_CORE]: IMethodOverride = getCommonCrud(EEntityType.SEARCH_CORE);
  [EEntityType.LEAD_SOURCE]: IMethodOverride = getCommonCrud(EEntityType.LEAD_SOURCE);
  [EEntityType.CLIENT]: IMethodOverride = getCommonCrud(EEntityType.CLIENT);
  [EEntityType.TRANSACTION]: IMethodOverride = getCommonCrud(EEntityType.TRANSACTION);
  [EEntityType.FEED]: IMethodOverride = getCommonCrud(EEntityType.FEED);
  [EEntityType.LOCATION]: IMethodOverride = getCommonCrud(EEntityType.LOCATION);
  [EEntityType.CATEGORY]: IMethodOverride = getCommonCrud(EEntityType.CATEGORY);
  [EEntityType.TARGET_LIST]: IMethodOverride = getCommonCrud(EEntityType.TARGET_LIST);
  [EEntityType.SETTINGS_LINK]: IMethodOverride = getCommonCrud(EEntityType.SETTINGS_LINK);
  [EEntityType.MESSAGING_PRIVATE_CHAT]: IMethodOverride = getCommonCrud(
    EEntityType.MESSAGING_PRIVATE_CHAT,
  );
  [EEntityType.MESSAGING_PRIVATE_CHAT_MESSAGE]: IMethodOverride = getCommonCrud(
    EEntityType.MESSAGING_PRIVATE_CHAT_MESSAGE,
  );
  [EEntityType.NODE]: IMethodOverride = getCommonCrud(EEntityType.NODE);
  [EEntityType.NODE_LOG]: IMethodOverride = getCommonCrud(EEntityType.NODE_LOG);
  [EEntityType.MESSAGE_LOG]: IMethodOverride = getCommonCrud(EEntityType.MESSAGE_LOG);
  [EEntityType.BOT_ELEMENT]: IMethodOverride = getCommonCrud(EEntityType.BOT_ELEMENT);
  [EEntityType.BOT_SCRIPT]: IMethodOverride = getCommonCrud(EEntityType.BOT_SCRIPT);
  [EEntityType.BOT_VARIABLE]: IMethodOverride = getCommonCrud(EEntityType.BOT_VARIABLE);
}

export const apiController = new ApiController();
