import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { observer } from "mobx-react-lite";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { Button } from "../../../components/Button/Button";
import { trimText } from "../../../../_shared/Util/text";
import { generateUserLink } from "../../../components/Util/Username";
import { ClientForm } from "../../../components/ClientForm/ClientForm";

interface IClientItemProps {}

export const ClientItem: React.FunctionComponent<IClientItemProps> = observer((props) => {
  let params = useParams();
  let navigate = useNavigate();
  const { clientId } = params;
  const controller = root.clientItemController;
  useEffect(() => {
    controller.initItem(clientId).then();
  }, []);
  if (controller.state !== EAsyncState.IDLE) return <Loader />;

  return (
    <PageWrapper
      header={
        <span>
          {trimText(controller.getCardName(), 20)} {generateUserLink(controller.getUsername())}
        </span>
      }
      onSave={async () => {
        const item = await controller.saveItem();
        navigate(`/clients/${item._id}`);
      }}
      onDelete={async () => {
        const isDeleted = await controller.deleteItem();
        if (isDeleted) {
          navigate(`/clients`);
        }
      }}
      state={EAsyncState.IDLE}
      actionBar={
        <Button
          onClick={() => root.clientItemController.openCard(controller.item._id)}
          text={"Открыть карточку"}
        />
      }
    >
      <ClientForm controller={controller} />
    </PageWrapper>
  );
});
