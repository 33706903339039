import { DateTime } from "luxon";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { apiController } from "../ApiController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";

export const addTimeToField =
  (controller, field) => (timeFrame: "today" | "days" | "weeks" | "months") => {
    if (!controller.item[field] || timeFrame === "today") {
      controller.item[field] = Date.now();
      return;
    }
    return (controller.item[field] = DateTime.fromMillis(controller.item[field])
      .plus({ [timeFrame]: 1 })
      .toMillis());
  };

export const genPotentialId = () => {
  return Date.now() + "_" + (Math.random() * 10000000000).toFixed(0);
};

export const getUniqId = async (entType: EEntityType, test_id?: string) => {
  const potentialId = test_id ? test_id : genPotentialId();
  const resp = await apiController[entType][ECrudMethods.COUNT]({
    unique_id: potentialId,
  });
  if (resp.count === 0) return potentialId;
  return getUniqId(entType);
};
