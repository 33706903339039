import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { InternalClientTable } from "../../../components/InternalClientTable/InternalClientTable";

export const ClientTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.clientTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Клиенты"} state={EAsyncState.IDLE}>
      <InternalClientTable
        tableController={root.clientTableController}
        onItemClick={(item) => root.clientItemController.openCard(item._id)}
      />
    </PageWrapper>
  );
});
