import { keyBy } from "lodash";
import { EClientStatus } from "../_enums/EClientStatus";

export const CLIENT_STATUSES = [
  { label: "К обработке", value: EClientStatus.TO_BE_PROCESSED },
  { label: "Платит", value: EClientStatus.CLOSED },
  { label: "Тестирует", value: EClientStatus.TRIAL },
  { label: "В обработке", value: EClientStatus.IN_PROCESS },
  { label: "Не подходит", value: EClientStatus.WRONG },
  { label: "Игнорит", value: EClientStatus.IGNORE },
  { label: "Потерян контакт", value: EClientStatus.LOST_CONTACT },
  { label: "Упущен", value: EClientStatus.LOST },
  { label: "Отказ", value: EClientStatus.REJECT },
  { label: "В бане", value: EClientStatus.IS_RESTRICTED },
  { label: "Необходима доработка", value: EClientStatus.NEED_DEVELOPMENT },
  { label: "Ошибка обработки", value: EClientStatus.ERROR },
];

export const CLIENT_STATUS_KEYED = keyBy(CLIENT_STATUSES, "value");
