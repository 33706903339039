import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return { title: regex };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    {
      title: "Место",
      field: "title",
      format: (val: string, item: any) => {
        const country = root.locationItemController.location_map[item.parent_id];
        return !country ? "" : val;
      },
    },
    {
      title: "Принадлежит к",
      field: "parent_id",
      format: (val: string, item: any) => {
        const country = root.locationItemController.location_map[val];
        return country?.title || item.title;
      },
    },
    {
      title: "Приватный",
      field: "is_private",
      format: (val) => {
        return !!val ? "Да" : "";
      },
    },
  ],
};

export const LocationTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.locationTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Места"} state={EAsyncState.IDLE} onNew={() => navigate("/locations/new")}>
      <ControlledTable
        controller={root.locationTableController}
        options={tableOptions}
        getItemHref={(item) => `/locations/${item._id}`}
      />
    </PageWrapper>
  );
});
