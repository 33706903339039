import { RootController } from "../RootController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { apiController } from "../ApiController";
import { makeAutoObservable, toJS } from "mobx";
import { each, get, keyBy, reduce, set, uniqueId } from "lodash";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { IClient } from "../../../_shared/Database/client";
import { makeToast } from "../../utils/toast";
import { IFeed } from "../../../_shared/Database/feed";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ISearchCore } from "../../../_shared/Database/searchCore";
import { IMessagingPrivateChat } from "../../../_shared/Database/messagingPrivateChat";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { generateBotText } from "../../../_shared/Util/messageGeneration/phraseGen";
import { CommonTableController } from "../_common/CommonTableController";
import { PaymentController } from "./PaymentController";
import { IBotElement } from "../../../_shared/Database/botElement";
import { prompter } from "../_common/PromptController";
import { IBotVariable } from "../../../_shared/Database/botVariable";

export class ClientItemController {
  root: RootController;
  state: EAsyncState = EAsyncState.LOADING;
  item: IClient | null = null;
  feeds: { [key: string]: IFeed } = {};
  elements: { [key: string]: IBotElement } = {};
  dialogs: { [key: string]: IMessagingPrivateChat } = {};
  searchCores: { [key: string]: ISearchCore } = {};
  isCardOpened: boolean = false;
  currentElement: string = "";
  copyElementOnGenerate: boolean = true;
  paymentController: PaymentController;
  allVariables: { [key: string]: IBotVariable } = {};

  constructor(root: RootController) {
    this.root = root;
    this.paymentController = new PaymentController(this);
    makeAutoObservable(this);
  }

  initItem = async (clientId: string | undefined) => {
    this.currentElement = "";
    if (!!clientId && clientId !== NEW_ITEM_ID) {
      return this.loadItem(clientId);
    }
    makeToast("Что-то пошло не так");
  };

  loadItem = async (ClientId: string) => {
    this.currentElement = "";
    try {
      this.dialogs = {};
      //this.state = EAsyncState.LOADING;
      const entityResp = await apiController[EEntityType.CLIENT][ECrudMethods.GETBY_ID](ClientId);
      const feedsResp = await apiController[EEntityType.FEED][ECrudMethods.LIST](
        { client_id: entityResp.entity._id },
        0,
        1000,
      );
      const feedsSearchCores = reduce(
        feedsResp.items,
        (acc, item) => {
          acc = acc.concat(item.search_core_ids);
          return acc;
        },
        [],
      );
      this.feeds = {};
      each(feedsResp.items, (pg) => {
        this.feeds[pg._id] = {
          ...pg,
          uuid: uniqueId("PG_"),
        };
      });
      if (this.root.isRoot()) {
        const dialogsResp = await apiController[EEntityType.MESSAGING_PRIVATE_CHAT][
          ECrudMethods.LIST
        ]({ tg_companion_id: entityResp.entity.client_id }, 0, 1000);
        const nodes = await apiController[EEntityType.NODE][ECrudMethods.LIST](
          { phone: { $in: dialogsResp.items.map((d) => d.node_phone) } },
          0,
          1000,
        );
        const nodesByPhone = keyBy(nodes.items, "phone");
        each(dialogsResp.items, (dialog) => {
          dialog.node = nodesByPhone[dialog.node_phone];
          this.dialogs[dialog._id] = dialog;
        });
      }

      const searchCoresResp = await apiController[EEntityType.SEARCH_CORE][ECrudMethods.LIST](
        { _id: { $in: feedsSearchCores } },
        0,
        10000,
      );
      each(searchCoresResp.items, (s) => {
        this.searchCores[s._id] = s;
      });

      const botVariablesResp = await apiController[EEntityType.BOT_VARIABLE][ECrudMethods.LIST](
        {},
        0,
        1000,
      );
      each(botVariablesResp.items, (v) => {
        this.allVariables[v.unique_id] = v;
      });

      this.item = entityResp.entity;
      this.state = EAsyncState.IDLE;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
    }
  };

  updateTables = () => {
    this.root.targetListItemController.targetClientTableController.items =
      this.root.targetListItemController.targetClientTableController.items.map((item) => {
        if (item.client_id === this.item.client_id) {
          return this.item;
        } else return item;
      });
    this.root.clientTableController.items = this.root.clientTableController.items.map((item) => {
      if (item.client_id === this.item.client_id) {
        return this.item;
      } else return item;
    });
  };

  changeItem = (path: string) => async (newValue: any) => {
    if (!this.item) return;
    set(this.item, path, newValue);
  };

  saveItem = async () => {
    if (!this.item) return;
    try {
      //this.state = EAsyncState.LOADING;
      const itemData = toJS(this.item);
      const resp = await apiController[EEntityType.CLIENT][ECrudMethods.UPDATE]({
        ...itemData,
        _id: itemData._id === NEW_ITEM_ID ? undefined : itemData._id,
      });

      for (const feedId in this.feeds) {
        await apiController[EEntityType.FEED][ECrudMethods.UPDATE]({
          _id: this.feeds[feedId]._id,
          location_ids: this.feeds[feedId].location_ids,
          is_muted: this.feeds[feedId].is_muted,
          search_core_ids: this.feeds[feedId].search_core_ids,
          payed_to: this.feeds[feedId].payed_to,
          blacklist_words: this.feeds[feedId].blacklist_words,
        });
      }
      await this.loadItem(resp.entity._id);
      makeToast("Сохранено");
      this.updateTables();
      return resp.entity;
    } catch (e) {
      console.error("Error: ", e);
      makeToast("Что-то пошло не так");
      this.state = EAsyncState.FAILURE;
    }
  };

  deleteItem = async () => {
    if (!this.item || !this.item._id) return false;
    if (!(await prompter.slider("Вы действительно хотите удалить клиента? " + this.getCardName())))
      return false;
    if (
      !(await prompter.slider(
        "Это удаление клиента, однозначное решение? " +
          this.getCardName() +
          " удалится из базы НАВСЕГДА",
      ))
    )
      return false;
    try {
      this.state = EAsyncState.LOADING;
      await apiController[EEntityType.FEED][ECrudMethods.DELETE_MANY_BY_QUERY]({
        client_id: this.item._id + "",
      });
      await apiController[EEntityType.CLIENT][ECrudMethods.DELETE](this.item._id);
      this.state = EAsyncState.IDLE;
      return true;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
      return false;
    }
  };

  changeVariable = async (varibleUniqueId: string) => {
    const newValue = await prompter.prompt("Введите новое значение:");

    if (newValue === "" && (await prompter.confirm("Хотите удалить переменную?"))) {
      delete this.item.bot_variables[varibleUniqueId];
      return;
    }

    // TODO: Make sure that type of variable is right. Date -> int, number -> int, text -> text etc
    this.item.bot_variables[varibleUniqueId] = parseInt(newValue);
  };

  changeLocations = (feedId: string, newLocations: any[]) => {
    this.feeds[feedId].location_ids = newLocations;
  };

  changeMuteState = (feedId: string, newMuteState: boolean) => {
    this.feeds[feedId].is_muted = newMuteState;
  };

  changeCores = (feedId: string, newCores: any[]) => {
    this.feeds[feedId].search_core_ids = newCores;
  };
  onChangeBlacklistWords = (feedId: string, newWords: string) => {
    this.feeds[feedId].blacklist_words = newWords;
  };

  getUsername = () => {
    if (this.item?.username) {
      return this.item?.username;
    }
    return "";
  };

  getCardName = (client?: IClient) => {
    const item = client ? client : this.item;
    if (!item) return "Загрузка";
    return item.first_name + " " + item.last_name + " ";
  };

  openCard = async (clientId: string) => {
    if (this.item?._id + "" !== clientId + "") {
      await this.loadItem(clientId + "");
    }
    this.isCardOpened = true;
  };

  changeClientLocation = (locations) => {
    this.changeItem("location_ids")(locations.map((l) => l._id));
  };

  closeCard = () => {
    this.isCardOpened = false;
  };

  isClientValidToSpam = async (client?: IClient) => {
    const item = client ? client : this.item;
    const clientName = this.getCardName(item);
    const hasUserName = !!item.username;
    if (!Object.keys(item.messages).length && !hasUserName) {
      makeToast("Нет коннекта с юзером " + clientName);
      return false;
    }
    return true;
  };

  changeBotScript = async (newBotScript: string | null) => {
    if (!newBotScript) {
      if (
        !(await prompter.confirm(`
                Хотите отменить действие скрипта для этого пользователя?
            `))
      )
        return;
      this.item.bot_control_script_id = null;
    } else {
      if (!(await this.isClientValidToSpam())) {
        await prompter.alert("Нет коннекта с юзером");
        return;
      }
      if (
        !(await prompter.confirm(`
                Назначить новый скрипт бота для пользователя?
            `))
      )
        return;
      this.item.bot_control_script_id = newBotScript;
    }
    await this.saveItem();
  };

  assignBotControl = async (tableController: CommonTableController, isNeedSchedule: boolean) => {
    if (
      !(await prompter.confirm(`
            Вы действительно хотите начать множественную операцию?
        `))
    )
      return;
    const items = tableController.items;
    const selected = tableController.selected || [];
    let operationCount = 0;
    let botScriptId = null;
    if (isNeedSchedule) {
      botScriptId = await prompter.asyncSelect(
        "Назначте скрипт для управления пользователями",
        EEntityType.BOT_SCRIPT,
      );
    }
    for (const client of items) {
      if (!selected.includes(client._id + "")) {
        continue;
      }
      if (!isNeedSchedule) {
        if (!client.bot_control_script_id) {
          continue;
        }
        await apiController[EEntityType.CLIENT][ECrudMethods.UPDATE]({
          _id: client._id,
          bot_control_script_id: null,
        });
        operationCount = operationCount + 1;
      } else {
        if (!botScriptId) return;
        if (await this.isClientValidToSpam(client)) {
          await apiController[EEntityType.CLIENT][ECrudMethods.UPDATE]({
            _id: client._id,
            bot_control_script_id: botScriptId,
          });
          operationCount = operationCount + 1;
        }
      }
    }
    tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await tableController.loadTable();
  };

  getClientElementSettings = async (id?: string) => {
    const clientId = id ? id : this.item._id + "";
    let {
      location: { country, city },
    } = await apiController.clientUtils.getLocation(clientId);
    const allWorldId = process.env.REACT_APP_WORLD_LOCATION_ID;
    if (!country || !city || city._id + "" === allWorldId || country._id + "" === allWorldId) {
      makeToast("Не распознана страна клиента");
      return;
    }
    const clientBestLinkResp = await apiController.clientUtils.getBestSettingsLink(clientId);
    if (!clientBestLinkResp.link) {
      makeToast("Не удалось подобрать ссылку для настройки (попробуйте указать страну)");
      return;
    }
    return {
      city,
      country,
      link: clientBestLinkResp.link,
    };
  };

  genPhaseText = async (elementId: string, clientId?: string) => {
    const settings = await this.getClientElementSettings(clientId);
    if (!settings) return;
    const { city, country, link } = settings;
    const element = this.root.botElementTableController.findById(elementId);
    this.currentElement = generateBotText(
      {
        city: city.title,
        country: country.title,
        userSettingsLinkId: link._id + "",
      },
      get(element, "element_data.text_constructor", null),
    );

    if (this.copyElementOnGenerate) {
      await navigator.clipboard.writeText(this.currentElement + "");
    }
    return this.currentElement;
  };

  onChangeCopyElementOnGenerate = () => {
    this.copyElementOnGenerate = !this.copyElementOnGenerate;
  };

  changeDlgElement = async (dlgId, elementUniqueId) => {
    if (!(await prompter.confirm("Вы действительно хотите переместить клиента на другую фазу? ")))
      return false;
    try {
      await apiController[EEntityType.MESSAGING_PRIVATE_CHAT][ECrudMethods.UPDATE]({
        _id: dlgId,
        bot_element_unique_id: elementUniqueId,
      });
      makeToast("Успешно перемещен клиент на фазу " + elementUniqueId);
      await this.loadItem(this.item._id);
    } catch (e) {
      makeToast("Ошибка при переходе на фазу" + elementUniqueId);
    }
  };
}
