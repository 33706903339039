import React, { useRef } from "react";
import style from "./BotEditor.module.scss";
import { observer } from "mobx-react-lite";

interface IDraggableHOCProps {
  id: string;
  element: any;
  elementMouseDown: (id: string, coords: any, offset: any) => void;
  deleteNode: (id: string) => void;
  children: React.ReactNode;
  className: string;
}

export const DraggableHOC: React.FC<IDraggableHOCProps> = observer((props) => {
  const rectRef = useRef(null);

  const { id, element } = props;

  const handleMouseDown = (e) => {
    e.stopPropagation();
    if (e.button != 0) return;
    const coords = { x: e.pageX, y: e.pageY };
    const rect = rectRef.current.getBoundingClientRect();
    // For test purposes
    const offset = {
      x: e.pageX - rect.x,
      y: e.pageY - rect.y,
    };

    props.elementMouseDown(element.unique_id, coords, offset);
  };

  const handleDelete = (e) => {
    if (e.button != 0) return;
    props.deleteNode(element.unique_id);
  };

  return (
    <div
      ref={rectRef}
      onMouseDown={handleMouseDown}
      className={style.draggable_hoc + " " + props.className}
      id={element.unique_id}
      style={{ left: element.x, top: element.y }}
    >
      {props.children}
    </div>
  );
});
