import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ITransaction } from "../../../_shared/Database/transaction";
import { CommonEntityController } from "../_common/CommonEntityController";
import { makeAutoObservable } from "mobx";
import { RootController } from "../RootController";
import { ECurrency } from "../../../_shared/_enums/ECurrency";

const newItem: ITransaction = {
  _id: NEW_ITEM_ID,
  value: 0,
  usd_value: 0,
  currency: ECurrency.USD,
  created_at: Date.now(),
  is_income: true,
  client_id: "",
  comment: "",
  payment_target: null,
};

export class TransactionItemController {
  root: RootController;
  entityController: CommonEntityController<ITransaction>;

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ITransaction>(
      EEntityType.TRANSACTION,
      newItem,
    );
    makeAutoObservable(this);
  }

  initItem = async (transactionItemId: string) => {
    await this.entityController.initItem(transactionItemId);
  };
}
