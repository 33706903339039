import { EConditionTerm } from "../_interfaces/nodeTreeEditor/INodeTreeEditor";

export const NEW_ITEM_ID = "new";

export const newLocationConditions = {
  version: "v1",
  content: {
    max_links: 99999,
    max_length: 999999,
    whitelist: { term: EConditionTerm.OR, content: [] },
    blacklist: { term: EConditionTerm.OR, content: [] },
  },
};
