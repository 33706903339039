import { apiController } from "../../controllers/ApiController";
import style from "./Header.module.scss";
import { observer } from "mobx-react-lite";

const envVer = `${process.env.NODE_ENV.substring(0, 3).toUpperCase()}:${
  process.env.REACT_APP_VERSION
}`;

export const Header = observer(() => {
  return (
    <header className={style.Header}>
      <span className={style.Logo}>
        {process.env.REACT_APP_NAME} - {envVer} {apiController.reqCount}
      </span>
      <div className={style.ClientSettings}></div>
      <div className={style.ClientSettings}></div>
    </header>
  );
});
