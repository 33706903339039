import { observer } from "mobx-react-lite";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { Loader } from "../../components/Loader/Loader";
import style from "./CommonItemCardModal.module.scss";
import { Link } from "react-router-dom";
import { CommonEntityController } from "../../controllers/_common/CommonEntityController";

interface ICommonItemCardModalProps {
  controller: CommonEntityController<any>;
  getTitle?: (item: any) => string;
  getItemHref?: (item: any) => string;
  children: React.ReactNode;
  additionalButtons?: React.ReactNode;
}

export const CommonItemCardModal: React.FunctionComponent<ICommonItemCardModalProps> = observer(
  ({ controller, getTitle, getItemHref, children, additionalButtons }) => {
    if (!controller.isCardOpened) return null;
    const openFullPage = () => {
      controller.closeCard();
    };
    return (
      <Modal
        header={
          <span>{getTitle ? getTitle(controller.item) : "Элемент " + controller.item._id}</span>
        }
        footer={
          <div className={style.footerActions}>
            <div>{additionalButtons ? additionalButtons : null}</div>
            <div>
              {getItemHref ? (
                <Link to={getItemHref(controller.item)} onClick={openFullPage}>
                  Открыть полностью
                </Link>
              ) : null}
              <Button text={"Сохранить"} onClick={controller.saveItem} />
            </div>
          </div>
        }
        onClose={controller.closeCard}
      >
        {controller.state === EAsyncState.LOADING ? <Loader /> : children}
      </Modal>
    );
  },
);
