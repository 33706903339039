import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import AsyncSelect from "react-select/async";
import { customSelectStyles } from "../../utils/select";
import { apiController } from "../../controllers/ApiController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { filter, find, get } from "lodash";

interface IAsyncInput {
  value: string | string[];
  entityType: EEntityType;
  isMulti?: boolean;
  isEnabled?: boolean;
  onChange?: (newValue: string | string[]) => void;
  titleField?: string;
  valueField?: string;
  formatInputQuery?: (inp: string) => any;
  onChangeRaw?: (obj: any) => void;
  additionalFilters?: any;
}

const defaultTitleField = "title";
const defaultValueField = "_id";
const defaulFormatInputQuery = (inp) => {
  return {
    title: {
      $regex: inp,
      $options: "i",
    },
  };
};

export const AsyncInput: React.FunctionComponent<IAsyncInput> = observer(
  ({
    value,
    entityType,
    onChange,
    isEnabled = true,
    isMulti = false,
    onChangeRaw,
    titleField = defaultTitleField,
    valueField = defaultValueField,
    formatInputQuery = defaulFormatInputQuery,
    additionalFilters = {},
  }) => {
    const handleLoadOptions = async (inputValue) => {
      const resp = await apiController[entityType][ECrudMethods.LIST](
        {
          ...additionalFilters,
          ...formatInputQuery(inputValue),
        },
        0,
        10000000,
      );
      return resp.items;
    };
    const [items, setItems] = useState([]);

    useEffect(() => {
      const fetchItems = async () => {
        const items = await handleLoadOptions("");
        setItems(items);
        return items;
      };
      fetchItems().then();
    }, []);

    let selectValue = null;
    if (value) {
      if (isMulti) {
        selectValue = filter(items, (i) => value.includes(get(i, valueField, null)));
      } else {
        selectValue = find(items, (i) => get(i, valueField, null) === value);
      }
    }

    const handleInputChange = (changedItem) => {
      if (onChangeRaw) {
        onChangeRaw(changedItem);
      }
      if (!onChange) return;
      if (changedItem) {
        if (isMulti) {
          onChange(changedItem.map((i) => get(i, valueField, null)));
          return;
        }
        onChange(get(changedItem, valueField, null));
        return;
      }
      if (isMulti) {
        onChange([]);
        return;
      }
      onChange(null);
    };

    return (
      <AsyncSelect
        defaultOptions={items}
        value={selectValue}
        getOptionLabel={(el) => get(el, titleField, "")}
        getOptionValue={(el) => get(el, valueField, null)}
        styles={customSelectStyles}
        menuPortalTarget={document.body}
        isClearable
        isDisabled={!isEnabled}
        loadOptions={handleLoadOptions}
        onChange={handleInputChange}
        isMulti={isMulti}
      />
    );
  },
);
