import { RootController } from "../RootController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { makeAutoObservable } from "mobx";
import { apiController } from "../ApiController";

export class NodeReportController {
  root: RootController;
  state: EAsyncState = EAsyncState.IDLE;
  report: any = {};

  constructor(root: RootController) {
    this.root = root;
    makeAutoObservable(this);
  }

  loadReport = async () => {
    this.state = EAsyncState.LOADING;
    const result = await apiController.nodes.getNodeReport();
    this.report = result;
    this.state = EAsyncState.IDLE;
  };
}
