import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { AddNewSourcesModal } from "./AddNewSourcesModal";
import { Button, EButtonType } from "../../../components/Button/Button";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import style from "./LeadSourceTable.module.scss";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $or: [
            { source_id: regex },
            { title: regex },
            { username: regex },
            { source_id: val.trim() },
            { tg_node_phone: regex },
          ],
        };
      },
      type: "string",
    },
    locationSearch: {
      title: "Место",
      makeQuery: (country) => {
        if (!country) return {};
        return { location_ids: { $in: [country._id] } };
      },
      type: "custom",
      customRender: (countryId, onChange) => {
        return (
          <AsyncInput
            value={countryId ? countryId._id : null}
            entityType={EEntityType.LOCATION}
            onChangeRaw={onChange}
          />
        );
      },
    },
    participants: {
      title: "Участников от",
      makeQuery: (val) => {
        return {
          $and: [
            {
              $or: [{ participants: { $gte: parseInt(val) } }],
            },
          ],
        };
      },
      type: "string",
    },
    isDeleted: {
      title: "Удален",
      makeQuery: (val) => {
        if (!!val) {
          return {
            $and: [
              {
                deleted_at: { $gt: 0 },
              },
            ],
          };
        }
        return {
          $and: [
            {
              deleted_at: null,
            },
          ],
        };
      },
      type: "checkbox",
    },
    isNeedReview: {
      title: "Ревью",
      makeQuery: (val) => {
        if (!val) return {};
        return {
          $and: [
            {
              need_review: true,
            },
          ],
        };
      },
      type: "checkbox",
    },
    noLocations: {
      title: "Без локации",
      makeQuery: (val) => {
        if (!val) return {};
        return {
          $and: [
            {
              location_ids: { $size: 0 },
            },
          ],
        };
      },
      type: "checkbox",
    },
  },
  columns: [
    {
      title: "Статус",
      field: "is_connected",
      format: (val: string, item) => {
        let connected = item.is_connected ? "🟢" : "🛑";
        let deleted = !!item.deleted_at ? "🗑️" : "";
        let need_review = item.need_review ? "👁️" : "";
        return `${connected}${deleted}${need_review}`;
      },
    },
    {
      title: "@",
      field: "username",
    },
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Места",
      field: "location_ids",
      format: (val: string[]) => {
        return val
          .map((locId) => {
            const location = root.locationItemController.location_map[locId + ""];
            return location ? location.title : "";
          })
          .join(" ,");
      },
    },
    {
      title: "Уч",
      field: "participants",
    },
    {
      title: "Node",
      field: "tg_node_phone",
    },
  ],
};

const ActionBar = observer(() => {
  return (
    <div>
      <Button
        className={style.actionBarButton}
        styling={EButtonType.DEFAULT}
        text={"🔗 Подкл"}
        onClick={() => root.leadSourceTableController.batchConnect()}
      />
      <Button
        className={style.actionBarButton}
        styling={EButtonType.DEFAULT}
        text={"🔌 Откл"}
        onClick={() => root.leadSourceTableController.batchDisconnect()}
      />
      <Button
        className={style.actionBarButton}
        styling={EButtonType.DEFAULT}
        text={"🎯 Задать локацию"}
        onClick={() => root.leadSourceTableController.batchSetLocation()}
      />
      <Button
        className={style.actionBarButton}
        styling={EButtonType.DEFAULT}
        text={"👁️ Проревьювить"}
        onClick={() => root.leadSourceTableController.batchReview()}
      />
      <Button
        className={style.actionBarButton}
        styling={EButtonType.REJECT}
        text={"🗑️ Пометить как удаленный"}
        onClick={() => root.leadSourceTableController.batchRemove(Date.now())}
      />
      <Button
        className={style.actionBarButton}
        text={"🩹 Восстановить"}
        onClick={() => root.leadSourceTableController.batchRemove(null)}
      />
      <Button
        className={style.actionBarButton}
        text={"⬇️ Добавление"}
        onClick={() => root.leadSourceTableController.openAddNewSourceModal()}
      />
    </div>
  );
});

export const LeadSourceTable = observer(() => {
  let navigate = useNavigate();
  const tableController = root.leadSourceTableController.tableController;
  useEffect(() => {
    tableController.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Источники лидов"} actionBar={<ActionBar />} state={EAsyncState.IDLE}>
      <ControlledTable
        controller={tableController}
        options={tableOptions}
        getItemHref={(item) => `/leadSources/${item._id}`}
      />
      <AddNewSourcesModal controller={root.leadSourceTableController} />
    </PageWrapper>
  );
});
