import { observer } from "mobx-react-lite";
import style from "./PromptModal.module.scss";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { prompter } from "../../controllers/_common/PromptController";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { AsyncInput } from "../../components/AsyncInput/AsyncInput";
import { Input } from "../../components/Input/Input";

const FooterPropmptSlider: React.FunctionComponent<any> = observer(() => {
  const [slider, changeSlide] = useState(0);

  useEffect(() => {
    changeSlide(0);
  }, [prompter.currentPrompt]);
  const handleSlideChange = (e) => {
    changeSlide(parseInt(e.target.value));
  };
  return (
    <div className={style.footerActions}>
      <div>
        <input type={"range"} value={slider} onChange={handleSlideChange} />
        <Button
          text={"Да"}
          disabled={slider !== 100}
          onClick={() => prompter.makeSelection(true)}
        />
      </div>
    </div>
  );
});

const FooterPropmptVariants: React.FunctionComponent<any> = observer(() => {
  return (
    <div className={style.footerActions}>
      {map(prompter.currentPrompt.variants || [], (v) => {
        return (
          <Button key={v.value} text={v.label} onClick={() => prompter.makeSelection(v.value)} />
        );
      })}
    </div>
  );
});

const FooterPropmptAsyncSelect: React.FunctionComponent<any> = observer(() => {
  const [value, changeValue] = useState(null);

  useEffect(() => {
    changeValue(null);
  }, [prompter.currentPrompt]);

  const handleValChange = (newVal) => {
    changeValue(newVal);
  };

  return (
    <div className={style.footerActions}>
      <AsyncInput
        value={value}
        onChange={handleValChange}
        entityType={prompter.currentPrompt.entityType}
      />
      <Button text={"Выбор"} disabled={!value} onClick={() => prompter.makeSelection(value)} />
    </div>
  );
});

const FooterPropmptText: React.FunctionComponent<any> = observer(() => {
  const [text, changeText] = useState("");

  useEffect(() => {
    changeText("");
  }, [prompter.currentPrompt]);

  return (
    <div className={style.footerActions}>
      <div className={style.footerInoputRow}>
        <Input isEnabled value={text} onValueChange={changeText} />
        <Button text={"Ok"} onClick={() => prompter.makeSelection(text)} />
      </div>
    </div>
  );
});

const FooterPromptActions: React.FunctionComponent<any> = observer(() => {
  if (prompter.currentPrompt.textInput) {
    return <FooterPropmptText />;
  }
  if (prompter.currentPrompt.variants) {
    return <FooterPropmptVariants />;
  }
  if (prompter.currentPrompt.slider) {
    return <FooterPropmptSlider />;
  }
  if (prompter.currentPrompt.entityType) {
    return <FooterPropmptAsyncSelect />;
  }
  return null;
});

export const PromptModal: React.FunctionComponent<any> = observer(() => {
  if (!prompter.currentPrompt) return null;

  return (
    <Modal
      header={"Сообщение"}
      className={style.PromptModal}
      footer={<FooterPromptActions />}
      onClose={() => prompter.makeSelection(null)}
    >
      {prompter.currentPrompt.message}
    </Modal>
  );
});
