import React from "react";
import { observer } from "mobx-react-lite";
import { EDragMode } from "../../../_shared/_interfaces/botEditor/botEditor";

interface IReceiverHOCProps {
  id: string;
  slotId: number;
  selectConnectionTarget: (elemId: string, slotId: number) => void;
  releaseConnectionTarget: () => void;
  dragMode: EDragMode;
  isActive: boolean;
  className: string;
  children: React.ReactNode;
}

export const ReceiverHOC: React.FC<IReceiverHOCProps> = observer((props) => {
  const { id, slotId, isActive } = props;

  const handleOnMouseEnter = (e) => {
    props.selectConnectionTarget(id, slotId);
  };

  const handleOnMouseLeave = (e) => {
    props.releaseConnectionTarget();
  };

  const events = isActive
    ? {
        onMouseEnter: handleOnMouseEnter,
        onMouseLeave: handleOnMouseLeave,
      }
    : {};

  return (
    <div {...events} className={props.className}>
      {props.children}
    </div>
  );
});
