import React, { useEffect, useRef } from "react";
import { ReactPanZoom } from "./ReactPanZoom";
import { observer } from "mobx-react-lite";
import { BotEditorController } from "../../controllers/BotScript/BotEditorController";
import { map } from "lodash";
import style from "./BotEditor.module.scss";
import { BotElement } from "./BotElement";
import { WindowEvents } from "../Util/Window";
import { Connection } from "./Connection";
import { EBotElementType } from "../../../_shared/_enums/EBotElementEnums";
import { Button, EButtonType } from "../Button/Button";
import { root } from "../../controllers/RootController";
import { getBotElementTitle, getElementTag } from "../../../_shared/Util/botElementUtils";
import { Input } from "../Input/Input";
import { CommonItemCardModal } from "../../modals/CommonItemCardModal/CommonItemCardModal";
import { BotElementItemForm } from "../../pages/botElements/BotElementItem/BotElementItemForm";
import { SelectionBox } from "./SelectionBox";

export const BotEditor: React.FC<{ controller: BotEditorController }> = observer(
  ({ controller }) => {
    const canvasRef = useRef(null);
    const handleMouseMove = (e) => {
      const coords = { x: e.pageX, y: e.pageY };
      controller.mouseMove(coords);
    };

    const handleCanvasClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      controller.startSelection(e);
    };

    useEffect(() => {
      const rect = canvasRef.current.getBoundingClientRect();
      controller.init(rect);
    }, []);

    const scriptController = root.botScriptItemController;
    const scriptItem = scriptController.entityController.item;

    return (
      <>
        <WindowEvents
          mouseup={controller.mouseUp}
          keyup={controller.handleKeyUp}
          copy={controller.handleCopy}
          paste={controller.handlePaste}
        />
        <div className={style.se_content_wrapper} ref={canvasRef}>
          <div className={style.toolbar_wrapper}>
            <Input
              className={style.toolbar_title}
              value={scriptItem.title}
              onValueChange={scriptController.entityController.changeItem("title")}
              isEnabled={true}
            />
            {!!scriptItem._id && scriptItem._id !== "new" ? (
              <div className={style.toolbar_buttons}>
                {map(EBotElementType, (t) => {
                  return (
                    <Button
                      key={t}
                      onClick={() => root.botEditorController.addBotElement(t)}
                      text={"+ " + getElementTag(t)}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className={style.se_iframe_wrapper}>
            <div
              className={style.se_iframe_outer_wrapper}
              onMouseMove={handleMouseMove}
              onMouseDown={handleCanvasClick}
            >
              <ReactPanZoom
                matrixData={controller.matrix}
                viewportChange={controller.viewportChange}
              >
                <div className={style.bot_editor}>
                  {controller.isInited && (
                    <svg className={style.connections}>
                      {map(controller.getElements(), (element) => {
                        return (element.connections || []).map((conn, index) => {
                          return (
                            <Connection
                              key={element.unique_id + "_" + index}
                              connection={conn}
                              controller={controller}
                              fromElement={element}
                              isSelected={controller.isConnectionSelected(element.unique_id, index)}
                              onConnectionClick={() =>
                                controller.selectConnectionIndex(element.unique_id, index)
                              }
                            />
                          );
                        });
                      })}
                    </svg>
                  )}
                  <div className={style.nodes}>
                    {map(controller.getElements(), (element, id) => {
                      return <BotElement element={element} key={id} controller={controller} />;
                    })}
                    <SelectionBox box={root.botEditorController.selectionBox} />
                  </div>
                </div>
              </ReactPanZoom>
            </div>
          </div>
          <CommonItemCardModal
            controller={root.botElementItemController.entityController}
            getItemHref={(item) => `/botScripts/elements/${item._id}`}
            getTitle={getBotElementTitle}
            additionalButtons={
              root.botElementItemController.entityController?.item &&
              (root.botElementItemController.entityController?.item.type ===
                EBotElementType.PHASE ||
                root.botElementItemController.entityController?.item.type ===
                  EBotElementType.RESPONSE_CHECK) ? (
                <Button
                  text={"👀 Проверка"}
                  onClick={() => root.botElementItemController.openTesting()}
                  styling={EButtonType.DEFAULT}
                />
              ) : (
                <></>
              )
            }
          >
            <BotElementItemForm />
          </CommonItemCardModal>
        </div>
      </>
    );
  },
);
