import style from "./Table.module.scss";
import { get, map } from "lodash";
import React from "react";
import { observer } from "mobx-react-lite";
import { Form, FormInputWrapper } from "../Form/Form";
import { GridRow } from "../Grid/GridRow";
import { Input } from "../Input/Input";
import ReactPaginate from "react-paginate";
import { CommonTableController } from "../../controllers/_common/CommonTableController";
import { EGridItemSize, GridItem } from "../Grid/GridItem";
import { useNavigate } from "react-router-dom";
import { prompter } from "../../controllers/_common/PromptController";

interface IFilter {
  title: string;
  makeQuery?: (val, otherValues?) => any;
  type: string;
  customRender?: (value: any, onChange: (newVal: any) => void) => React.ReactNode;
}

interface IColumn {
  title: string;
  field: string;
  columnStyle?: any;
  format?: (val: any, item?: any, rowIndex?: number) => any;
}

export interface ITableOptions {
  isSelectable?: boolean;
  filters?: { [name: string]: IFilter };
  columns: IColumn[];
}

interface ITableProps {
  pagination?: any;
  filterValues?: any;
  sortValues?: any;
  items: any[];
  options: ITableOptions;
  onItemClick?: (item: any) => void;
  getItemHref?: (item: any) => string;
  onFilterChange?: (options: ITableOptions, filterName, filterValue) => void;
  onSortChange?: (columnName: string) => void;
  onPageChange?: (newPage: number) => void;
  onSelectionChange?: (idOrAll: string) => void;
  selectedItems?: string[];
}

export const TableFilters: React.FunctionComponent<{
  filterValues: any;
  options: ITableOptions;
  onFilterChange: ITableProps["onFilterChange"];
}> = observer(({ filterValues, options, onFilterChange }) => {
  return (
    <Form>
      <GridRow>
        {map(options.filters, (filter, filterName) => {
          return (
            <GridItem size={EGridItemSize.medium} key={filterName}>
              <FormInputWrapper key={filterName}>
                <label>{filter.title}</label>
                {filter.customRender ? (
                  filter.customRender(filterValues[filterName], (val) =>
                    onFilterChange(options, filterName, val),
                  )
                ) : (
                  <Input
                    title={filter.title}
                    type={filter.type}
                    value={filterValues[filterName]}
                    onValueChange={(val) => onFilterChange(options, filterName, val)}
                    isEnabled={!!onFilterChange}
                  />
                )}
              </FormInputWrapper>
            </GridItem>
          );
        })}
      </GridRow>
    </Form>
  );
});

export const ControlledTable: React.FC<{
  controller: CommonTableController;
  onItemClick?: (item: any) => void;
  getItemHref?: (item: any) => string;
  options: ITableOptions;
}> = observer(({ options, controller, onItemClick, getItemHref }) => {
  return (
    <Table
      options={options}
      onPageChange={controller.changePage}
      pagination={controller.pagination()}
      filterValues={controller.filterValues}
      sortValues={controller.sort}
      items={controller.items}
      onFilterChange={controller.onFilterChange}
      onSortChange={controller.onSortChange}
      onItemClick={onItemClick}
      getItemHref={getItemHref}
      onSelectionChange={controller.onSelectionChange}
      selectedItems={controller.selected || []}
    />
  );
});

const getSortIcon = (sortValue) => {
  if (!sortValue) return null;
  switch (sortValue) {
    case -1:
      return <span className={style.sortIcon}>↓</span>;
    case 1:
      return <span className={style.sortIcon}>↑</span>;
  }
};

export const Table: React.FunctionComponent<ITableProps> = observer(
  ({
    onPageChange,
    pagination,
    sortValues = {},
    filterValues,
    items,
    options,
    onFilterChange,
    onSortChange,
    onItemClick,
    getItemHref,
    onSelectionChange,
    selectedItems = [],
  }) => {
    let navigate = useNavigate();
    const handleSortChange = (field: string) => (e) => {
      if (!onSortChange) return;
      onSortChange(field);
    };

    const handleRowClick = (item) => (e) => {
      if (getItemHref) {
        if (e.button === 0) {
          if (onItemClick) {
            onItemClick(item);
            return;
          }
          navigate(getItemHref(item));
          return;
        }
        (window as any).open(getItemHref(item), "_blank");
        return;
      }
      if (onItemClick) {
        onItemClick(item);
      }
    };

    const handleGotoPage = async () => {
      let newPage: any = await prompter.prompt("Номер страницы");
      newPage = parseInt(newPage);
      newPage = (newPage || 0) <= 0 ? 0 : newPage - 1;
      onPageChange(newPage);
    };

    return (
      <div className={style.Table}>
        {/* FILTERS */}
        <div className={style.filterArea}>
          <div className={style.filterWrapper}>
            {options.filters ? (
              <TableFilters
                onFilterChange={onFilterChange}
                filterValues={filterValues}
                options={options}
              />
            ) : null}
          </div>
          <div className={style.rightSide}>
            {pagination ? (
              <div className={style.paginationArea}>
                <ReactPaginate
                  nextLabel=">"
                  previousLabel="<"
                  pageClassName={style.pageLink}
                  pageLinkClassName={style.pageLink}
                  previousClassName={style.pageItem}
                  previousLinkClassName={style.pageLink}
                  activeClassName={style.selected}
                  nextClassName={style.pageItem}
                  nextLinkClassName={style.pageLink}
                  breakLabel="..."
                  breakClassName={style.pageItem}
                  breakLinkClassName={style.pageLink}
                  containerClassName={style.paginationWrapper}
                  onPageChange={({ selected }) => onPageChange(selected)}
                  pageRangeDisplayed={2}
                  pageCount={pagination.pages}
                  forcePage={pagination.page}
                  renderOnZeroPageCount={null}
                />
                <label className={style.navTotalLabel}>
                  Total of {pagination.count} <div onClick={handleGotoPage}>[переход]</div>
                </label>
              </div>
            ) : null}
          </div>
        </div>

        {/* HEADERS */}
        <table className={style.TableHeader}>
          <thead>
            <tr>
              {!!onSelectionChange && (
                <td
                  className={style.tableColumn}
                  onClick={() => onSelectionChange("all")}
                  style={{ width: "10px" }}
                >
                  all
                </td>
              )}
              {map(options.columns, ({ title, field, columnStyle }) => {
                return (
                  <td
                    key={field}
                    className={style.tableColumn}
                    onClick={handleSortChange(field)}
                    {...(columnStyle ? { style: columnStyle } : {})}
                  >
                    <div className={style.tableColumnInner}>
                      <label>{title}</label> {getSortIcon(sortValues[field])}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {map(items, (item, index) => {
              const isSelected = selectedItems.includes(item._id);
              return (
                <tr key={item._id || index} onMouseDown={handleRowClick(item)}>
                  {!!onSelectionChange && (
                    <td
                      className={style.tableColumn}
                      style={{ width: "10px" }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <Input
                        type={"checkbox"}
                        value={isSelected as any}
                        isEnabled={true}
                        onValueChange={() => onSelectionChange(item._id + "")}
                      />
                    </td>
                  )}
                  {map(options.columns, ({ field, format, columnStyle }) => {
                    const raw = get(item, field);
                    const value = format ? format(raw, item, index) : raw;
                    return (
                      <td key={field} {...(columnStyle ? { style: columnStyle } : {})}>
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
);
