import styles from "../reports.module.scss";
import { observer } from "mobx-react-lite";
import { root } from "../../../controllers/RootController";
import { NodeChart } from "./NodeChart";

export const NodeInfo = observer(({ nodePhone }: { nodePhone: string }) => {
  const chartData = root.nodeReportController.report;
  const nodeData = chartData[nodePhone];

  return (
    <div className={styles.nodeInfo}>
      <h4>
        {nodePhone} Sum: {nodeData.sum} Avg: {nodeData.avg} Chats: {nodeData.activeChats}
      </h4>
      <NodeChart nodePhone={nodePhone} />
    </div>
  );
});
