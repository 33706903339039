import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { CommonTableController } from "../_common/CommonTableController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { makeToast } from "../../utils/toast";
import { apiController } from "../ApiController";
import { each } from "lodash";
import { ILeadSource } from "../../../_shared/Database/leadSource";
import { prompter } from "../_common/PromptController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { betterConsoleLog } from "telegram/Helpers";

export class LeadSourceTableController {
  root: RootController;
  tableController: CommonTableController;
  sourceScanModalOpen: boolean = false;
  sourcesText: string = "";
  sourceScanModalMode: "add" | "queue" | "processed" = "add";

  scanState: any = null;
  scanLoadingState: EAsyncState = EAsyncState.IDLE;

  constructor(root: RootController) {
    this.tableController = new CommonTableController(
      EEntityType.LEAD_SOURCE,
      {
        filter: {
          "raw_chat.broadcast": false,
        },
      },
      {
        filterValues: {
          isDeleted: false,
        },
        filter: {
          deleted_at: null,
        },
      },
    );
    this.root = root;
    makeAutoObservable(this);
  }

  changeSourcesText = (newText: string) => {
    this.sourcesText = newText;
  };

  processSources = async (text: string = this.sourcesText) => {
    if (text.trim().length === 0) {
      return makeToast("Строка для поиска не должна быть пустая");
    }
    try {
      const resp = await apiController.scanner.scanSources(text);
      await this.loadScanState();
      if (resp.result) {
        makeToast("Успешно отправлено на скан");
      }
    } catch (e) {
      console.error("Error: ", e);
      return makeToast("Что-то пошло не так...");
    }
  };

  dequeueQuery = async (queue: string) => {
    try {
      const resp = await apiController.scanner.dequeueQuery(queue);
      await this.loadScanState();
    } catch (e) {
      this.scanLoadingState = EAsyncState.FAILURE;
      makeToast("Не удалось получить состояние сканировани");
    }
  };

  resetScan = async () => {
    try {
      if (!(await prompter.slider(`Вы уверены, что хотите полностью сбросить сканирование?`)))
        return false;
      this.scanLoadingState = EAsyncState.LOADING;
      const resp = await apiController.scanner.resetScan();
      await this.loadScanState();
      this.scanLoadingState = EAsyncState.IDLE;
    } catch (e) {
      this.scanLoadingState = EAsyncState.FAILURE;
      makeToast("Не удалось получить состояние сканировани");
    }
  };

  loadScanState = async () => {
    try {
      this.scanLoadingState = EAsyncState.LOADING;
      const resp = await apiController.scanner.getChatScanState();
      this.scanState = resp.state;
      this.scanLoadingState = EAsyncState.IDLE;
    } catch (e) {
      this.scanLoadingState = EAsyncState.FAILURE;
      makeToast("Не удалось получить состояние сканировани");
    }
  };

  changeSourceScanMode = async (newMode) => {
    this.sourceScanModalMode = newMode;
    await this.loadScanState();
  };

  openAddNewSourceModal = async () => {
    this.sourceScanModalOpen = true;
    await this.loadScanState();
  };

  closeAddNewSourceModal = () => {
    this.sourceScanModalOpen = false;
  };

  /*  Batch actions  */

  batchConnect = async () => {
    const items = this.tableController.items;
    const selected = this.tableController.selected || [];
    let operationCount = 0;
    for (const ls of items) {
      if (!selected.includes(ls._id + "")) {
        continue;
      }
      try {
        await apiController.scanner.openChat(ls.source_id);
        operationCount++;
      } catch (e) {
        makeToast("Не получилось подключиться к " + ls.title);
      }
    }
    this.tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await this.tableController.loadTable();
  };

  batchDisconnect = async () => {
    const items = this.tableController.items;
    const selected = this.tableController.selected || [];
    let operationCount = 0;
    for (const ls of items) {
      if (!selected.includes(ls._id + "")) {
        continue;
      }
      try {
        await apiController.scanner.closeChat(ls.source_id);
        operationCount++;
      } catch (e) {
        makeToast("Не получилось отключиться от " + ls.title);
      }
    }
    this.tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await this.tableController.loadTable();
  };

  batchSetLocation = async () => {
    const items = this.tableController.items;
    const selected = this.tableController.selected || [];
    const location = await prompter.asyncSelect(
      `
            Выберите локацию для присвоения
        `,
      EEntityType.LOCATION,
    );
    let operationCount = 0;
    if (!location) {
      return;
    }
    for (const ls of items) {
      if (!selected.includes(ls._id + "")) {
        continue;
      }
      await apiController[EEntityType.LEAD_SOURCE][ECrudMethods.UPDATE]({
        _id: ls._id,
        location_ids: [location],
      });
      operationCount = operationCount + 1;
    }
    this.tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await this.tableController.loadTable();
  };

  batchReview = async () => {
    const items = this.tableController.items;
    const selected = this.tableController.selected || [];
    if (
      !(await prompter.confirm(`
            Хотите проревьювить ${selected.length} элементов?
        `))
    )
      return;
    let operationCount = 0;
    for (const ls of items) {
      if (!selected.includes(ls._id + "")) {
        continue;
      }
      await apiController[EEntityType.LEAD_SOURCE][ECrudMethods.UPDATE]({
        _id: ls._id,
        need_review: null,
      });
      operationCount = operationCount + 1;
    }
    this.tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await this.tableController.loadTable();
  };

  batchRemove = async (newTimestamp: number | null) => {
    const operationName = newTimestamp ? "удалить" : "восстановить";
    const items = this.tableController.items;
    const selected = this.tableController.selected || [];
    if (
      !(await prompter.confirm(`
            Хотите начать ${operationName} ${selected.length} элементов?
        `))
    )
      return;
    let operationCount = 0;
    for (const ls of items) {
      if (!selected.includes(ls._id + "")) {
        continue;
      }
      await apiController[EEntityType.LEAD_SOURCE][ECrudMethods.UPDATE]({
        _id: ls._id,
        deleted_at: newTimestamp,
      });
      operationCount = operationCount + 1;
    }
    this.tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await this.tableController.loadTable();
  };
}
