import { ISearchCore } from "../Database/searchCore";
import * as C from "crypto-js";
import { each } from "lodash";

const hashConditionList = (cl) => {
  if (typeof cl === "string") {
    return cl;
  }
  let output = cl.term;
  let options = JSON.stringify(cl.options);
  each(cl.content, (innerCl) => {
    output += hashConditionList(innerCl) + options;
  });
  return output;
};

export const hashCore = (core: ISearchCore) => {
  const { conditions } = core;
  const {
    content: { blacklist, max_length, max_links, whitelist },
  } = conditions;
  const hashData =
    hashConditionList(whitelist) + hashConditionList(blacklist) + max_links + max_length;
  return C.MD5(hashData) + "";
};

export const hashMesasge = (msg: string): string => {
  return C.MD5(msg) + "";
};
