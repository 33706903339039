import { INode } from "../../../../_shared/Database/node";

export const nodeName = (nodeItem: INode) => {
  if (!nodeItem.me) return "";
  return `${nodeItem.me.firstName || ""} ${nodeItem.me.lastName || ""} (@${nodeItem.me.username})`;
};

export const printRestrictions = (nodeItem: INode, printFull = true) => {
  if (!nodeItem.me) return "";
  const isRestricted = nodeItem.me.restricted;
  if (!isRestricted) return "Нет";
  if (!printFull) return "Да";
  return `${isRestricted ? JSON.stringify(nodeItem.me.restrictionReason) : ""}`;
};
