export enum ECrudMethods {
  COUNT = "count",
  AGGREGATE = "aggregate",
  LIST = "list",
  GET_BY_QUERY = "getByQuery",
  DELETE_MANY_BY_QUERY = "deleteManyByQuery",
  GETBY_ID = "getById",
  UPDATE = "update",
  DELETE = "delete",
}

export interface IMethodOverride {
  [ECrudMethods.COUNT]?: any;
  [ECrudMethods.LIST]?: any;
  [ECrudMethods.AGGREGATE]?: any;
  [ECrudMethods.GET_BY_QUERY]?: any;
  [ECrudMethods.DELETE_MANY_BY_QUERY]?: any;
  [ECrudMethods.GETBY_ID]?: any;
  [ECrudMethods.UPDATE]?: any;
  [ECrudMethods.DELETE]?: any;
}
