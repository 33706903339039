import { makeAutoObservable } from "mobx";
import { RootController } from "../RootController";
import { apiController } from "../ApiController";
import { EChatMode } from "../../../_shared/_enums/EChatMode";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { INode } from "../../../_shared/Database/node";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { prompter } from "../_common/PromptController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { makeToast } from "../../utils/toast";

export class FeedChatController {
  root: RootController;
  mode: EChatMode = EChatMode.BOTCHAT;
  chatId: string | null = null;
  node: INode | null = null;
  messages: any[] = [];
  client: any = null;
  chat: any = null;
  interval: any = null;
  openedFiles: any = {};
  getFullLog: boolean = false;
  inputMessage: string = "";
  sendStatus: EAsyncState = EAsyncState.IDLE;

  constructor(root: RootController) {
    this.root = root;
    makeAutoObservable(this);
  }

  init = async (mode: EChatMode, chatId: string) => {
    if (!this.root.isRoot()) {
      return;
    }
    this.messages = [];
    this.mode = mode;
    this.chatId = chatId;
    await this.loadChatMessages(chatId, null);
    await this.root.unreadsController.startReadMessages().then();
    if (mode === EChatMode.BOTCHAT) {
      let { client, feed } = await apiController.chats.initFeedChat(chatId);
      this.client = client;
      this.chat = feed;
    } else {
      const { entity: dialog } =
        await apiController[EEntityType.MESSAGING_PRIVATE_CHAT][ECrudMethods.GETBY_ID](chatId);
      let { entity: client } = await apiController[EEntityType.CLIENT][ECrudMethods.GET_BY_QUERY]({
        client_id: dialog.tg_companion_id,
      });
      this.client = client;
      this.chat = dialog;
    }
    await this.root.botElementTableController
      .applyDefaultFilters({ filter: { bot_script_id: this.client.bot_control_script_id } })
      .loadTable();
  };

  loadChatMessages = async (chatId: string, fromMessageId: string | null) => {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    const resp = await apiController.chats.loadChatMessages(
      this.mode,
      chatId,
      fromMessageId,
      this.getFullLog,
    );
    this.interval = setTimeout(() => {
      this.loadChatMessages(this.chatId, null);
    }, 1000);
    if (chatId !== this.chatId) return;
    this.messages = resp.data.messages;
  };

  onSend = async (html, textContent, message) => {
    if (this.sendStatus !== EAsyncState.IDLE) {
      makeToast("Другое сообщение в отправке, подождите немного");
      return;
    }
    try {
      this.sendStatus = EAsyncState.LOADING;
      const { result, message: err } = await apiController.chats.sendMessage(
        this.mode,
        this.chat._id,
        message,
      );
      if (!result) {
        throw Error(err);
      }
      await this.loadChatMessages(this.chatId, null);
      this.inputMessage = "";
    } catch (e) {
      console.error(e);
      makeToast("Произошлка ошибка, " + e.toString());
    } finally {
      this.sendStatus = EAsyncState.IDLE;
    }
  };

  onMessageDelete = (messageId) => async (e) => {
    if (!(await prompter.confirm("Удалить сообщение?!"))) return;
    const { result } = await apiController.chats.deleteMessage(this.mode, this.chatId, messageId);
  };

  onMessageFileOpen = (messageId) => {
    this.openedFiles[messageId] = !this.openedFiles[messageId];
  };

  toggleFullLog = () => {
    this.getFullLog = !this.getFullLog;
  };

  genInputText = async (elementId) => {
    const msg = await this.root.clientItemController.genPhaseText(elementId, this.client._id);
    this.inputMessage = msg;
  };

  changeInputMessage = (newContent) => {
    this.inputMessage = newContent;
  };
}
