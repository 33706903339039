import { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { observer } from "mobx-react-lite";
import { Grid } from "../../../components/Grid/Grid";
import { map } from "lodash";
import styles from "../reports.module.scss";

import { NodeInfo } from "./NodeInfo";

export const NodeAnalyticsReport = observer(() => {
  useEffect(() => {
    root.nodeReportController.loadReport().then();
  }, []);

  const chartData = root.nodeReportController.report;
  return (
    <PageWrapper
      header={"Отчет: Анатика сканирующих нод"}
      state={root.profitReportController.state}
    >
      <Grid>
        <div className={styles.nodeInfoContainer}>
          {map(chartData, (v, k) => {
            return <NodeInfo key={k} nodePhone={k} />;
          })}
        </div>
      </Grid>
    </PageWrapper>
  );
});
