import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { CommonTableController } from "../_common/CommonTableController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { apiController } from "../ApiController";
import { makeToast } from "../../utils/toast";
import { ConditionalController } from "../_common/ConditionalController";

export class SemanticController {
  root: RootController;
  tableController: CommonTableController;
  conditionalController: ConditionalController | null = null;
  analysisStatus: EAsyncState = EAsyncState.IDLE;

  currentSampleItem: any = null;
  isSamplesModalOpened = false;
  loadedSamples = [];
  samplesLoadingState = EAsyncState.LOADING;

  constructor(root: RootController) {
    this.root = root;
    this.tableController = new CommonTableController("semantics" as EEntityType);
    makeAutoObservable(this);
  }

  init = async () => {
    await this.tableController.loadTable();
  };

  getScanStatus = () => {
    if (this.analysisStatus === EAsyncState.IDLE) return "Анализировать";
    return "Анализировать...";
  };

  openSamples = async (sampleItem) => {
    this.loadedSamples = [];
    this.currentSampleItem = sampleItem;
    try {
      this.samplesLoadingState = EAsyncState.LOADING;
      this.isSamplesModalOpened = true;
      const samplesResp = await apiController.semantics.getSamples({
        text: sampleItem.text,
        sampleCount: 50,
      });
      this.loadedSamples = samplesResp.samples.map((s) => ({ text: s }));
      this.samplesLoadingState = EAsyncState.IDLE;
    } catch (e) {
      this.samplesLoadingState = EAsyncState.FAILURE;
    }
  };

  closeSamplesModal = () => {
    this.isSamplesModalOpened = false;
  };

  analyze = async () => {
    const searchCoreId = this.tableController.filterValues.core?._id;
    const searchText = this.tableController.filterValues.search;
    if (!searchCoreId && !searchText) {
      alert("Не выбрано ядро или поисковой запрос");
    }
    const scanningSize = this.tableController.filterValues.scanningSize;

    try {
      this.analysisStatus = EAsyncState.LOADING;
      await apiController.semantics.analyze({
        searchCoreId,
        searchText,
        scanningSize,
      });
      await this.tableController.loadTable();
      this.analysisStatus = EAsyncState.IDLE;
    } catch (e) {
      this.analysisStatus = EAsyncState.IDLE;
      makeToast("Что-то пошло не так");
    }
  };
}
