import { keyBy } from "lodash";
import { EConditionTerm } from "../_interfaces/nodeTreeEditor/INodeTreeEditor";

export const TERM_ITEMS = [
  { label: "or", value: EConditionTerm.OR },
  { label: "and", value: EConditionTerm.AND },
  { label: "seq", value: EConditionTerm.SEQ },
  { label: "nor", value: EConditionTerm.NOR },
];

export const TERM_ITEMS_KEYED = keyBy(TERM_ITEMS, "value");

export const defaultConditionOperators = [
  EConditionTerm.OR,
  EConditionTerm.AND,
  EConditionTerm.SEQ,
  EConditionTerm.NOR,
];
