import style from "../../pages/searchCore/SearchCoreItem/SearchCoreItem.module.scss";
import { Modal } from "../Modal/Modal";
import {
  EConditionChangeCommand,
  EConditionTerm,
  ENodeType,
} from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import React from "react";
import { Input, TextArea } from "../Input/Input";
import { Button, EButtonType } from "../Button/Button";
import { Toggle } from "../Toggle/Toggle";
import { GridRow } from "../Grid/GridRow";
import { Form } from "../Form/Form";
import { observer } from "mobx-react-lite";
import {
  defaultConditionOperators,
  TERM_ITEMS_KEYED,
} from "../../../_shared/Constants/ConditionConstsnts";
import { TreeEditorController } from "../../controllers/_common/TreeEditorController";

const NODE_TYPES_VARIANTS = [
  { label: "Значение", value: ENodeType.VALUE },
  { label: "Группировка", value: ENodeType.NODE },
];

interface INodeEditModalProps {
  controller: TreeEditorController;
  operators?: EConditionTerm[];
  ValueEditor?: React.FunctionComponent<{
    onValueChange: (newVal) => void;
    value: any;
  }>;
}

export const NodeEditModal: React.FunctionComponent<INodeEditModalProps> = observer(
  ({ controller, operators = defaultConditionOperators, ValueEditor }) => {
    const isModalOpen = !!controller.currentEditingNode;
    const closeEditModal = controller.closeEditModal;
    const selectedNode = controller.getSelectedNode(controller.currentEditingNode?.path);
    const onValueChange = controller.onValueChange;
    const onPositionChange = controller.onPositionChange;
    const onNodeTypeChange = controller.onNodeTypeChange;
    const onDelete = controller.onNodeDelete;

    if (!isModalOpen) return null;

    const nodeType = !selectedNode.term ? ENodeType.VALUE : ENodeType.NODE;

    const handleChangeTerm = (newTerm: string) => {
      controller.execCommand(
        EConditionChangeCommand.CHANGE_TERM,
        controller.currentEditingNode?.path,
        newTerm as EConditionTerm,
      );
    };

    const handleChangeSeqMaxDistance = (newDistance: string) => {
      controller.execCommand(
        EConditionChangeCommand.CHANGE_OPTIONS,
        controller.currentEditingNode?.path,
        { max_distance: parseInt(newDistance) },
      );
    };

    const onHandleTitleChange = (newTitle) => {
      controller.execCommand(
        EConditionChangeCommand.CHANGE_TITLE,
        controller.currentEditingNode?.path,
        newTitle,
      );
    };
    const handleMakeDump = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      controller.execCommand(
        EConditionChangeCommand.MAKE_DUMP,
        controller.currentEditingNode?.path,
        "",
      );
    };

    const handleRestoreDump = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      controller.execCommand(
        EConditionChangeCommand.RESTORE_DUMP,
        controller.currentEditingNode?.path,
        "",
      );
    };
    return (
      <Modal header={"Редактирование ноды"} innerStyle={{ minWidth: 300 }} onClose={closeEditModal}>
        <Form>
          {nodeType === ENodeType.NODE ? (
            <GridRow>
              <div className={style.editNodeInput}>
                <label>Название группы</label>
                <Input
                  value={selectedNode.title || ""}
                  isEnabled={true}
                  onValueChange={onHandleTitleChange}
                />
              </div>
            </GridRow>
          ) : null}
          <GridRow>
            <div className={style.editNodeInput}>
              <label>Тип узла</label>
              <Toggle
                selected={nodeType}
                variants={NODE_TYPES_VARIANTS}
                onSelect={onNodeTypeChange}
              />
            </div>
          </GridRow>
          <GridRow>
            {nodeType === ENodeType.VALUE ? (
              ValueEditor ? (
                <ValueEditor onValueChange={onValueChange} value={selectedNode} />
              ) : (
                <div>
                  <label>Значение</label>
                  <TextArea
                    value={selectedNode as any}
                    autoFocus={true}
                    isEnabled={true}
                    onValueChange={onValueChange}
                  />
                </div>
              )
            ) : (
              <div>
                <Toggle
                  variants={operators.map((o) => TERM_ITEMS_KEYED[o])}
                  onSelect={handleChangeTerm}
                  selected={selectedNode.term}
                />
                <br />
                {selectedNode.term === EConditionTerm.SEQ ? (
                  <div className={style.editNodeInput}>
                    <label>Дистанция</label>
                    <Input
                      value={selectedNode.options?.max_distance || 75}
                      autoFocus={true}
                      isEnabled={true}
                      onValueChange={handleChangeSeqMaxDistance}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </GridRow>
          <GridRow>
            <Button
              onClick={(e) => {
                onDelete();
                closeEditModal();
              }}
              styling={EButtonType.REJECT}
              text={"Удалить"}
            />
            <div className={style.postionInputChangerWrapper}>
              <Button
                style={{ transform: "rotate(270deg)" }}
                onClick={() => onPositionChange(-1, "nested")}
                text={"⇨"}
              />
              <Button
                style={{ transform: "rotate(90deg)" }}
                onClick={() => onPositionChange(1, "nested")}
                text={"⇨"}
              />
            </div>
            <div className={style.postionInputChangerWrapper}>
              <Button text={"Copy"} onClick={handleMakeDump} />
              <Button text={"Paste"} onClick={handleRestoreDump} />
            </div>
          </GridRow>
        </Form>
      </Modal>
    );
  },
);
