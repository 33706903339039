import _ from "lodash";
import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { BotEditorController } from "../../controllers/BotScript/BotEditorController";

import { distance, relativePoint, translatePointToCanvas } from "./canvasUtils";
import { IBotElement } from "../../../_shared/Database/botElement";
import { IConnection } from "../../../_shared/_interfaces/botEditor/botEditor";
import { EBotElementSlotType } from "../../../_shared/_enums/EBotElementEnums";

function getCenterRectPoint(rect) {
  const { x, y, width, height } = rect;

  const hWidth = width / 2;
  const hHeight = height / 2;

  return { x: x + hWidth, y: y + hHeight };
}

function getDistance(p1, p2) {
  const x1 = p1.x;
  const y1 = p1.y;
  const x2 = p2.x;
  const y2 = p2.y;
  var a = x1 - x2;
  var b = y1 - y2;
  return Math.sqrt(a * a + b * b);
}

function getMiddlePoints(rect) {
  const { x, y, width, height } = rect;

  const hWidth = width / 2;
  const hHeight = height / 2;

  return [
    //top
    { x: x + hWidth, y, direction: "top" },
    //right
    { x: x + width, y: y + hHeight, direction: "right" },
    //bottom
    { x: x + hWidth, y: y + height, direction: "bottom" },
    //left
    { x, y: y + hHeight, direction: "left" },
  ];
}

const calculateDirections = (r1, r2) => {
  let r1MiddlePoints = getMiddlePoints(r1);
  let r2MiddlePoints = getMiddlePoints(r2);

  return _.head(
    _.sortBy(
      _.reduce(
        r1MiddlePoints,
        (result, r1Point) => {
          let currentDistances = _.map(r2MiddlePoints, (r2Point) => {
            let r1Pointr2PointDistance = getDistance(r1Point, r2Point);
            return {
              d1: r1Point.direction,
              d2: r2Point.direction,
              d: r1Pointr2PointDistance,
            };
          });
          return _.concat(result, currentDistances);
        },
        [],
      ),
      "d",
    ),
  );
};

const renderTriagle = (point, direction, color) => {
  const triangleWidth = 7;
  const triangleHeight = 15;

  const hWidth = triangleWidth / 2;

  let rotation = 0;

  switch (direction) {
    case "left":
      rotation = 0;
      break;
    case "top":
      rotation = 90;
      break;
    case "right":
      rotation = 180;
      break;
    case "bottom":
      rotation = 270;
  }

  return (
    <polygon
      points={`${point.x} ${point.y},${point.x - triangleHeight} ${point.y - hWidth},${
        point.x - triangleHeight
      } ${point.y + hWidth}`}
      style={{
        transformOrigin: `${point.x}px ${point.y}px`,
      }}
      transform={`rotate(${rotation})`}
      fill="#cecece"
      stroke={color}
      strokeWidth="2px"
    />
  );
};

const renderCurveConnection = (p1, p2, color, onConnectionClick) => {
  const dist = distance(p1, p2);
  const curvinessBias = dist * 0.3;

  const handleConnectionClick = (e) => {
    e.stopPropagation();
    onConnectionClick();
  };

  return (
    <g>
      <path
        onMouseDown={handleConnectionClick}
        d={`M${p1.x} ${p1.y} C ${p1.x + curvinessBias} ${p1.y}, ${p2.x - curvinessBias} ${p2.y}, ${
          p2.x
        } ${p2.y}`}
        stroke={color}
        strokeWidth="2px"
        fill="transparent"
      />
      <circle cx={p1.x} cy={p1.y} r="4" stroke={color} strokeWidth="2px" fill="white" />
      <circle cx={p2.x} cy={p2.y} r="4" stroke={color} strokeWidth="2px" fill="white" />
    </g>
  );
};

const renderBezier = (r1, r2, color, matrix, offset, onConnectionClick?) => {
  let p1 = getCenterRectPoint(r1);
  p1 = relativePoint(p1, offset);
  p1 = translatePointToCanvas(p1, matrix);
  if (!r1 || !r2) {
    console.error(
      "Error: trying to render connection but there is no points inc the connection",
      r1,
      r2,
    );
    return;
  }
  let p2 = r2.width ? getCenterRectPoint(r2) : r2;
  p2 = relativePoint(p2, offset);
  p2 = translatePointToCanvas(p2, matrix);

  return renderCurveConnection(p1, p2, color, onConnectionClick);
};

interface IConnectionProps {
  controller: BotEditorController;
  connection: IConnection;
  fromElement: IBotElement;
  isSelected: boolean;
  onConnectionClick: () => void;
}

export const Connection: React.FC<IConnectionProps> = observer(
  ({ controller, fromElement, connection, onConnectionClick, isSelected }) => {
    const toElementId = connection.to_unique_id;
    const toElement = controller.elements[toElementId];

    const [{ fromElementRect, toElementRect }, setRectStates] = useState({
      fromElementRect: null,
      toElementRect: null,
    });

    useEffect(() => {
      const fromSlot = connection.from_slot_id;
      const toSlot = connection.to_slot_id;

      const fromSlotElementId =
        fromElement.unique_id + "_" + EBotElementSlotType.OUTPUT + "_" + fromSlot;

      const fromHtmlElement = document.getElementById(fromSlotElementId);

      let toHtmlElement = null;
      if (toElement) {
        let toSlotElementId = toElement.unique_id + "_" + EBotElementSlotType.INPUT + "_" + toSlot;
        toHtmlElement = document.getElementById(toSlotElementId);
      }

      if (fromHtmlElement) {
        const fromElementRect = fromHtmlElement.getBoundingClientRect();
        let toElementRect = null;
        if (toHtmlElement) {
          toElementRect = toHtmlElement.getBoundingClientRect();
        }
        setRectStates({
          fromElementRect,
          toElementRect,
        });
        return;
      }
      setRectStates({
        fromElementRect: null,
        toElementRect: null,
      });
    }, [fromElement, toElement, controller.matrix]);

    let color = isSelected ? "yellow" : "#0059ff";
    if (fromElementRect && !toElementRect) {
      return renderBezier(
        fromElementRect,
        controller.mouse,
        color,
        controller.matrix,
        controller.canvasScreenBox,
      );
    }
    if (!fromElementRect || !toElementRect) return null;

    return renderBezier(
      fromElementRect,
      toElementRect,
      color,
      controller.matrix,
      controller.canvasScreenBox,
      onConnectionClick,
    );
  },
);
