import * as React from "react";
import {
  ChatContainer,
  InputToolbox,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { IFeedChatMessage } from "../../../_shared/Database/feedChatMessage";
import { get, map, reduce } from "lodash";
import { root } from "../../controllers/RootController";
import { observer } from "mobx-react-lite";
import { Loader } from "../Loader/Loader";
import { DateTime } from "luxon";
import styles from "./ChatMessenger.module.scss";
import { replaceAll } from "../../../_shared/Util/text";
import { EChatMode } from "../../../_shared/_enums/EChatMode";
import { IMessagingPrivateChatMessage } from "../../../_shared/Database/messagingPrivateChatMessage";
import { Link } from "react-router-dom";
import { Button, EButtonType } from "../Button/Button";
import { Input } from "../Input/Input";
import { getBotElementTitle } from "../../../_shared/Util/botElementUtils";
import { EBotElementType } from "../../../_shared/_enums/EBotElementEnums";
import { prompter } from "../../controllers/_common/PromptController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";

const getMessageFooter = (date: number, from: string, onMessageDelete?, isDeleted?) => {
  const dt = DateTime.fromMillis(date * 1000);
  const deletedLabel = isDeleted ? "[❌ УДАЛЕНО]" : "";
  const handleOnClick = async () => {
    if (isDeleted) {
      await prompter.alert("Сообщение уже удалено");
      return;
    }
    onMessageDelete();
  };
  return (
    <Message.Footer
      sender={from || "удалить"}
      onClick={handleOnClick}
      sentTime={deletedLabel + " " + dt.toRelative()}
    />
  );
};

const getTgBotMessage = (msg: IFeedChatMessage, from: string) => {
  const messageText = msg.message_data?.text || "";
  const fileId = msg.message_data?.file_id;
  const fileMime = msg.message_data?.mime_type;
  const date = msg.message_data?.date;
  const direction = msg?.from_bot ? 1 : 0;
  const position = "single";
  if (fileId && fileMime) {
    const fileUrl = `https://${process.env.REACT_APP_API_HOSTNAME}/v1/chats/file/${replaceAll(
      fileMime,
      "/",
      "_",
    )}/${fileId}`;
    if (fileMime.includes("audio")) {
      return (
        <Message
          key={msg._id}
          model={{
            direction,
            type: "custom",
            position,
          }}
        >
          <Message.CustomContent>
            <audio controls>
              <source src={fileUrl} />
            </audio>
          </Message.CustomContent>
          {getMessageFooter(date, from)}
        </Message>
      );
    }
    return (
      <Message
        type={"image"}
        key={msg.source_message_id}
        model={{
          direction,
          position: "single",
          payload: {
            src: fileUrl,
            alt: "User pic",
            width: "150px",
          },
        }}
      >
        {getMessageFooter(date, from)}
      </Message>
    );
  }
  return (
    <Message
      key={msg.source_message_id}
      model={{
        message: messageText,
        sentTime: "just now",
        sender: "Joe",
        direction,
        position: "single",
      }}
    >
      {getMessageFooter(date, from)}
    </Message>
  );
};

export const FileSpoiler: React.FC<{
  type: string;
  isFileOpened: boolean;
  onFileLoad: Function;
  children: React.ReactNode;
}> = ({ type, children, isFileOpened, onFileLoad }) => {
  return (
    <div className={styles.FileSpoiler}>
      {isFileOpened ? (
        children
      ) : (
        <Button
          text={`[${type}] Загрузить файл`}
          styling={EButtonType.DEFAULT}
          onClick={() => {
            onFileLoad();
          }}
        />
      )}
    </div>
  );
};

const getGramMessage = (
  msg: IMessagingPrivateChatMessage,
  from,
  nodeId,
  onMessageDelete,
  isFileOpened = false,
  onMessageFileOpen,
) => {
  const messageText = msg.tg_message.message;
  const media: any = msg.tg_message.media || null;
  const date = msg.tg_message?.date;
  const direction = msg?.tg_message.out ? 1 : 0;
  const position = "single";
  const fileUrl = `https://${process.env.REACT_APP_API_HOSTNAME}/v1/messaging/file/${
    msg.messaging_private_chat_id
  }/${msg._id + ""}`;

  if (media && media.document && media.document?.mimeType.includes("audio")) {
    return (
      <Message
        key={msg._id}
        model={{
          direction,
          type: "custom",
          position,
        }}
      >
        <Message.CustomContent>
          <FileSpoiler
            type={"⏵"}
            isFileOpened={isFileOpened}
            onFileLoad={() => onMessageFileOpen(msg._id + "")}
          >
            <audio controls>
              <source src={fileUrl} />
            </audio>
          </FileSpoiler>
        </Message.CustomContent>
        {getMessageFooter(date, from, onMessageDelete, msg.is_deleted)}
      </Message>
    );
  }
  if (media && media.document && media.document?.mimeType.includes("video")) {
    return (
      <Message
        key={msg.tg_message.id}
        model={{
          type: "html",
          direction,
          position: "single",
        }}
      >
        <Message.CustomContent>
          <FileSpoiler
            type={"⏵"}
            isFileOpened={isFileOpened}
            onFileLoad={() => onMessageFileOpen(msg._id + "")}
          >
            <video width="400px" controls src={fileUrl} />
          </FileSpoiler>
        </Message.CustomContent>
        {getMessageFooter(date, from, onMessageDelete, msg.is_deleted)}
      </Message>
    );
  }

  if (media && (media.photo || (media.document && media.document?.mimeType.includes("image")))) {
    return (
      <Message
        key={msg.tg_message.id}
        model={{
          type: "html",
          direction,
          position: "single",
        }}
      >
        <Message.CustomContent>
          <FileSpoiler
            type={"🖼"}
            isFileOpened={isFileOpened}
            onFileLoad={() => onMessageFileOpen(msg._id + "")}
          >
            <img width="400px" src={fileUrl} />
          </FileSpoiler>
        </Message.CustomContent>
        {getMessageFooter(date, from, onMessageDelete, msg.is_deleted)}
      </Message>
    );
  }
  return (
    <Message
      key={msg.tg_message.id}
      model={{
        message: messageText,
        sentTime: "just now",
        sender: "Joe",
        direction,
        position: "single",
      }}
    >
      {getMessageFooter(date, from, onMessageDelete, msg.is_deleted)}
    </Message>
  );
};

const renderMessages = (
  mode: EChatMode,
  messages: any[],
  from,
  nodeId,
  onMessageDelete,
  openedFiles,
  onMessageFileOpen,
) => {
  return reduce(
    messages,
    (acc, msg) => {
      let message = null;
      if (mode === EChatMode.BOTCHAT) {
        message = getTgBotMessage(msg as IFeedChatMessage, from);
      } else {
        const isOpened = !!openedFiles[msg._id];
        message = getGramMessage(
          msg as IMessagingPrivateChatMessage,
          from,
          nodeId,
          onMessageDelete(msg.tg_message.id),
          isOpened,
          onMessageFileOpen,
        );
      }
      acc.push(message);
      return acc;
    },
    [],
  );
};

const getChatTitle = (clientName, chat) => {
  const chatTitle = chat.title || chat.node_phone ? "Чат с " + chat.node_phone : "Чат с ботом";
  const blockedTitle = chat.is_chat_blocked ? "(блокировка)" : "";
  return `${clientName} - ${chatTitle} ${blockedTitle}`;
};

export const ChatMessenger: React.FC<any> = observer(() => {
  const {
    client,
    chat,
    messages,
    mode,
    onMessageDelete,
    openedFiles,
    onMessageFileOpen,
    getFullLog,
    toggleFullLog,
    genInputText,
    inputMessage,
    changeInputMessage,
    onSend,
    sendStatus,
  } = root.feedChatController;
  if (!client || !chat) return <Loader />;
  const clientName = client.first_name
    ? client.first_name + " " + (client.last_name || "")
    : client.name;

  if (sendStatus === EAsyncState.LOADING) {
    return <Loader />;
  }
  return (
    <MainContainer className={styles.mainMessengerWrapper}>
      <Link className={styles.messengerTitle} to={`/clients/${client._id}`}>
        <h3>
          <span className={styles.arrowLeft}>〈</span>
          {getChatTitle(clientName, chat)}
        </h3>
      </Link>
      <ChatContainer>
        {messages.length > 0 ? (
          <MessageList>
            {renderMessages(
              mode,
              messages.slice().reverse(),
              clientName,
              chat._id,
              onMessageDelete,
              openedFiles,
              onMessageFileOpen,
            )}
          </MessageList>
        ) : null}

        <MessageInput
          placeholder="Type message here"
          value={inputMessage}
          onChange={changeInputMessage}
          onSend={onSend}
          // autoFocus={true}
          attachButton={false}
          disabled={false}
          sendDisabled={false}
          inputMode={"text"}
          className={styles.messageInputWrapper}
        />
        <InputToolbox>
          <div className={styles.chatActionBar}>
            <div className={styles.elementItems}>
              {map(
                root.botElementTableController.items.filter((item) => {
                  return (
                    item.type === EBotElementType.PHASE &&
                    item.bot_script_id === client.bot_control_script_id
                  );
                }),
                (element) => {
                  return (
                    <Button
                      key={element._id}
                      text={get(element, "element_data.title", "")}
                      onClick={() => genInputText(element._id)}
                    />
                  );
                },
              )}
            </div>
            <label className={styles.fullLogToggler}>
              <Input
                type={"checkbox"}
                value={getFullLog as unknown as string}
                isEnabled={true}
                onValueChange={() => toggleFullLog()}
              />
              Отображать все сообщения
            </label>
          </div>
        </InputToolbox>
      </ChatContainer>
    </MainContainer>
  );
});
