import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { CommonEntityController } from "../_common/CommonEntityController";
import { ConditionalController } from "../_common/ConditionalController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ISettingsLink } from "../../../_shared/Database/settingsLink";

const newItem: ISettingsLink = {
  _id: NEW_ITEM_ID,
  title: "",
  location_ids: [],
  search_core_ids: [],
  creation_date: Date.now(),
};

export class SettingsLinkItemController {
  root: RootController;
  conditionalController: ConditionalController;
  entityController: CommonEntityController<ISettingsLink>;
  searchCores: any = {};
  locations: any = {};

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ISettingsLink>(
      EEntityType.SETTINGS_LINK,
      newItem,
    );
    makeAutoObservable(this);
  }

  initItem = async (targetListId: string) => {
    await this.entityController.initItem(targetListId);
  };
}
