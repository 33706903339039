import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";

const tableOptions: ITableOptions = {
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    { title: "Название", field: "title" },
  ],
};

export const CategoryTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.categoryTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper
      header={"Категории"}
      state={EAsyncState.IDLE}
      onNew={() => navigate("/categories/new")}
    >
      <ControlledTable
        controller={root.categoryTableController}
        options={tableOptions}
        getItemHref={(item) => `/categories/${item._id}`}
      />
    </PageWrapper>
  );
});
