import { RootController } from "../RootController";
import { makeAutoObservable, toJS } from "mobx";
import { each, filter, find, get, identity, isNil, map, range, some } from "lodash";
import {
  distance,
  isPointInsideAABB,
  relativePoint,
  translatePointToCanvas,
} from "../../components/Editor/canvasUtils";
import { apiController } from "../ApiController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import {
  EDragMode,
  IBox,
  IConnection,
  ICoords,
} from "../../../_shared/_interfaces/botEditor/botEditor";
import { IBotElement } from "../../../_shared/Database/botElement";
import { getInitialElementDataFromType } from "../../../_shared/Util/botElementUtils";
import {
  EBotElementSlotType,
  EBotElementType,
  EPasteMode,
} from "../../../_shared/_enums/EBotElementEnums";
import { BOT_ELEMENT_SLOTS } from "../../../_shared/Constants/BotElementConstants";
import { makeToast } from "../../utils/toast";
import { prompter } from "../_common/PromptController";
import { getUniqId } from "../_common/utils";

interface IDrag {
  mode: EDragMode;
  dragFromElementId: string | null;
  dragFromSlotId: number | null;
  dragConnectionIndex: number | null;
}

const getElementVisiblePosition = async (preferredPosition: ICoords, botScriptId: string) => {
  const resp = await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.COUNT]({
    bot_script_id: botScriptId,
    x: preferredPosition.x,
    y: preferredPosition.y,
  });
  if (resp.count === 0) return preferredPosition;
  const newItemOffset = 100;
  return await getElementVisiblePosition(
    {
      x: preferredPosition.x + newItemOffset,
      y: preferredPosition.y + newItemOffset,
    },
    botScriptId,
  );
};

const recursiveGetVarsFromElementCondition = (cond: any) => {
  let vars = [];
  each(cond.content, (contentItem) => {
    if (!!contentItem.term) {
      vars = [...vars, ...recursiveGetVarsFromElementCondition(contentItem)];
      return;
    }
    if (contentItem.k === "variable") {
      if (contentItem.val.var_unique_id) {
        return vars.push(contentItem.val.var_unique_id);
      }
    }
  });
  return vars;
};

export class BotEditorController {
  root: RootController;
  isInited: boolean = false;
  matrix: number[] = [1, 0, 0, 1, 0, 0];
  elementCoordsOffset: ICoords | null = null;
  initClickCoords: ICoords | null = null;
  mouse: ICoords | null = null;
  canvasScreenBox: IBox | null = null;
  selectionBox: { a: ICoords; b: ICoords } | null = null;
  selectedElements: string[] = [];
  selectedMasterElement: string | null = null;
  selectedConnectionIndex: number | null = null;
  drag: IDrag = {
    dragFromElementId: null,
    dragFromSlotId: null,
    dragConnectionIndex: null,
    mode: EDragMode.NODRAG,
  };

  elements: { [uniqId: string]: IBotElement } = {};

  constructor(root: RootController) {
    this.root = root;
    makeAutoObservable(this);
  }

  init = (box: IBox) => {
    this.canvasScreenBox = box;
    this.isInited = true;
    this.reload().then();
  };

  reload = async () => {
    this.elements = {};
    const resp = await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.LIST](
      {
        bot_script_id: this.root.botScriptItemController.entityController.item._id,
      },
      0,
      100000,
    );
    this.elements = {};
    each(resp.items, (item) => {
      this.elements[item.unique_id] = item;
    });
  };

  viewportChange = (newMatrix) => {
    this.matrix = newMatrix;
  };

  getVariableIdsFromElement = (elementUniqId) => {
    const element: IBotElement = this.elements[elementUniqId];
    const varIds = {};
    if (element.type === EBotElementType.VARIABLE_CHANGE && element.element_data?.var_unique_id) {
      varIds[element.element_data.var_unique_id] = element.element_data.var_unique_id;
      return varIds;
    }
    if (element.type !== EBotElementType.CONDITION_CHECK) return;
    const condition = element.element_data.condition;
    const varsFromConditions = recursiveGetVarsFromElementCondition(condition);
    each(varsFromConditions, (vId) => {
      varIds[vId] = vId;
    });
    return varIds;
  };

  handleCopy = async () => {
    try {
      const selected = this.getSelected();
      if (!selected.length) return;
      let varIds = {};
      const els = map(selected, (elementUniqId) => {
        const element: any = toJS(this.elements[elementUniqId]);
        const variableIdsFromElement = this.getVariableIdsFromElement(elementUniqId);
        varIds = { ...varIds, ...variableIdsFromElement };
        delete element.bot_script_id;
        delete element.__v;
        delete element._id;
        return element;
      });

      const vars = map(varIds, (varId) => {
        const variable = this.root.botScriptItemController.variablesTableController.findByQuery({
          unique_id: varId,
        });
        if (!variable) return null;
        delete variable.bot_script_id;
        delete variable.__v;
        delete variable._id;
        return toJS(variable);
      }).filter(identity);

      const data = {
        app: "leadder_bot_editor",
        type: "paste_event",
        v: 1,
        elements: els,
        vars: vars,
      };
      console.info("Copied data", data);
      await navigator.clipboard.writeText(JSON.stringify(data));
    } catch (e) {
      console.error("Error: ", e);
      console.error("Error while copying the elements");
    }
  };

  processPasteConnections = (
    pasteMode: EPasteMode,
    pastingBufferElements: (IBotElement & { old_uniq_id: string })[],
    pastingEl: IBotElement,
  ) => {
    switch (pasteMode) {
      case EPasteMode.DESTROY:
        return [];
      case EPasteMode.OVERRIDE:
        return (pastingEl.connections || []).filter((conn: IConnection) => {
          return !!this.getElementByUniqId(conn.to_unique_id);
        });
      case EPasteMode.DUPLICATE:
        return (pastingEl.connections || [])
          .map((conn: IConnection) => {
            const connectedItem = find(pastingBufferElements, {
              old_uniq_id: conn.to_unique_id,
            });
            if (!connectedItem) return null;
            return {
              ...conn,
              to_unique_id: connectedItem.unique_id,
            };
          })
          .filter(identity);
    }
  };

  pasteElements = async (pastingElements) => {
    const botScriptId = this.root.botScriptItemController.entityController.item._id;

    let pasteModeElements: EPasteMode | null = EPasteMode.DUPLICATE;
    let pasteModeConnections: EPasteMode | null = EPasteMode.DUPLICATE;

    /* Check for asinkg permissions */
    let isUniq = true;
    let hasConnections = false;
    for (const pasteEl of pastingElements) {
      // if pasted element already exists
      const existingUniqElement = this.getElementByUniqId(pasteEl.unique_id);
      if (!!existingUniqElement) {
        isUniq = false;
      }
      if (pasteEl.connections?.length) {
        hasConnections = true;
      }
      if (!isUniq && hasConnections) {
        break;
      }
    }

    if (!isUniq) {
      pasteModeElements = await prompter.variants(
        "Найден элемент с таким же уникальным ID. \nКакое действие вы хотите совершить?",
        [
          { label: "Замена", value: EPasteMode.OVERRIDE },
          { label: "Дублирование", value: EPasteMode.DUPLICATE },
        ],
      );
      if (!pasteModeElements) return;
    }

    if (hasConnections && pasteModeElements === EPasteMode.DUPLICATE) {
      pasteModeConnections = await prompter.variants(
        "Найдены связи у дублируемых элементов. Как поступим?",
        [
          { label: "Оставить к существующим", value: EPasteMode.OVERRIDE },
          { label: "Дублировать к копируемым", value: EPasteMode.DUPLICATE },
          { label: "Уничтожить", value: EPasteMode.DESTROY },
        ],
      );
      if (!pasteModeConnections) return;
    }

    /* End check for permissions */
    if (pasteModeElements === EPasteMode.DUPLICATE) {
      // We are duplicating. Thats because we override uniq_id to the new ones.
      const newUniqIds = await Promise.all(
        pastingElements.map((el) => getUniqId(EEntityType.BOT_ELEMENT, el.unique_id)),
      );
      pastingElements = pastingElements.map((el, index) => {
        el.old_uniq_id = el.unique_id;
        el.unique_id = newUniqIds[index];
        return el;
      });
    }

    for (const pasteEl of pastingElements) {
      // if pasted element already exists
      const existingUniqElement = this.getElementByUniqId(pasteEl.unique_id);
      if (pasteModeElements === EPasteMode.OVERRIDE && existingUniqElement) {
        await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.UPDATE]({
          _id: existingUniqElement._id,
          ...pasteEl,
        });
      } else {
        const preferredPos = await getElementVisiblePosition(pasteEl, botScriptId);
        await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.UPDATE]({
          bot_script_id: botScriptId,
          ...pasteEl,
          ...preferredPos,
          connections: this.processPasteConnections(pasteModeConnections, pastingElements, pasteEl),
        });
      }
    }
  };

  pasteVars = async (pastingVars: any[]) => {
    if (pastingVars.length === 0) return;
    const botScriptId = this.root.botScriptItemController.entityController.item._id;
    for (const variable of pastingVars) {
      const existingVar = this.root.botScriptItemController.variablesTableController.findByQuery({
        unique_id: variable.unique_id,
      });
      if (existingVar) {
        continue;
      }
      await apiController[EEntityType.BOT_VARIABLE][ECrudMethods.UPDATE]({
        ...variable,
        bot_script_id: botScriptId,
      });
    }
    await this.root.botScriptItemController.variablesTableController.loadTable();
  };

  handlePaste = async () => {
    try {
      const clpText = await navigator.clipboard.readText();
      const pasteObject = JSON.parse(clpText);
      const { app, type, v, elements, vars } = pasteObject;
      if (app !== "leadder_bot_editor" || type !== "paste_event" || v !== 1) return;
      let pastingElements = elements;
      let pastingVars = vars;
      await this.pasteElements(pastingElements);
      await this.pasteVars(pastingVars);
    } catch (e) {
      console.error("Error: ", e);
      makeToast("Error while pasting");
    } finally {
      this.reload().then();
    }
  };

  handleKeyUp = async (e) => {
    if (this.getSelected().length === 0) return;
    const selectedElementUniqId = this.getSelected()[0];
    if (e.key === "Delete" || e.key === "Backspace") {
      if (!isNil(this.selectedConnectionIndex)) {
        this.deleteConnection(selectedElementUniqId, this.selectedConnectionIndex);
        this.saveElement(selectedElementUniqId).then();
      }
      if (this.getSelected().length) {
        if (!(await prompter.confirm(`Удалить элементы? (${this.getSelected().length} шт)`)))
          return;
        for (const selectedUniqueElementUniqId of this.getSelected()) {
          await this.deleteElement(selectedUniqueElementUniqId);
        }
        await this.reload();
      }
    }
  };

  mouseUp = async (e) => {
    if (e.which == 2) {
      e.preventDefault();
    }
    this.initClickCoords = null;
    switch (this.drag.mode) {
      case EDragMode.SELECTION_DRAG: {
        this.selectionBox = null;
        break;
      }
      case EDragMode.ELEMENT_DRAG: {
        each(this.getSelected(), (elementUniqId) => {
          this.saveElement(elementUniqId).then();
        });
        this.selectedMasterElement = null;
        this.elementCoordsOffset = null;
        break;
      }
      case EDragMode.CONNECTION_DRAG: {
        if (!isNil(this.drag.dragConnectionIndex)) {
          const connection = this.getConnectionByIndex(
            this.drag.dragFromElementId,
            this.drag.dragConnectionIndex,
          );
          if (connection && !isNil(connection.to_slot_id) && !isNil(connection.to_unique_id)) {
            this.saveElement(this.drag.dragFromElementId).then();
          } else {
            this.deleteConnection(this.drag.dragFromElementId, this.drag.dragConnectionIndex);
          }
        }
        break;
      }
      case EDragMode.NODRAG:
        if (this.selectedMasterElement) {
          this.selectedElements = [this.selectedMasterElement];
        }
        break;
    }

    this.drag.mode = EDragMode.NODRAG;
  };

  clearSelection = () => {
    this.selectedConnectionIndex = null;
    this.selectedElements = [];
    this.selectedMasterElement = null;
  };

  isConnectionSelected = (elementUniqId, connectionIndex) => {
    if (this.getSelected().length === 0) return false;
    const selectedElementId = this.getSelected()[0];
    return selectedElementId === elementUniqId && this.selectedConnectionIndex === connectionIndex;
  };

  selectConnectionIndex = (elementUniqId, connectionIndex) => {
    this.selectedElements = [elementUniqId];
    this.selectedConnectionIndex = connectionIndex;
  };

  elementMouseDown = (elementUniqId: string, coords: ICoords, offset: ICoords) => {
    this.elementCoordsOffset = offset;
    this.initClickCoords = coords;
    this.selectedMasterElement = elementUniqId;
    if (!this.selectedElements.includes(elementUniqId)) {
      this.selectedElements = [elementUniqId];
    }
  };

  startSelection = (e) => {
    this.clearSelection();
    const calculatedOffsets = this.screenPointToCanvas({
      x: e.clientX,
      y: e.clientY,
    });
    this.selectionBox = {
      a: calculatedOffsets,
      b: calculatedOffsets,
    };
    this.drag.mode = EDragMode.SELECTION_DRAG;
  };

  screenPointToCanvas = (point: ICoords, offset = { x: 0, y: 0 }): ICoords => {
    let calculatedOffsets = relativePoint(point, offset);
    calculatedOffsets = relativePoint(calculatedOffsets, this.canvasScreenBox);
    return translatePointToCanvas(calculatedOffsets, this.matrix);
  };

  changeElement = (element) => {
    // For the reference changing; Feels hacky, find better approach later. Maybe use callbacks to track the changes?
    this.elements[element.unique_id] = toJS(element);
  };

  getSelected = () => {
    if (!this.selectedMasterElement || this.selectedElements.includes(this.selectedMasterElement)) {
      return this.selectedElements;
    }
    return [this.selectedMasterElement, ...this.selectedElements];
  };

  selectionDrag = (mouseCoords: ICoords) => {
    const translatedPoint = this.screenPointToCanvas(mouseCoords);
    this.selectionBox = {
      ...this.selectionBox,
      b: translatedPoint,
    };

    // traverse thru all elements and add to selection list
    const newList = [];
    each(this.elements, (element) => {
      const isTopPointInside = isPointInsideAABB(element, this.selectionBox);
      const isBottomPointInside = isPointInsideAABB(
        { x: element.x + 185, y: element.y + 25 },
        this.selectionBox,
      );
      if (isTopPointInside || isBottomPointInside) {
        newList.push(element.unique_id);
      }
    });
    this.selectedElements = newList;
  };

  mouseMove = (mouseCoords: ICoords) => {
    this.mouse = mouseCoords;
    switch (this.drag.mode) {
      case EDragMode.SELECTION_DRAG: {
        this.selectionDrag(mouseCoords);
        return;
      }
      case EDragMode.CONNECTION_DRAG: {
        return;
      }
      case EDragMode.ELEMENT_DRAG: {
        this.mouse = mouseCoords;
        if (isNil(this.selectedMasterElement)) return;
        if (!this.selectedElements.includes(this.selectedMasterElement)) {
          this.selectedElements.push(this.selectedMasterElement);
        }

        const masterElement = this.getElementByUniqId(this.selectedMasterElement);
        const translatedPoint = this.screenPointToCanvas(this.mouse, this.elementCoordsOffset);
        const { x, y } = masterElement;
        masterElement.x = translatedPoint.x - (translatedPoint.x % 25);
        masterElement.y = translatedPoint.y - (translatedPoint.y % 25);
        const [deltaX, deltaY] = [masterElement.x - x, masterElement.y - y];
        if (deltaX === 0 && deltaY === 0) return;
        this.changeElement(masterElement);

        each(this.getSelected(), (elementUniqId) => {
          if (elementUniqId === masterElement.unique_id) return;
          const slaveElement = this.getElementByUniqId(elementUniqId);
          slaveElement.x = slaveElement.x + deltaX;
          slaveElement.y = slaveElement.y + deltaY;
          this.changeElement(slaveElement);
        });
        return;
      }
      case EDragMode.NODRAG: {
        const isSelected = this.getSelected().length > 0;
        if (this.initClickCoords && distance(this.initClickCoords, this.mouse) > 10 && isSelected) {
          this.drag.mode = EDragMode.ELEMENT_DRAG;
        }
        return;
      }
    }
  };

  canSlotBeConnectionTarget = (targetElementId: string, targetSlotId: number) => {
    const fromType = this.getDragSlotType();
    if (!fromType) return false;
    const targetElement = this.getElementByUniqId(targetElementId);
    if (!targetElement) return false;
    const targetElementType = targetElement.type;
    const targetSlotValidation = get(
      BOT_ELEMENT_SLOTS,
      [targetElementType, EBotElementSlotType.INPUT, targetSlotId, "validation"],
      null,
    );
    if (!targetSlotValidation) return true;
    return targetSlotValidation.includes(fromType);
  };

  getDragSlotType = (): EBotElementType => {
    if (this.drag.mode !== EDragMode.CONNECTION_DRAG) return null;
    if (isNil(this.drag.dragFromSlotId)) return null;
    const dragFromElement = this.getElementByUniqId(this.drag.dragFromElementId);
    return dragFromElement.type;
  };

  getElementByUniqId = (elementUniqId): IBotElement => {
    return this.elements[elementUniqId];
  };

  getElements = () => {
    return this.elements;
  };

  saveElement = async (elementUniqId) => {
    const element = this.getElementByUniqId(elementUniqId);
    if (!element) return;
    await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.UPDATE]({
      _id: element._id,
      x: element.x,
      y: element.y,
      connections: element.connections ? element.connections : undefined,
    });
  };

  deleteConnection = (elementUniqId: string, connectionId: number) => {
    const element = this.getElementByUniqId(elementUniqId);
    if (!element) return;
    element.connections = filter(element.connections, (conn, index) => index !== connectionId);
    this.clearSelection();
    this.changeElement(element);
  };

  deleteElementDependencies = async (removingElementId) => {
    const promises = [];
    each(this.elements, (elem) => {
      const connections = elem.connections;
      each(connections, (conn, index) => {
        if (conn.to_unique_id === removingElementId) {
          this.deleteConnection(elem.unique_id, index);
          promises.push(this.saveElement(elem.unique_id));
        }
      });
    });
    return Promise.all(promises);
  };

  deleteElement = async (elementUniqId: string) => {
    const element = this.getElementByUniqId(elementUniqId);
    if (!element) return;
    await this.deleteElementDependencies(elementUniqId);
    await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.DELETE]({
      _id: element._id + "",
    });
    delete this.elements[elementUniqId];
  };

  addBotElement = async (elementType: EBotElementType) => {
    const x = this.canvasScreenBox.width / 2;
    const y = this.canvasScreenBox.height / 2;
    const cameraCenterToCanvas = this.screenPointToCanvas({ x, y });
    const botScriptId = this.root.botScriptItemController.entityController.item._id;
    const preferredPos = await getElementVisiblePosition(cameraCenterToCanvas, botScriptId);
    await apiController[EEntityType.BOT_ELEMENT][ECrudMethods.UPDATE]({
      type: elementType,
      unique_id: await getUniqId(EEntityType.BOT_ELEMENT),
      bot_script_id: botScriptId,
      element_data: getInitialElementDataFromType(elementType),
      ...preferredPos,
    });
    this.reload().then();
  };

  getConnectionByIndex = (elementUniqId, connectionIndex) => {
    const element = this.getElementByUniqId(elementUniqId);
    return element.connections[connectionIndex];
  };

  startConnect = (elementUniqId, slotId) => {
    this.drag.mode = EDragMode.CONNECTION_DRAG;
    this.drag.dragFromElementId = elementUniqId;
    this.selectedElements = [elementUniqId];
    this.drag.dragFromSlotId = slotId;

    const element = this.getElementByUniqId(elementUniqId);
    if (!element.connections) {
      element.connections = [];
    }
    element.connections.push({
      from_slot_id: slotId,
    });
    this.drag.dragConnectionIndex = element.connections.length - 1;
    this.selectedConnectionIndex = this.drag.dragConnectionIndex;
    this.changeElement(element);
  };

  selectConnectionTarget = (targetElementId, targetSlotId) => {
    if (this.drag.mode !== EDragMode.CONNECTION_DRAG) return;
    const sourceElement = this.getElementByUniqId(this.drag.dragFromElementId);

    // Check if this connection already exists
    const allConnections = sourceElement.connections;
    const connectionExists = some(allConnections, (c: IConnection) => {
      return (
        c.to_unique_id === targetElementId &&
        c.to_slot_id === targetSlotId &&
        c.from_slot_id === this.drag.dragFromSlotId
      );
    });
    if (connectionExists) return;

    const connection = sourceElement.connections[this.drag.dragConnectionIndex];
    connection.to_unique_id = targetElementId;
    connection.to_slot_id = targetSlotId;
    this.changeElement(sourceElement);
  };

  releaseConnectionTarget = () => {
    if (this.drag.mode !== EDragMode.CONNECTION_DRAG) return;
    const sourceElement = this.getElementByUniqId(this.drag.dragFromElementId);
    const connection = sourceElement.connections[this.drag.dragConnectionIndex];
    connection.to_unique_id = null;
    connection.to_slot_id = null;
    this.changeElement(sourceElement);
  };

  openElementModal = (elementUniqId: string) => {
    this.root.botElementItemController
      .openCard(elementUniqId, {
        onSave: async () => {
          await this.reload();
          this.root.botElementItemController.closeCard();
        },
      })
      .then();
  };
}
