import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormAsyncInput, FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import style from "./SettingsLink.module.scss";
import { Input } from "../../../components/Input/Input";
import { generateSettingsBotLink } from "../../../../_shared/Util/text";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";

interface ICategoryItemProps {}

export const SettingsLinkItem: React.FunctionComponent<ICategoryItemProps> = observer((props) => {
  let params = useParams();
  let navigate = useNavigate();
  const { settingsLinkId } = params;
  const entityController = root.settingsLinkItemController.entityController;
  useEffect(() => {
    root.settingsLinkItemController.initItem(settingsLinkId).then();
  }, []);

  if (entityController.state !== EAsyncState.IDLE) return <Loader />;
  const item = entityController.item;
  if (!item) return null;

  return (
    <PageWrapper
      header={settingsLinkId ? item.title : `Новая ссылка`}
      onSave={async () => {
        const newItem = await entityController.saveItem();
        navigate(`/settingsLinks/${newItem._id}`);
      }}
      onDelete={
        settingsLinkId
          ? async () => {
              const result = await entityController.deleteItem();
              if (result) {
                navigate(`/settingsLinks`);
              }
            }
          : undefined
      }
      state={EAsyncState.IDLE}
    >
      <Form>
        <GridRow>
          <GridItem>
            <FormInput
              path={"title"}
              item={item}
              title={"Название"}
              onChange={entityController.changeItem("title")}
            />
          </GridItem>
          {item._id.length > 4 && (
            <GridItem size={EGridItemSize.half}>
              <label>Ссылка</label>
              <Input
                disabled={true}
                className={style.botStartLink}
                value={generateSettingsBotLink(item)}
              />
            </GridItem>
          )}
        </GridRow>
        <GridRow>
          <GridItem>
            <FormAsyncInput
              entityType={EEntityType.BOT_SCRIPT}
              path={"bot_script_id"}
              item={item}
              title={"Скрипт бота"}
              onChange={entityController.changeItem("bot_script_id")}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.half}>
            <FormAsyncInput
              entityType={EEntityType.LOCATION}
              title={"Места"}
              path={"location_ids"}
              item={entityController.item}
              isMulti={true}
              onChange={entityController.changeItem("location_ids")}
            />
          </GridItem>
          <GridItem size={EGridItemSize.half}>
            <FormAsyncInput
              entityType={EEntityType.SEARCH_CORE}
              title={"Ядра"}
              path={"search_core_ids"}
              item={entityController.item}
              isMulti={true}
              onChange={entityController.changeItem("search_core_ids")}
            />
          </GridItem>
        </GridRow>
      </Form>
    </PageWrapper>
  );
});
