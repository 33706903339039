import { observer } from "mobx-react-lite";
import { CommonTableController } from "../../controllers/_common/CommonTableController";
import { ControlledTable, ITableOptions } from "../Table/Table";
import { root } from "../../controllers/RootController";
import { getRelativeDate } from "../../../_shared/Util/dates";
import { EClientStatus } from "../../../_shared/_enums/EClientStatus";
import { UnreadMessages } from "../ChatMessenger/UnreadMessages";
import { generateUserLink } from "../Util/Username";
import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import { BotElement, ClientStatus } from "../ClientStatus/ClientStatus";
import { CLIENT_STATUSES } from "../../../_shared/Constants/ClientStatuses";
import { Button, EButtonType } from "../Button/Button";
import React from "react";
import { ComboboxInput } from "../ComboboxInput/ComboboxInput";
import { filter, get } from "lodash";
import { AsyncInput } from "../AsyncInput/AsyncInput";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { trimText } from "../../../_shared/Util/text";
import style from "./InternalClientTable.module.scss";
import cx from "classnames";
import { Input } from "../Input/Input";
import { ILocation } from "../../../_shared/Database/location";

const { DateTime } = require("luxon");

const tableOptions = (tableController: CommonTableController): ITableOptions => {
  return {
    filters: {
      name: {
        title: "Поиск",
        customRender(value, onChange) {
          return (
            <Input
              title={"Поиск"}
              className={style.searchField}
              type={"string"}
              value={value}
              onValueChange={onChange}
              isEnabled={true}
            />
          );
        },
        makeQuery: (val) => {
          if (!val || val.trim() === "") {
            return {};
          }
          const regex = { $regex: val, $options: "i" };
          return {
            $and: [
              {
                $or: [
                  { username: regex },
                  { first_name: regex },
                  { last_name: regex },
                  { client_id: regex },
                  { comment: regex },
                ],
              },
            ],
          };
        },
        type: "string",
      },
      status: {
        title: "Фильтр",
        makeQuery: (val) => {
          if (val === "payed_once") {
            return {
              $and: [
                {
                  $or: [{ payed_to: { $gt: 0 } }],
                },
              ],
            };
          }
          if (val === "musthave") {
            const currentDayEnd = DateTime.now().endOf("day").toMillis();
            const currentDayStart = DateTime.now().startOf("day").toMillis();
            return {
              $and: [
                {
                  $or: [
                    {
                      last_activity: {
                        $lt: DateTime.now().endOf("day").minus({ day: 4 }).toMillis(),
                      },
                      remind_at: null,
                      status: EClientStatus.IN_PROCESS,
                    },
                    { remind_at: { $lt: currentDayEnd } },
                    { payed_to: { $lt: currentDayEnd, $gt: currentDayStart } },
                    {
                      payed_to: { $lt: currentDayEnd },
                      status: EClientStatus.CLOSED,
                    },
                    {
                      payed_to: { $lt: currentDayEnd },
                      status: EClientStatus.TRIAL,
                    },
                    //{need_human_action: true},
                  ],
                },
              ],
            };
          }
          if (val === "processed") {
            return {
              $and: [
                { status: { $ne: EClientStatus.TO_BE_PROCESSED } },
                { status: { $exists: true } },
              ],
            };
          }
          if (val === EClientStatus.TO_BE_PROCESSED) {
            return {
              $and: [
                {
                  $or: [{ status: val }, { status: { $exists: false } }],
                },
              ],
            };
          }
          if (!val) {
            return {};
          }
          return {
            $and: [
              {
                status: val,
              },
            ],
          };
        },
        type: "custom",
        customRender: (value, onChange) => {
          const options = [
            { label: "Не выбрано", value: undefined },
            { label: "Обязательные", value: "musthave" },
            { label: "Хоть раз продленные", value: "payed_once" },
            { label: "Все обработанные", value: "processed" },
            ...CLIENT_STATUSES,
          ];
          return (
            <ComboboxInput
              options={options}
              value={value || undefined}
              onChange={(newVal) => onChange(newVal)}
            />
          );
        },
      },
      locationSearch: {
        title: "Место",
        makeQuery: (country) => {
          if (!country) return {};
          return { location_ids: { $in: [country._id] } };
        },
        type: "custom",
        customRender: (countryId, onChange) => {
          return (
            <AsyncInput
              value={countryId ? countryId._id : null}
              entityType={EEntityType.LOCATION}
              onChangeRaw={onChange}
            />
          );
        },
      },
      scriptSearch: {
        title: "Скрипт",
        makeQuery: (script) => {
          if (!script) return {};
          return { bot_control_script_id: { $in: [script._id] } };
        },
        type: "custom",
        customRender: (script, onChange) => {
          return (
            <AsyncInput
              value={script ? script._id : null}
              entityType={EEntityType.BOT_SCRIPT}
              onChangeRaw={onChange}
            />
          );
        },
      },
      botEnabled: {
        title: "Бот",
        makeQuery: (val) => {
          if (!val) return {};
          return {
            $and: [
              {
                bot_control_script_id: { $ne: null },
              },
            ],
          };
        },
        type: "checkbox",
      },
      isAlerted: {
        title: "Alert",
        makeQuery: (val) => {
          if (!val) return {};
          return {
            $and: [
              {
                has_alert: true,
              },
            ],
          };
        },
        type: "checkbox",
      },
      enalbeBot: {
        title: "Бот",
        type: "custom",
        customRender: () => {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "5px" }}>
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  text={"⚡ Включить бота"}
                  styling={EButtonType.DEFAULT}
                  onClick={() => root.clientItemController.assignBotControl(tableController, true)}
                />
              </div>
              <div>
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  text={"Отключить бота"}
                  styling={EButtonType.DEFAULT}
                  onClick={() => root.clientItemController.assignBotControl(tableController, false)}
                />
              </div>
            </div>
          );
        },
      },
    },
    columns: [
      {
        title: "@",
        field: "username",
        columnStyle: { width: "200px" },
        format: (val, item) => {
          const name = `${item.first_name || ""} ${item.last_name || ""}`;
          const subbed = trimText(name, 20);
          if (item.is_chat_blocked) {
            return (
              <span>
                <UnreadMessages msgCount={item.unread_messages} />
                <del>
                  {subbed} {generateUserLink(item.username)}
                </del>
              </span>
            );
          }
          return (
            <span className={cx({ [style.hasAlert]: !!item.has_alert })}>
              <UnreadMessages msgCount={item.unread_messages} />
              <label>
                {subbed} {generateUserLink(item.username)}
              </label>
            </span>
          );
        },
      },
      {
        title: "Статус",
        field: "status",
        columnStyle: { width: "150px" },
        format: (val) => {
          const state = val ? val : EClientStatus.TO_BE_PROCESSED;
          return <ClientStatus status={state} />;
        },
      },
      {
        title: "Скрипт бота",
        field: "bot_script",
        columnStyle: { width: "150px" },
        format: (val, item) => {
          return <label>{val ? val.title : null}</label>;
        },
      },
      {
        title: "Фаза",
        field: "bot_element",
        columnStyle: { width: "150px" },
        format: (val, item) => {
          return (
            <label>
              <BotElement element={val} />
            </label>
          );
        },
      },
      {
        title: "Активность",
        field: "last_activity",
        columnStyle: { width: "150px" },
        format: (val) => {
          if (!val) return null;
          return getRelativeDate(val);
        },
      },
      {
        title: "Напоминание",
        field: "remind_at",
        columnStyle: { width: "150px" },
        format: (val) => {
          if (!val) return null;
          return getRelativeDate(val);
        },
      },
      {
        title: "Блок",
        field: "is_chat_blocked",
        format: (val) => {
          return !!val ? "Да" : "";
        },
      },
      {
        title: "Оплачено",
        field: "payed_to",
        format: (val: number) => {
          return <PayedToLabel val={val} onlyRelative={true} />;
        },
      },
      {
        title: "Сообщений",
        field: "messages_count",
        format: (val, item) => {
          if (!val) return "";
          return val + "";
        },
      },
      {
        title: "Чатов",
        field: "chat_ids",
        format: (val) => val?.length || 0,
      },
      {
        title: "Места",
        field: "locations",
        format: (val: Partial<ILocation>[], item: any) => {
          if (!val || !val.length) return null;
          let [firstLocation, secondLocation, ...restLocations] = val.map((loc) => {
            return loc.title;
          });

          return (
            <span>
              {firstLocation} {secondLocation ? ", " + secondLocation : " "}{" "}
              {restLocations.length > 2 ? (
                <span>
                  и <span title={restLocations.join(" ,")}>другие</span>
                </span>
              ) : null}
            </span>
          );
        },
      },
    ],
  };
};

export const InternalClientTable: React.FC<{
  tableController: CommonTableController;
  onItemClick?;
}> = observer(({ tableController, onItemClick }) => {
  return (
    <ControlledTable
      controller={tableController}
      options={tableOptions(tableController)}
      onItemClick={onItemClick}
      getItemHref={(item) => `/clients/${item._id}`}
    />
  );
});
