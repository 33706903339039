export enum EEntityType {
  CLIENT = "client",
  LOCATION = "location",
  LEAD = "lead",
  MESSAGE_LOG = "messageLog",
  MESSAGE = "message",
  NODE = "node",
  NODE_LOG = "nodeLog",
  FEED = "feed",
  SEARCH_CORE = "searchCore",
  CATEGORY = "category",
  LEAD_SOURCE = "leadSource",
  TARGET_LIST = "targetList",
  SETTINGS_LINK = "settingsLink",
  MESSAGING_PRIVATE_CHAT = "messagingPrivateChat",
  MESSAGING_PRIVATE_CHAT_MESSAGE = "messagingPrivateChatMessage",
  TRANSACTION = "transaction",
  BOT_SCRIPT = "botScript",
  BOT_ELEMENT = "botElement",
  BOT_VARIABLE = "botVariable",
}
