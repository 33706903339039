export enum EClientStatus {
  TO_BE_PROCESSED = "TO_BE_PROCESSED",
  CLOSED = "CLOSED",
  LOST = "LOST",
  LOST_CONTACT = "LOST_CONTACT",
  IGNORE = "IGNORE",
  REJECT = "REJECT",
  TRIAL = "TRIAL",
  WRONG = "WRONG",
  IN_PROCESS = "IN_PROCESS",
  IS_RESTRICTED = "IS_RESTRICTED",
  NEED_DEVELOPMENT = "NEED_DEVELOPMENT",
  ERROR = "ERROR",
}
