import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { makeToast } from "../../utils/toast";
import { apiController } from "../ApiController";
import { delay } from "../../../_shared/Util/processing";
import { prompter } from "../_common/PromptController";

export class NodeUploadController {
  root: RootController;
  isOpened: boolean = false;
  files: any[] = [];
  status: EAsyncState = EAsyncState.IDLE;

  constructor(root: RootController) {
    this.root = root;
    makeAutoObservable(this);
  }

  accountsUploadModalClose = () => {
    this.isOpened = false;
  };

  accountsUploadModalOpen = () => {
    this.isOpened = true;
  };

  uploadNodes = (files) => {
    this.files = files;
  };

  startUploadTDatas = async () => {
    if (this.files.length === 0) {
      await prompter.alert("Добавьте файлы для загрузки");
      return;
    }
    try {
      this.status = EAsyncState.LOADING;
      const res = await apiController.nodes.uploadTDatas(this.files);
      this.status = EAsyncState.IDLE;
      this.accountsUploadModalClose();
      if (res.message) {
        makeToast("Ошибка! " + res.message);
        return;
      }
      makeToast("Загрузка прошла успешно, ноды обрабатываются...");
      await delay(1000);
      await this.root.nodeTableController.init();
      this.files = [];
    } catch (e) {
      this.status = EAsyncState.FAILURE;
    }
  };
}
