import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { root } from "../../controllers/RootController";
import { ControlledTable, ITableOptions } from "../../components/Table/Table";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { generateUserLink } from "../../components/Util/Username";
import { getDateStraight, getRelativeDate } from "../../../_shared/Util/dates";

const renderUserLink = (item, isBot) => {
  if (!item) return null;
  const name = `${item.first_name || item.firstName || ""} ${
    item.last_name || item.lastName || ""
  }`;
  const subbed = name.length > 20 ? name.substring(0, 20) + "..." : name;
  return (
    <span>
      <label>
        {isBot ? "[BOT]" : ""} {subbed} {generateUserLink(item.username)}
      </label>
    </span>
  );
};

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "По тексту",
      makeQuery: (val) => {
        if (!val || val.trim() === "") {
          return {};
        }
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [{ "tg_message.message": regex }],
        };
      },
      type: "string",
    },
    sender: {
      title: "По участнику",
      makeQuery: (val) => {
        if (!val || val.trim() === "") {
          return {};
        }
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [
                { "bot.0.me.username": regex },
                { "bot.0.me.firstName": regex },
                { "bot.0.me.lastName": regex },
                { "client.0.username": regex },
                { "client.0.first_name": regex },
                { "client.0.last_name": regex },
              ],
            },
          ],
        };
      },
      type: "string",
    },
    botOnly: {
      title: "Только от бота",
      makeQuery: (val) => {
        return {
          $and: [
            {
              $or: [{ "tg_message.out": val }],
            },
          ],
        };
      },
      type: "checkbox",
    },
  },
  columns: [
    {
      title: "Текст",
      field: "tg_message.message",
      columnStyle: { width: "150px" },
      format: (val) => {
        if (!val) return null;
        const text = val.substring(0, 100) + "...";
        return <span title={val}>{text}</span>;
      },
    },
    {
      title: "От кого",
      field: "bot.0.me",
      columnStyle: { width: "150px" },
      format: (v, item) => {
        let user = item.bot[0]?.me;
        if (item.tg_message.out === false) {
          user = item?.client[0];
        }
        return renderUserLink(user, item.tg_message.out);
      },
    },
    {
      title: "Кому",
      field: "client.0",
      columnStyle: { width: "150px" },
      format: (v, item) => {
        let user = item.bot[0]?.me;
        if (item.tg_message.out === true) {
          user = item?.client[0];
        }
        return renderUserLink(user, !item.tg_message.out);
      },
    },
    {
      title: "Дата",
      field: "tg_message.date",
      columnStyle: { width: "150px" },
      format: (val) => {
        if (!val) return null;
        return getDateStraight(val * 1000);
      },
    },
  ],
};

export const DialogMessagesTable = observer(() => {
  const controller = root.dialogsMessagesTableController;
  useEffect(() => {
    controller.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Сообщения из диалогов"} state={EAsyncState.IDLE}>
      <ControlledTable
        getItemHref={(item) => `/dialog/${item.messaging_private_chat_id}`}
        controller={controller}
        options={tableOptions}
      />
    </PageWrapper>
  );
});
