import style from "./PayedToLabel.module.scss";
import { DateTime } from "luxon";
import cx from "classnames";

export const PayedToLabel = ({ val, isStyled = true, onlyRelative = false }) => {
  try {
    const now = Date.now();
    const inval = val === "0" || !val;
    const dt = DateTime.fromMillis(parseInt(val));
    return (
      <span
        className={cx({
          [style.isPayed]: inval || now < val,
          [style.payText]: true,
          [style.noStyling]: !isStyled,
        })}
      >
        {inval ? (
          "-"
        ) : (
          <span title={val}>
            {!onlyRelative && <>{dt.toLocaleString(DateTime.DATE_MED)} </>}
            {dt.toRelativeCalendar()}
          </span>
        )}
      </span>
    );
  } catch (e) {
    return <span>Неправильная дата</span>;
  }
};
