import { observer } from "mobx-react-lite";
import { Form } from "../../../../components/Form/Form";
import { FormInput } from "../../../../components/FormInput/FormInput";
import { GridItem, EGridItemSize } from "../../../../components/Grid/GridItem";
import { GridRow } from "../../../../components/Grid/GridRow";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";

export const BotElementAlertForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  return (
    <Form>
      <GridRow>
        <GridItem size={EGridItemSize.full}>
          <FormInput
            path={"element_data.message"}
            item={item}
            title={"Сообщение"}
            onChange={controller.entityController.changeItem("element_data.message")}
          />
        </GridItem>
      </GridRow>
    </Form>
  );
});
