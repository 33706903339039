import { observer } from "mobx-react-lite";
import { root } from "../../../controllers/RootController";
import { useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";
import styles from "../reports.module.scss";

export const NodeChart = observer(({ nodePhone }: { nodePhone: string }) => {
  const chartData = root.nodeReportController.report;
  const nodeData = chartData[nodePhone];
  const messagesChart = nodeData.data;

  const primaryAxis = useMemo(
    () => ({
      scaleType: "localTime",
      getValue: (datum) => new Date(datum.dt),
    }),
    [],
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.msgs,
      },
    ],
    [],
  );

  const data = [
    {
      dataType: "time",
      label: "Messages",
      data: messagesChart,
    },
  ];

  return (
    <div className={styles.chartWrapper}>
      <Chart
        options={{
          data,
          primaryAxis: primaryAxis as any,
          secondaryAxes,
        }}
      />
    </div>
  );
});
