import { get } from "lodash";
import { EGridItemSize } from "../Grid/GridItem";
import { observer } from "mobx-react-lite";
import { Input, TextArea } from "../Input/Input";
import { FormInputWrapper } from "../Form/Form";
import { AsyncInput } from "../AsyncInput/AsyncInput";
import { EEntityType } from "../../../_shared/_enums/EEntityType";

interface IFormInput {
  title: string;
  path: string;
  type?: string;
  item: any;
  size?: EGridItemSize;
  onChange: (newValue: any) => void;
  isEnabled?: boolean;
}

export const FormInput: React.FunctionComponent<IFormInput> = observer(
  ({ title, path, item, onChange, type = "text", isEnabled = true }) => {
    return (
      <FormInputWrapper>
        <label>{title}</label>
        {type === "textarea" ? (
          <TextArea value={get(item, path, "")} onValueChange={onChange} />
        ) : (
          <Input
            value={get(item, path, "")}
            type={type}
            onValueChange={onChange}
            isEnabled={isEnabled}
          />
        )}
      </FormInputWrapper>
    );
  },
);

interface IFormAsyncInput extends IFormInput {
  entityType: EEntityType;
  isMulti?: boolean;
  additionalFilters?: any;
}

export const FormAsyncInput: React.FunctionComponent<IFormAsyncInput> = observer(
  ({
    title,
    path,
    item,
    onChange,
    entityType,
    isEnabled = true,
    isMulti = false,
    additionalFilters = {},
  }) => {
    return (
      <FormInputWrapper>
        <label>{title}</label>
        <AsyncInput
          value={get(item, path, undefined)}
          entityType={entityType}
          onChange={onChange}
          isMulti={isMulti}
          additionalFilters={additionalFilters}
        />
      </FormInputWrapper>
    );
  },
);
