import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import style from "./ClientForm.module.scss";
import { EBotElementType } from "../../../_shared/_enums/EBotElementEnums";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { AsyncInput } from "../AsyncInput/AsyncInput";

export const BotPhaseChanger: React.FunctionComponent<{
  elementUniqueId: string | null;
  botScriptId: string | null;
  onElementChange: (newElement: string) => void;
}> = observer(({ elementUniqueId, onElementChange, botScriptId }) => {
  return (
    <div className={style.BotElementChangerWrapper}>
      <AsyncInput
        entityType={EEntityType.BOT_ELEMENT}
        onChange={onElementChange}
        value={elementUniqueId || null}
        titleField={"element_data.title"}
        valueField={"unique_id"}
        formatInputQuery={(inp) => ({
          "element_data.title": { $regex: inp, $options: "i" },
        })}
        additionalFilters={{
          type: EBotElementType.PHASE,
          bot_script_id: botScriptId,
        }}
      />
    </div>
  );
});
