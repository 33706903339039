import style from "./Grid.module.scss";
import React from "react";
import cx from "classnames";

interface IGridItemProps {
  children: React.ReactNode;
  direction?: "LEFT" | "RIGHT" | "CENTER";
}

export const GridRow: React.FunctionComponent<IGridItemProps> = ({
  children,
  direction = "LEFT",
}) => {
  return (
    <div
      className={cx({
        [style.GridRow]: true,
        [style[direction]]: true,
      })}
    >
      {children}
    </div>
  );
};
