import { trimText } from "../../../_shared/Util/text";

export const generateUserLink = (username) => {
  if (!username) return null;
  return (
    <a target={"_blank"} href={"https://t.me/" + username}>
      @{trimText(username, 20)}
    </a>
  );
};
