import style from "./ClientForm.module.scss";
import { observer } from "mobx-react-lite";
import { ClientItemController } from "../../controllers/Client/ClientItemController";
import { Form, FormInputWrapper } from "../Form/Form";
import { EGridItemSize, GridItem } from "../Grid/GridItem";
import { GridRow } from "../Grid/GridRow";
import { CLIENT_STATUSES } from "../../../_shared/Constants/ClientStatuses";
import { FormAsyncInput, FormInput } from "../FormInput/FormInput";
import { identity, map } from "lodash";
import { DateEditor } from "../DateEditor/DateEditor";
import { TextArea } from "../Input/Input";
import { Link } from "react-router-dom";
import { getRelativeDate } from "../../../_shared/Util/dates";
import { UnreadMessages } from "../ChatMessenger/UnreadMessages";
import { FeedEditor } from "../FeedEditor/FeedEditor";
import cx from "classnames";
import { PaymentModal } from "./PaymentModal";
import { PaymentHistoryModal } from "./PaymentHistoryModal";
import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { BotPhaseChanger } from "./BotPhaseChanger";
import { ComboboxInput } from "../ComboboxInput/ComboboxInput";
import { AsyncInput } from "../AsyncInput/AsyncInput";
import { VariablesTable } from "./VariablesTable";

const ClientMessages = ({ messages }) => {
  return (
    <div className={style.clientMessagesWrapper}>
      {map(messages, ({ text, chat_id, chatUsername, message_id, date }) => {
        return (
          <a
            key={chatUsername + message_id}
            className={style.clientMessage}
            target="_blank"
            href={`https://t.me/${chatUsername}/${message_id}`}
          >
            <b>{date ? getRelativeDate(date) : "Дата неизвестна"}</b>
            <br />
            {text.substring(0, 100)}
          </a>
        );
      })}
    </div>
  );
};

const ClientDialogs: React.FunctionComponent<{
  controller: ClientItemController;
}> = observer(({ controller }) => {
  return (
    <div className={style.dialogsWrapper}>
      <div style={{ maxWidth: 400 }}>
        <AsyncInput
          value={controller.item.bot_control_script_id}
          entityType={EEntityType.BOT_SCRIPT}
          onChange={controller.changeBotScript}
        />
      </div>
      {map(controller.dialogs, (dlg) => {
        if (!dlg.node) return null;
        if (!dlg.node.me) return null;
        const nodeName = dlg.node?.me.firstName + " " + (dlg.node.me.lastName || "");
        const isRestictedOrBanned = !!dlg.node.error;
        return (
          <div
            key={dlg._id}
            className={cx({
              [style.dlgWrapper]: true,
              //[style.dlgWrapperNeedAction]: controller.item.need_human_action,
              [style.dlgResticted]: isRestictedOrBanned,
            })}
          >
            <Link to={"/dialog/" + dlg._id} onClick={(e) => controller.closeCard()}>
              <UnreadMessages msgCount={dlg.unread_messages} />
              <label>
                {dlg.node_phone} {nodeName}
              </label>
            </Link>
            <BotPhaseChanger
              botScriptId={controller.item.bot_control_script_id}
              elementUniqueId={dlg.bot_element_unique_id}
              onElementChange={(elementUniqueId) =>
                controller.changeDlgElement(dlg._id, elementUniqueId)
              }
            />
          </div>
        );
      })}
    </div>
  );
});

export const ClientForm: React.FunctionComponent<{
  controller: ClientItemController;
}> = observer(({ controller }) => {
  const cardItem = controller.item;

  const locations = controller.root.locationItemController.location_map;

  const defaultLocations = (cardItem.location_ids || [])
    .map((id) => {
      if (!locations[id]) {
        return null;
      }
      return {
        _id: id,
        title: locations[id].title,
      };
    })
    .filter(identity);

  const lastUpdate = controller.item.updated_at
    ? controller.item.updated_at
    : controller.item.last_contact;

  const botVariables = controller.item.bot_variables;

  return (
    <Form className={style.clientModalForm}>
      <GridRow>
        <GridItem size={EGridItemSize.half}>
          <FormInputWrapper>
            <label>Статус</label>
            <ComboboxInput
              options={CLIENT_STATUSES}
              value={controller.item.status}
              onChange={(newVal) => controller.changeItem("status")(newVal)}
            />
          </FormInputWrapper>
        </GridItem>
        <GridItem size={EGridItemSize.small}>
          <FormInput
            type="checkbox"
            title={"🚨"}
            path={"has_alert"}
            item={controller.item}
            onChange={controller.changeItem("has_alert")}
          />
        </GridItem>
        <GridItem size={EGridItemSize.half}>
          <label>Напомнить в</label>
          <DateEditor
            value={controller.item.remind_at}
            onChange={controller.changeItem("remind_at")}
          />
        </GridItem>
        <GridItem size={EGridItemSize.half}>
          <FormAsyncInput
            entityType={EEntityType.LOCATION}
            title={"Локация"}
            path={"location_ids"}
            item={controller.item}
            isMulti={true}
            onChange={controller.changeItem("location_ids")}
          />
        </GridItem>
      </GridRow>
      <GridRow>
        <GridItem size={EGridItemSize.half}>
          <FormInputWrapper>
            <label>Комментарий</label>
            <TextArea
              value={controller.item.comment}
              onChange={(e) => controller.changeItem("comment")(e.target.value)}
            />
          </FormInputWrapper>
        </GridItem>
        <GridItem size={EGridItemSize.half}>
          <FormInputWrapper>
            <label>Сообщения</label>
            <ClientMessages messages={controller.item.messages} />
          </FormInputWrapper>
        </GridItem>
      </GridRow>
      <GridRow>
        <GridItem size={EGridItemSize.half}>
          <h3>Диалоги</h3>
          <ClientDialogs controller={controller} />
        </GridItem>
        {botVariables && (
          <GridItem size={EGridItemSize.half}>
            <h3>Переменные</h3>
            <VariablesTable allVariables={controller.allVariables} botVariables={botVariables} />
          </GridItem>
        )}
      </GridRow>

      <GridRow>
        <GridItem size={EGridItemSize.full}>
          <h3>Фиды</h3>
          <FeedEditor
            feeds={controller.feeds}
            searchCores={controller.searchCores}
            onChangeBlacklistWords={controller.onChangeBlacklistWords}
            onChangeLocationIds={controller.changeLocations}
            onChangeCategoryIds={controller.changeCores}
            onOpenPaymentModal={controller.paymentController.openModal}
            onOpenPaymentHistoryModal={controller.paymentController.openHistoryModal}
            onChangeMuteState={controller.changeMuteState}
          />
        </GridItem>
      </GridRow>
      {lastUpdate ? (
        <GridRow>
          <GridItem size={EGridItemSize.half}>
            Обновлено: <PayedToLabel val={lastUpdate} isStyled={false} />
          </GridItem>
        </GridRow>
      ) : null}
      <PaymentModal controller={controller.paymentController} />
      <PaymentHistoryModal controller={controller.paymentController} />
    </Form>
  );
});
