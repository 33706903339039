import { observer } from "mobx-react-lite";
import { BotScriptItemController } from "../../../controllers/BotScript/BotScriptItemController";
import { Modal } from "../../../components/Modal/Modal";
import { Form } from "../../../components/Form/Form";
import { GridRow } from "../../../components/Grid/GridRow";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import React from "react";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import { Button } from "../../../components/Button/Button";
import { FormAsyncInput, FormInput } from "../../../components/FormInput/FormInput";

export const AutomationSettingsModal: React.FunctionComponent<{
  controller: BotScriptItemController;
}> = observer(({ controller }) => {
  if (!controller.isAutomationSettingsModalOpen) return null;

  return (
    <Modal header={"Автоматизация"} onClose={controller.closeAutomationSettings}>
      <span>
        Эти настройки служат для поиска по локациям и ядрам сообщений,
        <br />
        которые автоматически назначаются на текущий скрипт.
        <br />
        <br />
        Не забудь сохранить скрипт после изменения
      </span>
      <br />
      <br />
      <Form>
        <GridRow>
          <FormInput
            type={"checkbox"}
            title={"Сбор новых лидов включен"}
            path={"automationIsEnabled"}
            item={controller.entityController.item}
            onChange={controller.entityController.changeItem("automationIsEnabled")}
          />
          <FormInput
            type={"checkbox"}
            title={"Автошедуллинг на рассылку"}
            path={"automationSchedulingEnabled"}
            item={controller.entityController.item}
            onChange={controller.entityController.changeItem("automationSchedulingEnabled")}
          />
          <FormInput
            type={"checkbox"}
            title={"Рассылка по лидам включена"}
            path={"automationSendingEnabled"}
            item={controller.entityController.item}
            onChange={controller.entityController.changeItem("automationSendingEnabled")}
          />
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.half}>
            <FormAsyncInput
              entityType={EEntityType.SEARCH_CORE}
              title={"Ядра для поиска"}
              path={"automationCoreIds"}
              item={controller.entityController.item}
              isMulti={true}
              onChange={controller.entityController.changeItem("automationCoreIds")}
            />
          </GridItem>
          <GridItem size={EGridItemSize.half}>
            <FormAsyncInput
              entityType={EEntityType.LOCATION}
              title={"Локации для поиска"}
              path={"automationLocationIds"}
              item={controller.entityController.item}
              isMulti={true}
              onChange={controller.entityController.changeItem("automationLocationIds")}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}> </GridItem>
          <GridItem size={EGridItemSize.small}>
            <Button text={"Сохранить"} onClick={controller.saveAutomatization} />
          </GridItem>
        </GridRow>
      </Form>
    </Modal>
  );
});
