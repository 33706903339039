import style from "../BotElementItem.module.scss";
import { observer } from "mobx-react-lite";
import { FormInput } from "../../../../components/FormInput/FormInput";
import { EGridItemSize, GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { EConditionTerm } from "../../../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { NodeEditModal } from "../../../../components/NodeTreeEditor/NodeEditModal";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";
import { NodeTreeEditor } from "../../../../components/NodeTreeEditor/NodeTreeEditor";
import { ElementTestingModal } from "../ElementTestingModal";

export const BotElementPhaseForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  if (!controller.elementPhaseController) return null;
  return (
    <>
      <Form>
        <GridRow>
          <GridItem size={EGridItemSize.half}>
            <FormInput
              path={"element_data.title"}
              item={item}
              title={"Название"}
              onChange={controller.entityController.changeItem("element_data.title")}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium}>
            <FormInput
              path={"element_data.is_master"}
              item={item}
              type="checkbox"
              title={"Мастер-фаза"}
              onChange={controller.entityController.changeItem("element_data.is_master")}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium}>
            <FormInput
              path={"element_data.is_ai"}
              item={item}
              type="checkbox"
              title={"AI-фаза"}
              onChange={controller.entityController.changeItem("element_data.is_ai")}
            />
          </GridItem>
        </GridRow>
        {!!item.element_data.is_ai ? (
          <>
            <GridRow>
              <GridItem size={EGridItemSize.full}>
                <FormInput
                  path={"element_data.use_history"}
                  item={item}
                  type="checkbox"
                  title={"Использовать историю переписки"}
                  onChange={controller.entityController.changeItem("element_data.use_history")}
                />
              </GridItem>
            </GridRow>
            <GridRow>
              <GridItem size={EGridItemSize.full}>
                <FormInput
                  path={"element_data.global_ai_context"}
                  item={item}
                  type="textarea"
                  title={"Глобальный Контекст"}
                  onChange={controller.entityController.changeItem(
                    "element_data.global_ai_context",
                  )}
                />
              </GridItem>
            </GridRow>
            <GridRow>
              <GridItem size={EGridItemSize.full}>
                <FormInput
                  path={"element_data.ai_context"}
                  item={item}
                  type="textarea"
                  title={"Текущий контекст"}
                  onChange={controller.entityController.changeItem("element_data.ai_context")}
                />
              </GridItem>
            </GridRow>
          </>
        ) : (
          <GridRow>
            <div className={style.ConditionEditowWrapper}>
              <h3>Фраза на этой фазе</h3>
              <NodeTreeEditor
                operators={[EConditionTerm.AND, EConditionTerm.OR]}
                value={controller.elementPhaseController.phraseEditorController.getContent()}
                controller={controller.elementPhaseController.phraseEditorController}
              />
            </div>
          </GridRow>
        )}
      </Form>
      <ElementTestingModal controller={controller.elementPhaseController} />
      <NodeEditModal
        controller={controller.elementPhaseController.phraseEditorController}
        operators={[EConditionTerm.AND, EConditionTerm.OR]}
      />
    </>
  );
});
