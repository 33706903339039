import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";

class PromptController {
  currentPrompt = null;
  currentCallback = null;

  constructor() {
    makeAutoObservable(this);
  }

  makeSelection = (variantValue: any) => {
    if (!this.currentCallback) return;
    this.currentCallback(variantValue);
    this.currentPrompt = null;
    this.currentCallback = null;
  };

  genCb = async () => {
    return new Promise((resolve) => {
      this.currentCallback = resolve;
    });
  };

  alert = async (message: string) => {
    this.currentPrompt = {
      message,
      variants: [{ value: true, label: "Ok" }],
    };
    return (await this.genCb()) as Promise<null>;
  };

  confirm = async (message: string): Promise<boolean> => {
    this.currentPrompt = {
      message,
      variants: [
        { value: true, label: "Да" },
        { value: false, label: "Нет" },
      ],
    };
    return (await this.genCb()) as Promise<boolean>;
  };

  slider = async (message: string): Promise<boolean> => {
    this.currentPrompt = {
      message,
      slider: true,
    };
    return (await this.genCb()) as Promise<boolean>;
  };

  prompt = async (message: string): Promise<string> => {
    this.currentPrompt = {
      message,
      textInput: true,
    };
    return (await this.genCb()) as Promise<string>;
  };

  variants = async <T>(message: string, variants: { label: string; value: T }[]) => {
    this.currentPrompt = {
      message,
      variants,
    };
    return (await this.genCb()) as Promise<T>;
  };

  asyncSelect = async (message: string, entityType: EEntityType) => {
    this.currentPrompt = {
      message,
      entityType,
    };
    return (await this.genCb()) as Promise<string>;
  };
}

export const prompter = new PromptController();
