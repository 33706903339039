import { keyBy } from "lodash";
import { ELogLevel } from "../_enums/ELogLevel";

export const LOG_LEVELS = [
  { label: "TRACE", value: ELogLevel.TRACE },
  { label: "DEBUG", value: ELogLevel.DEBUG },
  { label: "INFO", value: ELogLevel.INFO },
  { label: "WARN", value: ELogLevel.WARN },
  { label: "ERROR", value: ELogLevel.ERROR },
  { label: "FATAL", value: ELogLevel.FATAL },
];

export const LOG_LEVELS_KEYED = keyBy(LOG_LEVELS, "value");
