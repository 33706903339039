import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { NodeItemController } from "../../../controllers/NodeController/NodeItemController";
import { INode } from "../../../../_shared/Database/node";
import { Form } from "../../../components/Form/Form";
import { FormInput } from "../../../components/FormInput/FormInput";
import { GridItem, EGridItemSize } from "../../../components/Grid/GridItem";
import { GridRow } from "../../../components/Grid/GridRow";
import { Button } from "../../../components/Button/Button";
import { useDropzone } from "react-dropzone";
import style from "./NodeItem.module.scss";

export const NodeManagementModal: React.FunctionComponent<{
  controller: NodeItemController;
}> = observer(({ controller }) => {
  const isModalOpen = !!controller.isManagementConsoleOpen;
  const onDrop = useCallback((acceptedFiles) => {
    controller.uploadAvatar(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  if (!isModalOpen) return null;

  return (
    <Modal
      header={"Менеджмент ноды"}
      innerStyle={{ minWidth: 500 }}
      onClose={controller.closeManagement}
    >
      <Form>
        <h3>Аватар</h3>
        <GridRow>
          <GridItem>
            <div className={style.dropZoneWrapper} {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>DRAG AVATAR OR CLICK TO SELECT</p>
              )}
              <div className={style.dropZoneFileHolder}>
                {controller.newAvatar ? <label>{controller.newAvatar.name}</label> : null}
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div style={{ paddingTop: "27px" }}></div>
            <Button text={"Загрузить"} onClick={() => controller.saveAvatar()} />
          </GridItem>
        </GridRow>
        <h3>Профиль</h3>
        <GridRow>
          <GridItem>
            <FormInput
              path={"firstName"}
              item={controller.newProfile}
              title={"Новое имя"}
              onChange={(val) => controller.changeProfile({ firstName: val })}
            />
          </GridItem>
          <GridItem>
            <FormInput
              path={"lastName"}
              item={controller.newProfile}
              title={"Новая фамилия"}
              onChange={(val) => controller.changeProfile({ lastName: val })}
            />
          </GridItem>
          <GridItem>
            <FormInput
              path={"about"}
              item={controller.newProfile}
              title={"Нвое описание"}
              onChange={(val) => controller.changeProfile({ about: val })}
            />
          </GridItem>
          <GridItem>
            <div style={{ paddingTop: "27px" }}></div>
            <Button text={"Изменить"} onClick={() => controller.saveName()} />
          </GridItem>
        </GridRow>
        <h3>Пароль</h3>
        <GridRow>
          <GridItem>
            <FormInput
              path={"currentPassword"}
              item={controller.new2FA}
              title={"Текущий пароль"}
              onChange={(val) => controller.change2FA({ currentPassword: val })}
            />
          </GridItem>
          <GridItem>
            <FormInput
              path={"newPassword"}
              item={controller.new2FA}
              title={"Новый пароль"}
              onChange={(val) => controller.change2FA({ newPassword: val })}
            />
          </GridItem>
          <GridItem>
            <div style={{ paddingTop: "27px" }}></div>
            <Button text={"Установить"} onClick={() => controller.save2FA()} />
          </GridItem>
        </GridRow>
        <h3>Безопасность</h3>
        <GridRow>
          <GridItem>
            <div style={{ paddingTop: "27px" }}></div>
            <Button
              text={"Установить настройки безопасности"}
              onClick={() => controller.setFullPrivacy()}
            />
          </GridItem>
          <GridItem>
            <div style={{ paddingTop: "27px" }}></div>
            <Button
              text={"Сброс всех сессий"}
              onClick={() => controller.terminateAllOtherSessions()}
            />
          </GridItem>
        </GridRow>
      </Form>
    </Modal>
  );
});
