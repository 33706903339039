import { EListType } from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { makeAutoObservable, toJS } from "mobx";
import { CommonEntityController } from "./CommonEntityController";
import { TreeEditorController } from "./TreeEditorController";
import { get } from "lodash";
import { apiController } from "../ApiController";
import { prompter } from "./PromptController";
import { SearchCoreEngine } from "../../../_shared/Util/searchCoreEngine";

export class ConditionalController {
  entityController: CommonEntityController<any>;
  currentEditingNode: { listType: EListType; path: number[] } | null = null;
  whiteListController: TreeEditorController;
  blackListController: TreeEditorController;
  pathAppendix = [];
  testing: any = {
    result: null,
    opened: false,
    text: "",
  };

  constructor(entityController, pathAppendix = ["conditions", "content"]) {
    this.pathAppendix = pathAppendix;
    this.entityController = entityController;
    this.whiteListController = new TreeEditorController(this.entityController, [
      ...this.pathAppendix,
      "whitelist",
    ]);
    this.blackListController = new TreeEditorController(this.entityController, [
      ...this.pathAppendix,
      "blacklist",
    ]);
    makeAutoObservable(this);
  }

  openTesting = () => {
    this.testing.opened = true;
    this.testing.result = null;
    this.testCore();
  };

  closeTesting = () => {
    this.testing.opened = false;
  };

  testTextChange = (newText: string) => {
    this.testing.text = newText;
    this.testCore();
  };

  getCore = () => {
    return get(this.entityController.item, this.pathAppendix, {});
  };

  testCore = () => {
    const core = toJS(this.getCore());
    const engine = new SearchCoreEngine(core);
    this.testing.result = engine.search({ input: this.testing.text, isFast: false });
  };

  testCoreAi = async () => {
    const itemData = this.entityController.item.element_data
      ? this.entityController.item.element_data
      : this.entityController.item;
    const prompt = itemData.ai_prompt.trim();
    if (!prompt || prompt === "") return;
    const text = this.testing.text.trim();
    if (!text || text === "") return;
    const result = await apiController["coreLab"].aiAnswerTest(prompt, text);
    await prompter.alert(result.response ? "Проходит" : "Не проходит");
  };
}
