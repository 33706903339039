import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { observer } from "mobx-react-lite";
import { EBotElementType } from "../../../../_shared/_enums/EBotElementEnums";
import React from "react";
import { BotElementResponseForm } from "./elementTypes/BotElementResponseForm";
import { BotElementStatusChangeForm } from "./elementTypes/BotElementStatusChangeForm";
import { BotElementConditionForm } from "./elementTypes/BotElementConditionForm";
import { BotElementTimeoutForm } from "./elementTypes/BotElementTimeoutForm";
import { BotElementPhaseForm } from "./elementTypes/BotElementPhaseForm";
import { BotElementVariableChangeForm } from "./elementTypes/BotElementVariableChangeForm";
import { BotElementAlertForm } from "./elementTypes/BotElementAlertForm";

interface IBotElementItemFormProps {}

export const BotElementItemForm: React.FunctionComponent<IBotElementItemFormProps> = observer(
  (props) => {
    const controller = root.botElementItemController;
    if (controller.entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = controller.entityController.item;

    switch (item.type as EBotElementType) {
      case EBotElementType.PHASE:
        return <BotElementPhaseForm controller={controller} />;
      case EBotElementType.TIMEOUT_CHECK:
        return <BotElementTimeoutForm controller={controller} />;
      case EBotElementType.RESPONSE_CHECK:
        return <BotElementResponseForm controller={controller} />;
      case EBotElementType.STATUS_CHANGE:
        return <BotElementStatusChangeForm controller={controller} />;
      case EBotElementType.VARIABLE_CHANGE:
        return <BotElementVariableChangeForm controller={controller} />;
      case EBotElementType.CONDITION_CHECK:
        return <BotElementConditionForm controller={controller} />;
      case EBotElementType.ALERT:
        return <BotElementAlertForm controller={controller} />;
    }
  },
);
