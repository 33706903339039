import { each, filter, identity } from "lodash";
import { ISearchCoreConditionContent, ProcessTerm } from "../_interfaces/searchCoreEngine";
import {
  ECustomPattern,
  ICustomPatternsOccurances,
  findCustomPatterns,
} from "./customPatternProcessor";

import {
  IMatchSelection,
  isTextValid,
  isTextValidPos,
  prepareTextForProcessing,
} from "./textProcessor";
import { EValidationReason } from "../_enums/EValidationReason";
const AhoCorasick = require("ahocorasick");

export const traverseTerm = (term: ProcessTerm, onStringFilter: (primitive: string) => void) => {
  for (let filter of term.content) {
    if (typeof filter !== "string") {
      traverseTerm(filter, onStringFilter);
    } else {
      onStringFilter(filter);
    }
  }
};

export type IOccurancesMap = { [k: string]: number[] };

export class SearchCoreEngine {
  private ac: typeof AhoCorasick;
  private customPatterns: Set<ECustomPattern> = new Set();
  private stringFilters: Set<string> = new Set();
  condition?: ISearchCoreConditionContent;

  constructor(condition?: ISearchCoreConditionContent) {
    if (!condition) return;
    this.setCondition(condition);
  }

  traverseConditions = (term: ProcessTerm) => {
    traverseTerm(term, (prim) => {
      if (prim.startsWith("{")) {
        this.customPatterns.add(prim.trim().toLowerCase() as ECustomPattern);
        return;
      }
      this.stringFilters.add(prim.toLowerCase());
    });
  };

  mergeCondition = (condition: ISearchCoreConditionContent) => {
    if (!!condition.whitelist) {
      this.traverseConditions(condition.whitelist);
    }
    if (!!condition.blacklist) {
      this.traverseConditions(condition.blacklist);
    }
    this.ac = new AhoCorasick(this.stringFilters);
  };

  setCondition = (condition: ISearchCoreConditionContent) => {
    this.condition = condition;
    this.stringFilters = new Set();
    this.customPatterns = new Set();
    this.mergeCondition(condition);
  };

  getCorasickOccurances = (input: string): IOccurancesMap => {
    let acRes = this.ac.search(input);
    let out = {};
    each(acRes, ([endIndex, patterns]) => {
      each(patterns, (ptr) => {
        if (!out[ptr]) {
          out[ptr] = [];
        }
        out[ptr].push(endIndex);
      });
    });
    return out;
  };

  getCustomPatternsOccurances = (input: string): ICustomPatternsOccurances => {
    return findCustomPatterns(input, Array.from(this.customPatterns));
  };

  getOccurances = (input: string): [IOccurancesMap, ICustomPatternsOccurances] => {
    return [this.getCorasickOccurances(input), this.getCustomPatternsOccurances(input)];
  };

  search = ({
    input,
    patterns,
    condition,
    occurrences,
    isFast = true,
  }: {
    input: string;
    condition?: ISearchCoreConditionContent;
    patterns?: ICustomPatternsOccurances;
    occurrences?: IOccurancesMap;
    isFast?: boolean;
  }): { reason: EValidationReason; positions: IMatchSelection[] } => {
    if (!condition && !this.condition) {
      throw "Cant search with virtual search core";
    }
    condition = condition ? condition : this.condition;

    // If no occurrences or patterns given - process by this core itself and prepare input either
    if (!occurrences || !patterns) {
      input = prepareTextForProcessing(input);
      [occurrences, patterns] = this.getOccurances(input);
    }

    return isTextValidPos(input, condition, occurrences, patterns, isFast);
  };

  isValid = (input: string, patterns?: ICustomPatternsOccurances, occurrences?: IOccurancesMap) => {
    const validPos = this.search({ input, patterns, occurrences });
    return validPos.reason === EValidationReason.WHITE_LIST;
  };
}
