import { observer } from "mobx-react-lite";
import { CommonTableController } from "../../controllers/_common/CommonTableController";
import { ControlledTable, ITableOptions } from "../Table/Table";
import { LOG_LEVELS } from "../../../_shared/Constants/LogLevels";
import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import React from "react";
import { ComboboxInput } from "../ComboboxInput/ComboboxInput";
import { getDateStraight } from "../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [{ message: regex }],
            },
          ],
        };
      },
      type: "string",
    },
    status: {
      title: "Фильтр",
      makeQuery: (val) => {
        if (val === null) {
          return {};
        }
        return {
          $and: [
            {
              $or: [{ level: val }],
            },
          ],
        };
      },
      type: "custom",
      customRender: (value, onChange) => {
        const options = [{ label: "Не выбрано", value: null }, ...LOG_LEVELS];
        return (
          <ComboboxInput options={options} value={value} onChange={(newVal) => onChange(newVal)} />
        );
      },
    },
  },
  columns: [
    {
      title: "Created At",
      field: "created_at",
      columnStyle: { width: "300px" },
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
    {
      title: "Level",
      field: "level",
    },
    {
      title: "Msg",
      field: "message",
    },
  ],
};

export const NodeLogTable: React.FC<{
  tableController: CommonTableController;
  onItemClick?;
}> = observer(({ tableController, onItemClick = () => {} }) => {
  return (
    <ControlledTable
      controller={tableController}
      options={tableOptions}
      onItemClick={onItemClick}
    />
  );
});
