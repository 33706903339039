import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { CommonTableController } from "../_common/CommonTableController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { apiController } from "../ApiController";
import { makeToast } from "../../utils/toast";
import { ConditionalController } from "../_common/ConditionalController";
import { filter, find, identity } from "lodash";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";

export class MessageLogController {
  root: RootController;
  tableController: CommonTableController;
  conditionalController: ConditionalController | null = null;
  scanStatus: EAsyncState = EAsyncState.IDLE;

  constructor(root: RootController) {
    this.root = root;
    this.tableController = new CommonTableController(EEntityType.MESSAGE_LOG);

    makeAutoObservable(this);
  }

  init = async () => {
    await this.root.searchCoreTableController.loadTable();
    await this.tableController.loadTable();
  };

  getScanStatus = () => {
    if (this.scanStatus === EAsyncState.IDLE) return "Сканировать";
    return "Сканирование...";
  };

  openRowTest = (rowId) => {
    const core = this.tableController.filterValues.core;
    if (!core) return;
    const row = find(this.tableController.items, { _id: rowId });
    this.conditionalController = new ConditionalController({ item: core });
    this.conditionalController.testTextChange(row.data.text);
    this.conditionalController.openTesting();
    this.conditionalController.testCore();
  };

  startCoreScan = async () => {
    const searchCore = this.tableController.filterValues.search;
    const core = this.tableController.filterValues.core;
    const size = this.tableController.filterValues.scanSize;
    const searchText = this.tableController.filterValues.inputSearch;

    if (searchCore) {
      const { entity } = await apiController[EEntityType.SEARCH_CORE][ECrudMethods.GETBY_ID](
        searchCore._id,
      );
      this.tableController.filterValues.search = entity;
    }
    if (core) {
      const { entity } = await apiController[EEntityType.SEARCH_CORE][ECrudMethods.GETBY_ID](
        core._id,
      );
      this.tableController.filterValues.core = entity;
    }
    if (!core && !searchCore && !searchText) {
      makeToast("Не выбрано ни одно ядро или текст");
      return;
    }

    try {
      this.scanStatus = EAsyncState.LOADING;
      if (core || searchCore) {
        await apiController.coreLab.scan(
          filter([(core || {})._id, (searchCore || {})._id], identity),
          size || 200000,
        );
      }
      this.scanStatus = EAsyncState.IDLE;
    } catch (e) {
      this.scanStatus = EAsyncState.IDLE;
      makeToast("Что-то пошло не так");
    }
    await this.tableController.loadTable();
  };
}
