import style from "./Toggle.module.scss";
import cx from "classnames";
import { map } from "lodash";

interface IToggleVariant<T> {
  label: string;
  value: T;
}

interface IToggleProps<T> {
  selected: T;
  variants: IToggleVariant<T>[];
  onSelect: (newVal: T) => void;
}

export function Toggle<T>({ selected, variants, onSelect }: IToggleProps<T>) {
  const handleSelect = (e: any, newVal: T) => {
    e.stopPropagation();
    onSelect(newVal);
  };

  return (
    <div className={style.ToggleWrapper}>
      {map(variants, (item) => {
        return (
          <span
            key={item.value as string}
            className={cx({
              [style.isPicked]: item.value === selected,
              [style.ToggleItem]: true,
            })}
            onClick={(e) => handleSelect(e, item.value)}
          >
            {item.label}
          </span>
        );
      })}
    </div>
  );
}
