import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [{ title: regex }],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    { title: "Название", field: "title" },
    {
      title: "Запущен",
      field: "is_working",
      format: (val: string) => {
        return !!val ? "Да" : "Нет";
      },
    },
    {
      title: "Сбор лидов",
      field: "automationIsEnabled",
      format: (val: string) => {
        return !!val ? "Да" : "Нет";
      },
    },
    {
      title: "Рассылка",
      field: "automationSendingEnabled",
      format: (val: string) => {
        return !!val ? "Да" : "Нет";
      },
    },
    {
      title: "Автошедуллинг",
      field: "automationSchedulingEnabled",
      format: (val: string) => {
        return !!val ? "Да" : "Нет";
      },
    },
  ],
};

export const BotScriptTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.botScriptTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper
      header={"Скрипты ботов"}
      state={EAsyncState.IDLE}
      onNew={() => navigate("/botScripts/scripts/new")}
    >
      <ControlledTable
        controller={root.botScriptTableController}
        options={tableOptions}
        getItemHref={(item) => `/botScripts/scripts/${item._id}`}
      />
    </PageWrapper>
  );
});
