import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { PayedToLabel } from "../../../components/PayedToLabel/PayedToLabel";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { getDateStraight } from "../../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    {
      title: "Название",
      field: "title",
    },
    {
      title: "Дата создания",
      field: "creation_date",
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
    {
      title: "Лидов",
      field: "lead_count",
    },
  ],
};

export const TargetListTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.targetListTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper
      header={"Таргетинг"}
      state={EAsyncState.IDLE}
      onNew={() => navigate("/targetLists/new")}
    >
      <ControlledTable
        controller={root.targetListTableController}
        options={tableOptions}
        getItemHref={(item) => `/targetLists/${item._id}`}
      />
    </PageWrapper>
  );
});
