import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { observer } from "mobx-react-lite";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { BotEditor } from "../../../components/Editor/BotEditor";
import { Button } from "../../../components/Button/Button";
import { VariablesModal } from "./VariablesModal";
import style from "./BotScriptItem.module.scss";
import { AutomationSettingsModal } from "./AutomationSettingsModal";

interface IBotScriptItemProps {}

export const BotScriptItem: React.FunctionComponent<IBotScriptItemProps> = observer((props) => {
  let params = useParams();
  let navigate = useNavigate();
  const { botScriptId } = params;
  const controller = root.botScriptItemController;
  useEffect(() => {
    controller.initItem(botScriptId).then();
  }, [botScriptId, controller]);
  if (controller.entityController.state !== EAsyncState.IDLE) return <Loader />;
  const item = controller.entityController.item;

  return (
    <PageWrapper
      header={botScriptId ? `Скрипт ${item.title}` : `Новый скрипт`}
      onSave={async () => {
        const newItem = await controller.entityController.saveItem();
        navigate(`/botScripts/scripts/${newItem._id}`);
      }}
      onDelete={
        botScriptId
          ? async () => {
              const result = await controller.entityController.deleteItem();
              if (result) {
                navigate(`/botScripts/scripts`);
              }
            }
          : undefined
      }
      actionBar={
        <div className={style.actionBar}>
          <div className={style.actionBarDivider} />
          <Button
            text={"Запустить"}
            disabled={controller.getWorkingStatus()}
            onClick={() => controller.toggleScriptWorkingStatus(true)}
          />
          <Button
            text={"Поставить на паузу"}
            disabled={!controller.getWorkingStatus()}
            onClick={() => controller.toggleScriptWorkingStatus(false)}
          />
          <div className={style.actionBarDivider} />
          <Button text={"Переменные"} onClick={controller.openVariables} />
          <div className={style.actionBarDivider} />
          <Button text={"Автоматизация"} onClick={controller.openAutomationSettings} />
        </div>
      }
      state={EAsyncState.IDLE}
    >
      <BotEditor controller={root.botEditorController} />

      <VariablesModal controller={controller} />
      <AutomationSettingsModal controller={controller} />
    </PageWrapper>
  );
});
