import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { CommonEntityController } from "../_common/CommonEntityController";
import { ConditionalController } from "../_common/ConditionalController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { IBotScript } from "../../../_shared/Database/botScript";
import { CommonTableController } from "../_common/CommonTableController";
import { ISearchCore } from "../../../_shared/Database/searchCore";
import { IBotElement } from "../../../_shared/Database/botElement";
import { apiController } from "../ApiController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { keyBy } from "lodash";
import { IBotVariableSchema } from "../../../_shared/Database/botVariable";
import { EBotVariableType } from "../../../_shared/_enums/EBotVariableType";
import { prompter } from "../_common/PromptController";
import { getUniqId } from "../_common/utils";
import { makeToast } from "../../utils/toast";

const newItem: IBotScript = {
  _id: NEW_ITEM_ID,
  title: "",
  is_working: false,
};

export class BotScriptItemController {
  root: RootController;
  conditionalController: ConditionalController;
  entityController: CommonEntityController<IBotScript>;
  botElementTableController: CommonTableController;
  variablesTableController: CommonTableController;
  categories: any = {};
  searchCores: { [k: string]: ISearchCore } = {};
  botElements: { [k: string]: IBotElement } = {};
  isVariablesModalOpen: boolean = false;

  isAutomationSettingsModalOpen: boolean = false;
  newVariable: Partial<IBotVariableSchema> = {
    title: "",
    var_type: EBotVariableType.DATE,
  };

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<IBotScript>(EEntityType.BOT_SCRIPT, newItem);
    makeAutoObservable(this);
  }

  loadKeyedItems = async (entityType: EEntityType, filter = {}) => {
    const resp = await apiController[entityType][ECrudMethods.LIST](filter, 0, 10000);
    const items = resp.items;
    return keyBy(items, "_id");
  };

  initItem = async (botScriptId: string) => {
    this.botElementTableController = new CommonTableController(EEntityType.BOT_ELEMENT, {
      autoFetch: true,
      filter: { bot_script_id: botScriptId },
    });

    await this.entityController.initItem(botScriptId);
    await this.loadVariables(botScriptId);

    this.searchCores = await this.loadKeyedItems(EEntityType.SEARCH_CORE, {});
    this.botElements = await this.loadKeyedItems(EEntityType.BOT_ELEMENT, {
      bot_script_id: this.entityController.item._id,
    });

    await this.botElementTableController.loadTable();
  };

  loadVariables = async (scriptId?) => {
    const botScriptId = scriptId ? scriptId : this.entityController.item._id;
    this.variablesTableController = new CommonTableController(EEntityType.BOT_VARIABLE, {
      autoFetch: true,
      filter: { bot_script_id: botScriptId },
      limit: 999999,
    });
    await this.variablesTableController.loadTable();
  };

  openVariables = async () => {
    this.isVariablesModalOpen = true;
    await this.loadVariables();
  };

  closeVariables = () => {
    this.isVariablesModalOpen = false;
  };

  openAutomationSettings = async () => {
    this.isAutomationSettingsModalOpen = true;
    await this.loadVariables();
  };

  closeAutomationSettings = async () => {
    this.isAutomationSettingsModalOpen = false;
  };

  saveAutomatization = async () => {
    await this.entityController.saveItem();
    await this.closeAutomationSettings();
    makeToast("Автоматизация обновлена");
  };

  changeNewVariable = (newVar) => {
    this.newVariable = newVar;
  };

  saveNewVariable = async () => {
    if (this.newVariable.title.trim() === "") return;
    if (!this.entityController.item._id) return;
    await apiController[EEntityType.BOT_VARIABLE][ECrudMethods.UPDATE]({
      ...this.newVariable,
      unique_id: await getUniqId(EEntityType.BOT_VARIABLE),
      bot_script_id: this.entityController.item._id + "",
    });
    this.newVariable = { title: "", var_type: EBotVariableType.DATE };
    await this.loadVariables();
  };

  deleteVariable = async (varId) => {
    if (!varId) {
      return;
    }
    if (!(await prompter.slider("Действительно удалить переменную?"))) return;
    await apiController[EEntityType.BOT_VARIABLE][ECrudMethods.DELETE]({
      _id: varId,
    });
    await this.variablesTableController.loadTable();
  };

  getWorkingStatus = () => {
    return this.entityController.item.is_working || false;
  };

  toggleScriptWorkingStatus = async (newStatus: boolean) => {
    if (
      !(await prompter.slider(
        `Вы действительно готовы ${newStatus ? "Запустить" : "Оставновить"} этот скрипт?`,
      ))
    )
      return false;
    await this.entityController.changeItem("is_working")(newStatus);
    await this.entityController.saveItem();
  };
}
