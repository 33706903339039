export const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#242f3d",
    minHeight: "30px",
    marginTop: "0px",
    minWidth: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "3px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "3px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0px 7px",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: "white" }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#71accd",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "black",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: "200px" }),
};
