import { ICategory } from "../../../_shared/Database/category";
import { CommonEntityController } from "../_common/CommonEntityController";
import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { RootController } from "../RootController";

const newItem: ICategory = {
  title: "",
  is_private: false,
};

export class CategoryItemController {
  root: RootController;
  entityController: CommonEntityController<ICategory>;

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ICategory>(EEntityType.CATEGORY, newItem);
    makeAutoObservable(this);
  }
}
