import React from "react";

interface IConnectorHOCProps {
  id: string;
  element: any;
  startConnect: () => void;
  children: React.ReactNode;
  className: string;
}

export class ConnectorHOC extends React.Component<IConnectorHOCProps> {
  onDragStart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.startConnect();
  };

  render() {
    const { id } = this.props;

    return (
      <div
        draggable
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onDragStart={this.onDragStart}
        className={this.props.className}
        id={id}
      >
        {this.props.children}
      </div>
    );
  }
}
