import { Modal } from "../../../components/Modal/Modal";
import React, { useCallback } from "react";
import { Button, EButtonType } from "../../../components/Button/Button";
import { GridRow } from "../../../components/Grid/GridRow";
import { Form } from "../../../components/Form/Form";
import { observer } from "mobx-react-lite";
import { useDropzone } from "react-dropzone";
import style from "./NodeUploader.module.scss";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { NodeUploadController } from "../../../controllers/NodeUploaderController/NodeUploadController";

export const UploadTDatasModal: React.FunctionComponent<{
  controller: NodeUploadController;
}> = observer(({ controller }) => {
  const isModalOpen = !!controller.isOpened;

  const handleStartProcessing = async () => {
    await controller.startUploadTDatas();
  };

  const onDrop = useCallback((acceptedFiles) => {
    controller.uploadNodes(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const status = controller.status;
  if (!isModalOpen) return null;

  const files = controller.files;

  if (status !== EAsyncState.IDLE) {
    return (
      <Modal
        header={"Добавление аккаунтов через загрузку TData"}
        onClose={() => controller.accountsUploadModalClose()}
      >
        <Loader />
      </Modal>
    );
  }

  return (
    <Modal
      header={"Добавление аккаунтов через загрузку TData"}
      onClose={() => controller.accountsUploadModalClose()}
    >
      <Form>
        <GridRow>
          <div className={style.dropZoneWrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <p>DRAG OR CLICK TO SELECT</p>}
            <div className={style.dropZoneFileHolder}>
              {files.map((file, index) => {
                return <label key={index}>{file.name}</label>;
              })}
            </div>
          </div>
        </GridRow>
        <GridRow direction={"RIGHT"}>
          <Button styling={EButtonType.DEFAULT} text={"Добавить"} onClick={handleStartProcessing} />
        </GridRow>
      </Form>
    </Modal>
  );
});
