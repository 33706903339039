import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { PayedToLabel } from "../../../components/PayedToLabel/PayedToLabel";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { prepareTextForProcessing } from "../../../../_shared/Util/textProcessor";
import { hashCore } from "../../../../_shared/Util/hashCore";
import { Button } from "../../../components/Button/Button";
import { TestingModal } from "../../searchCore/SearchCoreItem/TestingModal";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import { getDateStraight } from "../../../../_shared/Util/dates";
import { makeToast } from "../../../utils/toast";

const tableOptions: ITableOptions = {
  filters: {
    inputSearch: {
      title: "Поиск",
      type: "text",
      makeQuery: (val) => {
        if (!val) return {};
        return { $and: [{ ["data.text"]: { $regex: val, $options: "i" } }] };
      },
    },
    inverseSearch: {
      title: "Не",
      type: "checkbox",
    },
    search: {
      title: "Поиск по",
      makeQuery: (core, filterValues) => {
        if (!core) return {};
        const coreHash = hashCore(core);
        const cond = filterValues.condition;
        const inverse = filterValues.inverseSearch;
        const condition = !cond ? "or" : "and";
        const filter = inverse ? { $ne: true } : { $eq: true };
        return {
          ["$" + condition]: [{ ["belongs_to_core_hash." + coreHash]: filter }],
        };
      },
      type: "custom",
      customRender: (core, onChange) => {
        return (
          <AsyncInput
            value={core ? core._id : null}
            entityType={EEntityType.SEARCH_CORE}
            onChangeRaw={onChange}
          />
        );
      },
    },
    condition: {
      title: "И",
      type: "checkbox",
    },
    inverseCore: {
      title: "Не",
      type: "checkbox",
    },
    core: {
      title: "Ядро",
      makeQuery: (core, filterValues) => {
        if (!core) return {};
        const coreHash = hashCore(core);
        const inverse = filterValues.inverseCore;
        const cond = filterValues.condition;
        const condition = !cond ? "or" : "and";
        const filter = inverse ? { $ne: true } : { $eq: true };
        return {
          ["$" + condition]: [{ ["belongs_to_core_hash." + coreHash]: filter }],
        };
      },
      type: "custom",
      customRender: (core, onChange) => {
        return (
          <AsyncInput
            value={core ? core._id : null}
            entityType={EEntityType.SEARCH_CORE}
            onChangeRaw={onChange}
          />
        );
      },
    },
    scanSize: {
      title: "Размер",
      type: "number",
    },
    scanning: {
      title: "Сканирование",
      type: "custom",
      customRender: () => {
        return (
          <div style={{ marginTop: "5px" }}>
            <Button
              text={root.messageLogController.getScanStatus()}
              onClick={root.messageLogController.startCoreScan}
            />
          </div>
        );
      },
    },
  },
  columns: [
    {
      title: "Проходит",
      field: "belongs_to_core_hash",
      format: (hashes: any) => {
        const currentSelectedCore = root.messageLogController.tableController.filterValues.core;
        const currentSelectedSearchCore =
          root.messageLogController.tableController.filterValues.search;
        if ((!currentSelectedCore && !currentSelectedSearchCore) || !hashes) return null;
        const isValid = currentSelectedCore ? !!hashes[hashCore(currentSelectedCore)] : false;
        const isValidSearch = currentSelectedSearchCore
          ? !!hashes[hashCore(currentSelectedSearchCore)]
          : false;
        if (!isValid && !isValidSearch) return;
        return (
          <div>
            {isValidSearch && <span style={{ backgroundColor: "#93001a" }}>Поис</span>}
            {isValid && <span style={{ backgroundColor: "#007200" }}>Ядро</span>}
          </div>
        );
      },
    },
    {
      title: "Текст",
      field: "data.text",
      format: (val: string) => {
        const preparedText = prepareTextForProcessing(val).trim();

        const handleDC = async () => {
          await navigator.clipboard.writeText(val);
          makeToast("Copied text!");
        };

        return (
          <span title={preparedText} onDoubleClick={handleDC}>
            {preparedText.slice(0, 100)}
          </span>
        );
      },
    },
    {
      title: "Нода",
      field: "data.nodePhone",
    },
    {
      title: "Дата создания",
      field: "creation_date",
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
  ],
};

export const MessageLogTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.messageLogController.init().then();
  }, []);
  return (
    <PageWrapper header={"Ядерная лаборатория"} state={EAsyncState.IDLE}>
      <ControlledTable
        controller={root.messageLogController.tableController}
        options={tableOptions}
        onItemClick={(item) => root.messageLogController.openRowTest(item._id)}
      />
      <TestingModal controller={root.messageLogController.conditionalController} />
    </PageWrapper>
  );
});
