import { map, keyBy } from "lodash";
import { EBotElementSlotType, EBotElementType } from "../_enums/EBotElementEnums";
import { EBotVariableType } from "../_enums/EBotVariableType";

const ALL_ELEMENT_TYPES = map(EBotElementType, (t) => t);

export const BOT_ELEMENT_SLOTS = {
  [EBotElementType.PHASE]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: ALL_ELEMENT_TYPES,
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: true,
      },
    ],
  },
  [EBotElementType.RESPONSE_CHECK]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: [EBotElementType.PHASE, EBotElementType.STATUS_CHANGE],
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
  [EBotElementType.TIMEOUT_CHECK]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: [
          EBotElementType.PHASE,
          EBotElementType.RESPONSE_CHECK,
          EBotElementType.STATUS_CHANGE,
          EBotElementType.CONDITION_CHECK,
        ],
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
  [EBotElementType.VARIABLE_CHANGE]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: ALL_ELEMENT_TYPES,
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
  [EBotElementType.STATUS_CHANGE]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: ALL_ELEMENT_TYPES,
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
  [EBotElementType.ALERT]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: ALL_ELEMENT_TYPES,
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
  [EBotElementType.CONDITION_CHECK]: {
    [EBotElementSlotType.INPUT]: [
      {
        validation: [
          EBotElementType.PHASE,
          EBotElementType.TIMEOUT_CHECK,
          EBotElementType.RESPONSE_CHECK,
          EBotElementType.CONDITION_CHECK,
        ],
        multiple: true,
      },
    ],
    [EBotElementSlotType.OUTPUT]: [
      {
        multiple: false,
      },
    ],
  },
};

export const BOT_ELEMENT_VARIABLE_TYPES = [
  { label: "Дата", value: EBotVariableType.DATE },
  { label: "Число", value: EBotVariableType.NUMBER },
  { label: "Строка", value: EBotVariableType.STRING },
];

export const BOT_ELEMENT_VARIABLE_TYPES_KEYED = keyBy(BOT_ELEMENT_VARIABLE_TYPES, "value");
