import style from "./Modal.module.scss";
import React from "react";
import cx from "classnames";

interface IModalProps {
  header: React.ReactNode | string;
  footer?: React.ReactNode | string;
  children: React.ReactNode;
  onClose?: () => void;
  innerStyle?: any;
  className?: string;
}

export const Modal: React.FunctionComponent<IModalProps> = ({
  header,
  footer,
  children,
  onClose,
  innerStyle,
  className,
}) => {
  return (
    <div className={style.ModalContainer} onMouseDown={onClose}>
      <div
        className={cx({ [style.ModalInnerWrapper]: true, [className]: true })}
        style={innerStyle || {}}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={style.ModalHeader}>
          <span className={style.ModalHeaderLabel}>{header}</span>
          <div className={style.ModalActions}>
            <div className={style.CloseButton} onClick={onClose}>
              X
            </div>
          </div>
        </div>
        <div className={style.ModalBody}>{children}</div>
        {footer && <div className={style.ModalFooter}>{footer}</div>}
      </div>
    </div>
  );
};
