/*
  Heavily inspired/lifted from this idea: https://stackoverflow.com/a/39311435/661768
  without jqueryUI or jquery dependency.
*/
import * as React from "react";
import style from "./BotEditor.module.scss";
import _ from "lodash";

import {
  applyToPoint,
  fromObject,
  inverse,
  scale,
  transform,
  translate,
} from "transformation-matrix";
import cx from "classnames";
import { root } from "../../controllers/RootController";

export function getSVGPoint(matrixValue, x, y) {
  let matrix = fromObject(matrixValue);

  let inverseMatrix = inverse(matrix);
  return applyToPoint(inverseMatrix, { x: x, y: y });
}

export class ReactPanZoom extends React.PureComponent<any, any> {
  panContainer: any;
  panWrapper: any;

  constructor(props) {
    super(props);
    this.panWrapper = null;
    this.panContainer = null;
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { pandx, pandy, zoom } = this.props;

    const defaultDragData = {
      dx: pandx,
      dy: pandy,
      x: 0,
      y: 0,
    };
    return {
      dragData: defaultDragData,
      dragging: false,
      zoomDelta: 0.1,
    };
  }

  onMouseDown(e) {
    if (e.button != 1) return;

    const { dragData } = this.state;
    let { matrixData } = this.props;
    const offsetX = matrixData[4];
    const offsetY = matrixData[5];
    const DragData = {
      dx: offsetX,
      dy: offsetY,
      x: e.pageX,
      y: e.pageY,
    };
    const newDragData = {
      dx: offsetX,
      dy: offsetY,
      x: e.pageX,
      y: e.pageY,
    };
    this.setState({
      dragData: newDragData,
      dragging: true,
    });
    if (this.panWrapper) {
      this.panWrapper.style.cursor = "move";
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }

  onMouseUp(e) {
    if (e.button != 1) return;
    this.setState({
      dragging: false,
    });
    if (this.panWrapper) {
      this.panWrapper.style.cursor = "";
    }
  }

  getNewMatrixData(x, y) {
    const { dragData } = this.state;
    let { matrixData } = this.props;
    let clone = _.clone(matrixData);
    const deltaX = dragData.x - x;
    const deltaY = dragData.y - y;
    clone[4] = dragData.dx - deltaX;
    clone[5] = dragData.dy - deltaY;
    return clone;
  }

  onMouseMove(e) {
    if (this.state.dragging) {
      const matrixData = this.getNewMatrixData(e.pageX, e.pageY);
      if (this.panContainer) {
        this.panContainer.style.transform = `matrix(${matrixData.toString()})`;
      }
      if (this.props.viewportChange) {
        this.props.viewportChange(matrixData);
      }
    }
  }

  onMouseWheel(event) {
    if (!root.botEditorController.isInited) return;
    let { matrixData } = this.props;
    const { zoomDelta } = this.state;

    /* [oldScale, skewX, skewY, zoomY, dx, dy] */
    let [a, b, c, d, e, f] = matrixData;

    const mouseX = event.pageX - root.botEditorController.canvasScreenBox.x;
    const mouseY = event.pageY - root.botEditorController.canvasScreenBox.y;

    const wheel = event.deltaY > 0 ? -1 : 1;
    const zoomFactor = 1 + wheel * zoomDelta;

    const p = getSVGPoint({ a, b, c, d, e, f }, mouseX, mouseY);

    const translateX = p.x;
    const translateY = p.y;

    const matrix = transform(
      fromObject({ a, b, c, d, e, f }),
      translate(translateX, translateY),
      scale(zoomFactor, zoomFactor),
      translate(-translateX, -translateY),
    );

    a = matrix.a;
    b = matrix.b;
    c = matrix.c;
    d = matrix.d;
    e = matrix.e;
    f = matrix.f;

    if (a >= 2) return;
    if (a <= 0.2) return;

    const finalMatrix = [a, b, c, d, e, f];

    if (this.panContainer) {
      this.panContainer.style.transform = `matrix(${finalMatrix.toString()})`;
      if (this.props.viewportChange) {
        this.props.viewportChange(finalMatrix);
      }
    }
  }

  render() {
    let { matrixData } = this.props;
    return (
      <div
        className={cx({
          [style.pan_container]: true,
          [this.props.className]: true,
        })}
        onMouseDown={this.onMouseDown.bind(this)}
        onWheel={this.onMouseWheel.bind(this)}
        onMouseUp={this.onMouseUp.bind(this)}
        onMouseMove={this.onMouseMove.bind(this)}
        style={{
          height: this.props.height,
          width: this.props.width,
        }}
        ref={(ref) => (this.panWrapper = ref)}
      >
        <div
          ref={(ref) => (ref ? (this.panContainer = ref) : null)}
          style={{
            //background: '#a3a3a3',
            transformOrigin: "0 0",
            transform: `matrix(${matrixData.toString()})`,
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
/*

ReactPanZoom.defaultProps = {
    viewportChange: () => undefined,
    pandx: 0,
    pandy: 0,
    zoom: 1,
}*/
