import { observer } from "mobx-react-lite";
import { EGridItemSize, GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";
import React from "react";
import { EEntityType } from "../../../../../_shared/_enums/EEntityType";
import { get } from "lodash";
import { ComboboxInput } from "../../../../components/ComboboxInput/ComboboxInput";
import { AsyncInput } from "../../../../components/AsyncInput/AsyncInput";

const DATE_VARIABLE_VALUES = [
  { label: "Удалить переменную", value: null },
  { label: "Задать текущую дату", value: "CURRENT_DATE" },
  { label: "Подставить ответ пользователя", value: "CURRENT_RESPONSE" },
];

export const BotElementVariableChangeForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  const value = item.element_data?.value || null;
  return (
    <Form>
      <GridRow>
        <GridItem size={EGridItemSize.half}>
          <label>Переменная</label>
          <AsyncInput
            value={get(item, "element_data.var_unique_id", undefined)}
            entityType={EEntityType.BOT_VARIABLE}
            valueField={"unique_id"}
            onChange={(newId) =>
              controller.entityController.changeItem("element_data.var_unique_id")(newId)
            }
            isMulti={false}
            additionalFilters={{ bot_script_id: item.bot_script_id }}
          />
        </GridItem>
      </GridRow>
      <GridRow>
        <GridItem size={EGridItemSize.half}>
          <ComboboxInput
            options={DATE_VARIABLE_VALUES}
            value={value}
            onChange={(v) => controller.entityController.changeItem("element_data.value")(v)}
          />
        </GridItem>
      </GridRow>
    </Form>
  );
});
