import { keyBy } from "lodash";
import { ETgNodeType } from "../_enums/ETgNodeType";

export const TG_NODE_TYPES = [
  { label: "Не активная", value: ETgNodeType.NOT_ACTIVE },
  { label: "Сканирующая", value: ETgNodeType.SCAN },
  { label: "Сервисная", value: ETgNodeType.SERVICE },
  { label: "Рассылочная", value: ETgNodeType.MESSAGING },
];

export const TG_NODE_TYPES_KEYED = keyBy(TG_NODE_TYPES, "value");
