import { each } from "lodash";
import React, { useEffect } from "react";

export const WindowEvents: React.FC<any> = (events) => {
  useEffect(() => {
    each(events, (evCallback, evName) => {
      window.addEventListener(evName, evCallback);
    });
    return () => {
      each(events, (evCallback, evName) => {
        window.removeEventListener(evName, evCallback);
      });
    };
  }, []);
  return <></>;
};
