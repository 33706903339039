import { observer } from "mobx-react-lite";
import { BotScriptItemController } from "../../../controllers/BotScript/BotScriptItemController";
import { Modal } from "../../../components/Modal/Modal";
import { Form } from "../../../components/Form/Form";
import { GridRow } from "../../../components/Grid/GridRow";
import { GridItem } from "../../../components/Grid/GridItem";
import React from "react";
import { Input } from "../../../components/Input/Input";
import { Button } from "../../../components/Button/Button";
import { ControlledTable, ITableOptions } from "../../../components/Table/Table";
import { root } from "../../../controllers/RootController";
import { BOT_ELEMENT_VARIABLE_TYPES } from "../../../../_shared/Constants/BotElementConstants";
import { ComboboxInput } from "../../../components/ComboboxInput/ComboboxInput";

export const botVariablesTableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [{ title: regex }],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "Название",
      field: "title",
    },
    {
      title: "Тип",
      field: "var_type",
    },
    {
      title: "X",
      field: "delete",
      format: (_, item) => {
        return (
          <Button
            text={"Удалить"}
            onClick={() => root.botScriptItemController.deleteVariable(item._id)}
          />
        );
      },
    },
  ],
};
export const VariablesModal: React.FunctionComponent<{
  controller: BotScriptItemController;
}> = observer(({ controller }) => {
  if (!controller.isVariablesModalOpen) return null;

  return (
    <Modal header={"Переменные"} onClose={controller.closeVariables}>
      <Form>
        <GridRow>
          <GridItem>
            <Input
              isEnabled={true}
              value={controller.newVariable.title}
              onValueChange={(v) =>
                controller.changeNewVariable({
                  title: v,
                  var_type: controller.newVariable.var_type,
                })
              }
            />
          </GridItem>
          <GridItem>
            <ComboboxInput
              options={BOT_ELEMENT_VARIABLE_TYPES}
              value={controller.newVariable.var_type}
              onChange={(v) =>
                controller.changeNewVariable({
                  title: controller.newVariable.title,
                  var_type: v,
                })
              }
            />
          </GridItem>
          <GridItem>
            <Button text={"+"} onClick={controller.saveNewVariable} />
          </GridItem>
        </GridRow>
      </Form>

      <ControlledTable
        controller={controller.variablesTableController}
        options={botVariablesTableOptions}
      />
    </Modal>
  );
});
