import style from "./BotElementItem.module.scss";
import { Modal } from "../../../components/Modal/Modal";
import React from "react";
import { Button, EButtonType } from "../../../components/Button/Button";
import { GridRow } from "../../../components/Grid/GridRow";
import { Form } from "../../../components/Form/Form";
import { observer } from "mobx-react-lite";
import { TextArea } from "../../../components/Input/Input";
import { BotElementPhaseController } from "../../../controllers/BotScript/BotElementItemController";

export const ElementTestingModal: React.FunctionComponent<{
  controller: BotElementPhaseController;
}> = observer(({ controller }) => {
  if (!controller) return null;
  const isModalOpen = !!controller.isTestElementOpened;
  if (!isModalOpen) return null;

  return (
    <Modal header={"Тестирование фразы"} onClose={() => controller.closeTesting()}>
      <Form>
        <GridRow>
          <TextArea value={controller.generatedText} onChange={() => {}} />
        </GridRow>
        <GridRow direction={"RIGHT"}>
          <div className={style.bottomWrapper}>
            <div></div>
            <Button
              styling={EButtonType.DEFAULT}
              text={"Генерация"}
              onClick={controller.generatePhrase}
            />
          </div>
        </GridRow>
      </Form>
    </Modal>
  );
});
