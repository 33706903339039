import style from "./Grid.module.scss";
import React from "react";
import cx from "classnames";

export enum EGridItemSize {
  small = 1,
  medium = 3,
  half = 6,
  full = 12,
}

interface IGridItemProps {
  size?: EGridItemSize;
  className?: string;
  children: React.ReactNode;
}

export const GridItem: React.FunctionComponent<IGridItemProps> = ({
  size = EGridItemSize.medium,
  children,
  className,
}) => {
  return (
    <div
      className={cx({
        [style.GridItem]: true,
        [style.FormInputWrapper]: true,
        [style["Size-" + size]]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};
