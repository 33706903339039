import axios from "axios";
import { ECurrency } from "../../../_shared/_enums/ECurrency";
import { apiController } from "../ApiController";

class CurrencyController {
  // Some default rates if there is an error with an api
  rates = {
    [ECurrency.USD]: 1,
    [ECurrency.GEL]: 0.37,
    [ECurrency.RUB]: 0.011,
    [ECurrency.TRY]: 0.033,
  };

  getMulti = (curr: ECurrency) => {
    return this.rates[curr];
  };

  valToUsd = (value: number, curr: ECurrency) => {
    const rate = this.rates[curr];
    return value * rate;
  };

  usdToVal = (value: number, curr: ECurrency) => {
    const rate = this.rates[curr];
    return value / rate;
  };

  getRateToUsd = (curr: ECurrency) => {
    if (!curr) return 0;
    return 100 / this.rates[curr] / 100;
  };

  updateRates = async () => {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        reject("Timeout currency update error");
      }, 5000);

      const rates = await apiController.getCurrencies();

      if (!rates) return;
      this.rates[ECurrency.USD] = 1;
      this.rates[ECurrency.GEL] = parseFloat(rates["GELUSD"]);
      this.rates[ECurrency.RUB] = parseFloat(rates["RUBUSD"]);
      this.rates[ECurrency.TRY] = parseFloat(rates["TRYUSD"]);

      console.info("Updated rates is ", this.rates);
      resolve("ok");
    });
  };
}

export const currencyController = new CurrencyController();
