import { DateTime, Duration } from "luxon";
import * as humanizeDuration from "humanize-duration";

export const getDayString = (val?: number) => {
  let date = val ? new Date(val) : new Date();
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const getDateString = (val: number | string) => {
  const numericVal = typeof val === "string" ? parseInt(val) : val;
  return DateTime.fromMillis(numericVal).toLocaleString(DateTime.DATETIME_FULL);
};

export const getDateStraight = (val: number | string) => {
  const numericVal = typeof val === "string" ? parseInt(val) : val;
  return DateTime.fromMillis(numericVal).toFormat("dd.MM.yy HH:mm:ss");
};

export const getRelativeDate = (val: number | string) => {
  const numericVal = typeof val === "string" ? parseInt(val) : val;
  return DateTime.fromMillis(numericVal).toRelative({ style: "narrow" });
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "г",
      mo: () => "мес",
      w: () => "нед",
      d: () => "д",
      h: () => "ч",
      m: () => "мин",
      s: () => "с",
      ms: () => "мс",
    },
  },
});

export const getRelativeDuration = (millis) => {
  return shortEnglishHumanizer(millis);
};
