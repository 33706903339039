export enum EConditionTerm {
  AND = "and",
  OR = "or",
  SEQ = "seq",
  NOR = "nor",
}

export enum EConditionChangeCommand {
  CREATE = "create",
  OPEN_UPDATE_MODAL = "open_update_modal",
  DELETE = "delete",
  CHANGE_TERM = "change_term",
  MAKE_DUMP = "make_dump",
  RESTORE_DUMP = "restore_dump",
  CHANGE_OPTIONS = "change_options",
  CHANGE_TITLE = "change_title",
  TOGGLE_OPEN_STATE = "toggle_open_state",
}

export type IConditionContentNode = string | IConditionNode;

export interface IConditionNode {
  term: EConditionTerm;
  content: IConditionContentNode[];
  options: any;
  title?: string;
  isOpened?: boolean;
}

export enum EListType {
  WHITELIST = "whitelist",
  BLACKLIST = "blacklist",
}

export enum ENodeType {
  VALUE = "value",
  NODE = "node",
}
