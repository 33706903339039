import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Grid } from "../../../components/Grid/Grid";
import { GridRow } from "../../../components/Grid/GridRow";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import styles from "../reports.module.scss";
import { Input } from "../../../components/Input/Input";
import { DateTime } from "luxon";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import { Button } from "../../../components/Button/Button";

const twoDigitCool = (number, apndx = "") => {
  if (!number) return 0;
  const [big, littl] = number.toFixed(2).split(".");
  const littlTxt = littl === "00" ? "" : "." + littl;
  return (
    <span className={styles.twoDigWrp}>
      <span className={styles.twoDigWrpBig}>{big}</span>
      <span className={styles.twoDigLittl}>{littlTxt}</span>
      {apndx}
    </span>
  );
};

const perc = (p: number) => {
  return twoDigitCool(p * 100);
};

const curr = (c: number) => {
  return twoDigitCool(c);
};

function relDiff(a, b) {
  return 100 * Math.abs((a - b) / ((a + b) / 2));
}

const compareNumbersPercent = (a, b) => {
  if (!b) return;
  const percentDiff = ((a - b) / b) * 100;
  return (
    <span>
      ({percentDiff > 0 ? "+" : ""}
      {percentDiff.toFixed(2)}%)
    </span>
  );
};

const renderPeriod = (periodData, compareData?) => {
  if (!Object.keys(periodData).length) return null;
  return (
    <div>
      <GridRow>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Профит $</label>
          <label className={styles.reportStatValue}>
            {curr(periodData.totalTxProfit)}{" "}
            {compareNumbersPercent(periodData.totalTxProfit, compareData?.totalTxProfit)}
          </label>
          <label className={styles.reportStatLabel}>Кол-во транз. </label>
          <label className={styles.reportStatValue}>
            {periodData.totalTxCount}{" "}
            {compareNumbersPercent(periodData.totalTxCount, compareData?.totalTxCount)} $
            {curr(periodData.totalTxAvgProfit)} avg
          </label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Обработано</label>
          <label className={styles.reportStatValue}>
            {periodData.allProcessedLeads}{" "}
            {compareNumbersPercent(periodData.allProcessedLeads, compareData?.allProcessedLeads)}
          </label>
          <label className={styles.reportStatLabel}>Протестировало</label>
          <label className={styles.reportStatValue}>
            {periodData.testingClients}{" "}
            {compareNumbersPercent(periodData.testingClients, compareData?.testingClients)}
          </label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Закрыто клиентов</label>
          <label className={styles.reportStatValue}>
            {periodData.closedClients} [{periodData.newClients} новых]{" "}
            {compareNumbersPercent(periodData.closedClients, compareData?.closedClients)}
          </label>
          <label className={styles.reportStatLabel}>Отписалось</label>
          <label className={styles.reportStatValue}>
            {periodData.lostClients}{" "}
            {compareNumbersPercent(periodData.lostClients, compareData?.lostClients)}
          </label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Конверсия в тест %</label>
          <label className={styles.reportStatValue}>
            {perc(periodData.testConversionRate)}{" "}
            {compareNumbersPercent(periodData.testConversionRate, compareData?.testConversionRate)}
          </label>
          <label className={styles.reportStatLabel}>Конверсия в оплату %</label>
          <label className={styles.reportStatValue}>
            {perc(periodData.paymentConversionRate)}{" "}
            {compareNumbersPercent(
              periodData.paymentConversionRate,
              compareData?.paymentConversionRate,
            )}
          </label>
        </GridItem>
      </GridRow>
    </div>
  );
};

const renderBase = (periodData) => {
  if (!Object.keys(periodData).length) return null;
  return (
    <div>
      <GridRow>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Сейчас в воронке</label>
          <label className={styles.reportStatValue}>{periodData.allInProcessClients}</label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Тестят</label>
          <label className={styles.reportStatValue}>{periodData.allTestingClients}</label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Платят</label>
          <label className={styles.reportStatValue}>{periodData.allPayedClients}</label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Заплатят до конца месяца</label>
          <label className={styles.reportStatValue}>{periodData.clientsWillPay}</label>
        </GridItem>
        <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
          <label className={styles.reportStatLabel}>Заплатят в след месяце</label>
          <label className={styles.reportStatValue}>{periodData.unrealizedNextMonthTxCount}</label>
        </GridItem>
      </GridRow>
    </div>
  );
};

export const ProfitReport = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.profitReportController.loadReport().then();
  }, []);

  const cont = root.profitReportController;
  return (
    <PageWrapper header={"Отчет: Доходы"} state={root.profitReportController.state}>
      <Grid>
        <h3>Настойки:</h3>
        <GridRow>
          <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
            <label className={styles.reportStatLabel}>Скрипт</label>
            <AsyncInput
              entityType={EEntityType.BOT_SCRIPT}
              onChange={cont.changeScript}
              value={cont.selectedScript}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
            <label className={styles.reportStatLabel}>Гео</label>
            <AsyncInput
              entityType={EEntityType.LOCATION}
              onChange={cont.changeLocation}
              value={cont.selectedLocation}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
            <label className={styles.reportStatLabel}>Дата с</label>
            <Input
              type={"date"}
              isEnabled={true}
              onValueChange={cont.changeDate("timeFrom")}
              value={DateTime.fromMillis(cont.timeFrom).toISODate()}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
            <label className={styles.reportStatLabel}>Дата по</label>
            <Input
              type={"date"}
              isEnabled={true}
              onValueChange={cont.changeDate("timeTo")}
              value={DateTime.fromMillis(cont.timeTo).toISODate()}
            />
          </GridItem>
          <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
            <label className={styles.reportStatLabel}>Управление</label>
            <GridRow>
              <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
                <Button onClick={cont.traversePeriod("prev")} text="Назад" />
              </GridItem>
              <GridItem size={EGridItemSize.medium} className={styles.reportStat}>
                <Button onClick={cont.traversePeriod("next")} text="Вперед" />
              </GridItem>
            </GridRow>
          </GridItem>
        </GridRow>

        <h3>Базовые показатели</h3>
        {renderBase(cont.baseResults)}
        <h3>За период {cont.periodResults.main.label}</h3>
        {renderPeriod(cont.periodResults.main, cont.periodResults.prev)}
        <h3>За прошлый период {cont.periodResults.prev.label}</h3>
        {renderPeriod(cont.periodResults.prev)}
      </Grid>
    </PageWrapper>
  );
});
