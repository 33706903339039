import style from "./Form.module.scss";
import { Grid } from "../Grid/Grid";
import cx from "classnames";

export const FormInputWrapper = ({ children }) => {
  return (
    <div
      className={cx({
        [style.FormInputWrapper]: true,
      })}
    >
      {children}
    </div>
  );
};

export const Form = (props: any) => {
  return (
    <div className={cx({ [style.Form]: true, [props.className || ""]: true })}>
      <Grid>{props.children}</Grid>
    </div>
  );
};
