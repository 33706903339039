import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { apiController } from "../ApiController";
import { CommonTableController } from "../_common/CommonTableController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { makeToast } from "../../utils/toast";
import { prompter } from "../_common/PromptController";
import { delay } from "../../../_shared/Util/processing";

export class SchedullingController {
  root: RootController;
  schedullingClientTableController: CommonTableController;

  unschedulledCount: number = 0;
  schedulledCount: number = 0;

  constructor(root: RootController) {
    this.root = root;
    this.schedullingClientTableController = new CommonTableController(
      EEntityType.CLIENT,
      {
        limit: 50,
        filter: {
          messages: { $ne: null },
          automation_info: { $ne: null },
          status: null,
        },
      },
      {
        filterValues: {
          automation_scheduled: undefined,
        },
        filter: {
          automation_scheduled_at: null,
        },
      },
    );
    makeAutoObservable(this);
  }

  loadCounters = async () => {
    this.schedulledCount = (
      await apiController[EEntityType.CLIENT][ECrudMethods.COUNT]({
        status: null,
        automation_scheduled_at: { $ne: null },
      })
    ).count;
    this.unschedulledCount = (
      await apiController[EEntityType.CLIENT][ECrudMethods.COUNT]({
        status: null,
        automation_scheduled_at: null,
      })
    ).count;
    await delay(60000);
    this.loadCounters();
  };

  getCountersText = () => {
    return this.schedulledCount + " / " + this.unschedulledCount;
  };

  approveSpam = async (tableController: CommonTableController) => {
    const items = tableController.items;
    const selected = tableController.selected || [];
    if (!selected.length) return;
    if (
      !(await prompter.confirm(`
            Вы действительно хотите назначить рассылку по ${selected.length}?
        `))
    )
      return;
    let operationCount = 0;
    let currentDate = Date.now();
    for (const client of items) {
      if (!selected.includes(client._id + "")) {
        continue;
      }
      await apiController[EEntityType.CLIENT][ECrudMethods.UPDATE]({
        _id: client._id,
        automation_scheduled_at: currentDate,
      });
      operationCount = operationCount + 1;
    }
    tableController.selected = [];
    if (operationCount === 0) return;
    makeToast("Выполнено " + operationCount + " операций");
    await tableController.loadTable();
  };

  massDelete = async (tableController: CommonTableController) => {
    const items = tableController.items;
    const selected = tableController.selected || [];
    if (!selected.length) return;
    if (
      !(await prompter.confirm(`
            Вы действительно хотите уничтожить ${selected.length} клиентов? 
        `))
    )
      return;

    if (
      !(await prompter.confirm(`
              Действие необратимо, точно? 
          `))
    )
      return;
    let currentDate = Date.now();

    const clientIds = [];

    for (const client of items) {
      if (!selected.includes(client._id + "")) {
        continue;
      }
      clientIds.push(client._id + "");
    }
    if (clientIds.length === 0) return;

    await apiController[EEntityType.CLIENT][ECrudMethods.DELETE_MANY_BY_QUERY]({
      _id: { $in: clientIds },
      status: null,
    });
    tableController.selected = [];
    makeToast("Удалено " + clientIds.length + " клиентов");
    await tableController.loadTable();
  };
}
