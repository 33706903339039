import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { EConditionTerm } from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { ISearchCore } from "../../../_shared/Database/searchCore";
import { CommonEntityController } from "../_common/CommonEntityController";
import { ConditionalController } from "../_common/ConditionalController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { apiController } from "../ApiController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";

const newItem: ISearchCore = {
  _id: NEW_ITEM_ID,
  title: "",
  is_private: true,
  category_id: undefined,
  conditions: {
    version: "v1",
    content: {
      max_links: 2,
      max_length: 320,
      whitelist: { term: EConditionTerm.OR, content: [] },
      blacklist: { term: EConditionTerm.OR, content: [] },
    },
  },
};

export class SearchCoreItemController {
  root: RootController;
  conditionalController: ConditionalController;
  entityController: CommonEntityController<ISearchCore>;

  categories: any = {};

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ISearchCore>(
      EEntityType.SEARCH_CORE,
      newItem,
    );
    this.conditionalController = new ConditionalController(this.entityController);
    apiController[EEntityType.CATEGORY][ECrudMethods.LIST]({}, 0, 10000).then((resp) => {
      this.categories = resp.items;
    });
    makeAutoObservable(this);
  }
}
